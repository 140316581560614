import 'bootstrap/dist/css/bootstrap.min.css';

import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import $ from "jquery";

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
process.env.CI = false;
if((window.location.href.indexOf("heroku") !== -1) || (window.location.href.indexOf("localhost") !== -1)) {
	serviceWorker.register();
} else {
	serviceWorker.unregister();
}
