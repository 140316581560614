import React, { Component } from 'react';
import Switch from "react-switch";
import $ from "jquery";

//import data from './projectors.json';
import logo from './Christie-Black-Logo.png';
import exportLogo from './Christie_Logo_GS.png';
import exportTriangle from './CHRI_Gradient_DarkBlue_LightBlue_RGB.png';
import './App.css';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';

import Modal from 'react-modal';

// const url = "https://api.ipify.org/?format=json";


// checkIP();
// function checkIP() {
//   var ip;
//   fetch(url)
//     .then(response => response.json())
//     .then(data => {
//       ip = data;
//       ipLocation(data);
//     })
//     //this catch function solves "CD-558" ip error
//     .catch(err =>{
//       console.log(err.message);
//     })
// }
// var china = false;
// var chinaIP = '114.215.172.136';
// function ipLocation(data) {
//   var ip = data.ip;
//   if (window.location.hash == '#china') {
//     ip = chinaIP;
//   }
//   var ipURL = 'https://api.ipgeolocation.io/ipgeo?apiKey=b435a680100844609b0d8f3ee00b764f&ip=' + ip;

//   fetch(ipURL)
//     .then(response => response.json())
//     .then(data => {
//       if (data.country_name === "China") {
//         china = true;
//       }
//     })
//     .catch(err =>{
//       console.log(err.message);
//     })
// }
  
function addScriptToPage() {
  var addGtagScript = document.createElement("script");
  addGtagScript.setAttribute("src", 'https://www.googletagmanager.com/gtag/js?id=UA-6631798-1');
  addGtagScript.setAttribute("type", 'text/javascript');
  document.getElementsByTagName("body")[0].appendChild(addGtagScript);
  var addGtagCode = document.createElement("script");
  addGtagCode.setAttribute("src", '/windowtag.js');
  addGtagCode.setAttribute("type", 'text/javascript');
  document.getElementsByTagName("body")[0].appendChild(addGtagCode);
  addFontsToPage();
}

function addFontsToPage () {
  var addFonts = document.createElement("link");
  addFonts.setAttribute("href", 'https://fonts.googleapis.com/css?family=Assistant:700|Lato:400,700&display=swap');
  addFonts.setAttribute("rel", 'stylesheet');
  document.getElementsByTagName("body")[0].appendChild(addFonts);
}
//import ReactGA from 'react-ga';

// ReactGA.initialize('UA-6631798-01', {
//   debug: true,
//   titleCase: false,
//   gaOptions: {
//     userId: 123
//   },
//   gaAddress: './analytics.js'
// });
// ReactGA.timing({
//   category: 'JS Libraries',
//   variable: 'load',
//   value: 20, // in milliseconds
//   label: 'CDN libs'
// });
// ReactGA.pageview(window.location.pathname + window.location.search);
// ReactGA.set({ dimension10: 'toggle_mode' }, { dimension11: 'projector' }, { dimension12: 'discontinued' }, { dimension13: 'lens' });

// ReactGA.ga('send', 'pageview', '/');

//import CacheBuster from './CacheBuster';
//import packageJson from '../package.json';
//global.appVersion = packageJson.version;

//var projectors = require.context('projectors', true);

var octicons = require("octicons");
// function editButton() {
//   return {__html: octicons.pencil.toSVG()};
// }
// function gearButton() {
//   return {__html: octicons.gear.toSVG()};
// }
function settingsButton() {
  return {__html: "Units " + octicons.settings.toSVG()};
}
function summaryButton() {
  return {__html: "Summary " + octicons.info.toSVG()};
}
function infoButton() {
  return {__html: octicons.info.toSVG()};
}

function starIcon() {
  return {__html: octicons.star.toSVG()};
}

function searchIcon() {
  return {__html: octicons.search.toSVG()};
}
function infoIcon() {
  return {__html: octicons.info.toSVG()};
}

function xIcon() {
  return {__html: octicons.x.toSVG()};
}

function checkIcon() {
  return octicons.check.toSVG();
}

const customStyles = {
  content : {
    top                   : '10%',
    left                  : '10%',
    right                 : '10%',
    bottom                : '10%',
    padding: '0',
  }
};

const customStyles2 = {
  content : {
    top                   : '20%',
    left                  : '20%',
    right                 : '20%',
    bottom                : '20%',
    padding: '0',
  }
};

const customStyles12 = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};



class App extends Component {

gtaEvent(eventName, eventVal = null) {
  var blendMode = "single";
  if (this.state.blended === true)
    blendMode = "blended";

  var lensName = "";
  if (this.state.selectedLenses !== undefined && this.state.selectedLenses.length > 0)
    lensName = this.state.selectedLenses[0].name;
  // if (!china && window.gtag) {
  //   window.gtag(
  //     'event', 
  //     'click', 
  //     {
  //       'event_category': eventName,
  //       'event_label': eventVal,
  //       'blend_mode': blendMode,
  //       'projector': this.state.projector.name,
  //       'discontinued': this.state.projector.discontinued,
  //       'lens': lensName
  //     }
  //   )
  // }
  // ReactGA.event({
  //   category: eventName,
  //   action: 'click',
  //   label: eventVal,
  //   'event_category': eventName,
  //   'event_label': eventVal,
  //   'blend_mode': blendMode,
  //   'projector': this.state.projector.name,
  //   'discontinued': this.state.projector.discontinued,
  //   'lens': lensName
  // });
}


selectProjector(projector,boolOrig) {
  $(".lens.selected").removeClass("selected");
  var selectLensButtons = $(".select-lens-button");
  selectLensButtons.html("Select");
  selectLensButtons.removeClass("lens-selected");

  var projectorState;
  var setHorizVert = this.state.setHorizVert;
  var vertOff = this.state.vertOff;
  var horizOff = this.state.horizOff;
  var middleColumn = $("#single-projector");
  var throwCard = $("#throwDistanceCard");
  //middleColumn.addClass("hide-me");

  $("#offset-canvas-container").addClass("hide-me");
  $("#offset-canvas-message").removeClass("hide-me");

  if (this.state.setHorizVert === true) {
    vertOff = 0;
    horizOff = 0;
  } else {
    setHorizVert = true;
  }

  if(boolOrig)
    projectorState = this.state.projectorsOrig[projector];
  else 
    projectorState = this.state.projectors[projector];

  if (!$('#splashScreenMobile.visible-mobile').hasClass("always-hidden")) {
    $('#splashScreenMobile.visible-mobile').addClass("always-hidden");
  }

  this.setState(
    {
      projector: projectorState, 
      lenses: [],
      selectedLenses:[],
      vertOff:vertOff, 
      horizOff:horizOff,
      setHorizVert: setHorizVert
    }, function() {

      var arr = [];
      if(boolOrig)
        arr = this.state.projectorsOrig[projector].lenses.lens;
      else
        arr = this.state.projectors[projector].lenses.lens;
      
      if(arr.constructor === Array) {
        for(var x=0;x<arr.length;x++) {
          arr[x].selected = "false";
        }
      } else {
        arr.selected = "false";
      }

      var arr2;
      var lenses;

      if(boolOrig) {
        if(this.state.projectorsOrig[projector].lenses.lens.constructor === Array) {
          lenses = arr;
          arr2 = arr;
        } else {
          lenses = [arr];
          arr2 = [arr];
        }        
      } else {
        if(this.state.projectors[projector].lenses.lens.constructor === Array) {
          lenses = arr;
          arr2 = arr;
        } else {
          lenses = [arr];
          arr2 = [arr];
        }
      }
      if (lenses !== arr) {
        arr = lenses;
      }
      var aspectWidthPreselect = projectorState.aspectratio.width;
      var aspectHeightPreselect = projectorState.aspectratio.height;

      // update radio button with projector aspect ratio
      var ratio = '"' + aspectWidthPreselect + ":" + aspectHeightPreselect + '"';
      $('input[type=radio][name=ratio]').prop('checked', false);
      $('input[type=radio][value='+ ratio +']').attr("checked", "checked");
      $('input[type=radio][value='+ ratio +']').prop("checked", true);
      if (this.state.orientation === "v") {
        aspectWidthPreselect = this.state.aspectheight;
        aspectHeightPreselect = this.state.aspectwidth;
      }
      this.setState(
        {
          lenses: arr,
          aspectratio: projectorState.aspectratio,
          aspectwidth: aspectWidthPreselect,
          aspectheight: aspectHeightPreselect
        },
        function() {
          //this.drawSideProjector();
          if (this.state.lenses.length === 1) {
            $(".ust-values").removeClass("hide-me");
            $("#single-projector").removeClass("hide-me");
            $("#throwDistanceCard").removeClass("hide-me");
          }
          if (this.state.lenses.length === 1 && this.state.lenses[0].type === "UST") {
            $("#throwDistanceCard").addClass("hide-me");
          }
          var indexer = projector;

          var projector = this.state.projector;


          //$('#' + projector.aspectratio.width + projector.aspectratio.height).prop("checked", true);


          //GET Min/Max diagonal from lens list
          var minDiagonal;
          var maxDigonal;
          var diagonal = this.state.diagonal;
          for(var i = 0; i < arr.length; i++) {
            var lens = arr[i];
            if (lens.diagonal !== undefined) {
              var lensMin = Number(lens.diagonal.min);
              var lensMax = Number(lens.diagonal.max);
              if (minDiagonal == null || lensMin < minDiagonal)
                minDiagonal = lensMin;

              if (maxDigonal == null || lensMax > maxDigonal)
                maxDigonal = lensMax;
            }
          }
          if (diagonal < minDiagonal)
            diagonal = minDiagonal;
          if (diagonal > maxDigonal)
            diagonal = maxDigonal;
          //All other dimensions/distance based off diagonal
          var minWidth = this.getProjectedImageWidthFromDiagonal(minDiagonal, Number(this.state.aspectwidth), Number(this.state.aspectheight));
          var maxWidth = this.getProjectedImageWidthFromDiagonal(maxDigonal, Number(this.state.aspectwidth), Number(this.state.aspectheight));

          var width = this.state.width;
          if (width < minWidth)
            width = minWidth;
          if (width > maxWidth)
            width = maxWidth;

          var height = (Number(projector.aspectratio.height) * width)  / (Number(projector.aspectratio.width) * 1.0);
          var minHeight = this.getProjectedImageHeightFromDiagonal(minDiagonal, Number(this.state.aspectwidth), Number(this.state.aspectheight));
          var maxHeight = this.getProjectedImageHeightFromDiagonal(maxDigonal, Number(this.state.aspectwidth), Number(this.state.aspectheight));

          if (height < minHeight)
            height = minHeight;
          if (height > maxHeight)
            height = maxHeight;

          
          $('#screenWidth').val(width);
          if (this.state.units === "mm" || this.state.units === "in") {
            $('#manualWidth').val(this.myRoundMM(width * this.state.unitsMulti));
          } else {
            $('#manualWidth').val(this.myRound(width * this.state.unitsMulti));
          }

          if (this.state.units === "ft") {
            var feetInchArray = this.getFeetAndInches(width * this.state.unitsMulti);
            $('#manualWidth').val(feetInchArray[0]);
            $('#manualWidthInInput').val(feetInchArray[1]);
          }

          var length = (Number(projector.length));
          // update height and diag based on width
          //$('#screenHeight').val(this.state.height);
          $('#screenHeight').val(height);
          $('#screenDiagonal').val(diagonal);
          if (this.state.units === "mm" || this.state.units === "in") {
            $('#manualHeight').val(this.myRoundMM(height * this.state.unitsMulti));
            $('#manualDiagonal').val(this.myRoundMM(diagonal * this.state.unitsMulti));
          } else {
            $('#manualHeight').val(this.myRound(height * this.state.unitsMulti));
            $('#manualDiagonal').val(this.myRound(diagonal * this.state.unitsMulti));
          }

          if (this.state.units === "ft") {
            var feetInchArrayHeight = this.getFeetAndInches(height  * this.state.unitsMulti);
            var feetInchArrayDiagonal = this.getFeetAndInches(diagonal * this.state.unitsMulti);
            $('#manualHeight').val(feetInchArrayHeight[0]);
            $('#manualHeightInInput').val(feetInchArrayHeight[1]);
            $('#manualDiagonal').val(feetInchArrayDiagonal[0]);
            $('#manualDiagonalInInput').val(feetInchArrayDiagonal[1]);
          }
          
          // $("#single-projector").addClass("hide-me");
          // $("#throwDistanceCard").addClass("hide-me");
          var horizOff = arr2[0].offset.vertical.replace("+","").replace("-","").replace(" ","").replace(" ","").replace("%","").replace("%","").split("/");
          var vertOff = arr2[0].offset.horizontal.replace("+","").replace("-","").replace(" ","").replace(" ","").replace("%","").replace("%","").split("/");
          this.setState(
            {
              width: width,
              minWidth: minWidth,
              maxWidth: maxWidth,
              height: height,
              minHeight: minHeight,
              maxHeight: maxHeight,
              diagonal: diagonal,
              minDiag: minDiagonal,
              maxDiag: maxDigonal,
              setDistance: false,
              selectedLenses: [arr2[0]],
              maxHoriz:Number(vertOff[0]),
              minHoriz:-Number(vertOff[1]),
              maxVert:Number(horizOff[0]),
              minVert: -Number(horizOff[1])
            },
            function() {
              this.setMinMax();
              this.gtaEvent('Select Projector', this.state.projector.name);
              if (this.state.projector !== undefined)
              {
                this.setState(
                  {
                    quoteURL:"https://info.christiedigital.com/form/8948/if/?product=" + this.state.projector.name +"&sbu=16122",
                    iframeURL: "https://www.christiedigital.com/lp/lc-rq/?product=" + encodeURIComponent(this.state.projector.name) + "&selected_lens=" + encodeURIComponent(this.state.projector.lenses.lens[0] !== undefined ? this.state.projector.lenses.lens[0].name : this.state.projector.lenses.lens.name)
                  });

              }
              this.setProjector(projector);
            }
          );

        }
      );
    }
  );
}

setProjector(projector) {
  var distance = this.state.distance;

  $('#searchProjectors').addClass('hidden');
  $('#searchProjectors').addClass('pinned');

  $('#splashScreen').addClass('hidden');

  window.scrollTo({ top: 0, behavior: 'smooth' });

  $('#projectorName').removeClass('initially-hidden');
  $('#setValues').removeClass('initially-hidden');
  $('#tabs').removeClass('initially-hidden');
  $('#simulation').removeClass('initially-hidden');
  $('#simulation').removeClass('initially-hidden-mobile');
  $('#lenses').removeClass('initially-hidden');

  $('#projectorName').removeClass('disabled');
  $('#setValues').removeClass('disabled');
  $('#simulation').removeClass('disabled');
  $('#lenses').removeClass('disabled');

  $('#mainNav').removeClass('hide-me');
  $('#notification-values-displayed').removeClass('hide-me');

  if (this.state.blended === true)
  {
    this.setBlended();

    if (projector.mystique === true) {
      $("#notification-mystique").removeClass('hide-me');
    }
  }
  if (this.state.setLens === true && this.state.lensPreselect !== "") {
    var lensIndex = this.state.lenses.findIndex(x => x.partno === this.state.lensPreselect);

    var lens = this.state.lenses[lensIndex];
    if(this.state.selectedLenses.indexOf(lens) === -1) {
      this.selectLens(lens, lens, lensIndex, false);
    }
  } 

  if (this.state.units === "ft") {
    $('#throwDistance').val(distance * this.state.unitsMulti);
    $('#throwDistanceInput').val(Math.floor(distance * this.state.unitsMulti));
  } else if (this.state.units === "mm" || this.state.units === "in") {      
    $('#throwDistance').val(this.myRoundMM(distance * this.state.unitsMulti));
    $('#throwDistanceInput').val(this.myRoundMM(distance * this.state.unitsMulti));
  } else {      
    $('#throwDistance').val(this.myRound(distance * this.state.unitsMulti));
    $('#throwDistanceInput').val(this.myRound(distance * this.state.unitsMulti));
  }

  this.hideThrowCard();
  this.setDistanceAlert(distance);
  this.setDiagonalAlert(this.state.diagonal);
  this.drawSideProjector();

}

toggleStar(projector) {

  var localProjectors = this.state.projectorsOrig;
  for(var x=0; x<localProjectors.length; x++) {
    if(localProjectors[x].name === projector) {
      if(localProjectors[x].starred) {
        localProjectors[x].starred = false;
      } else {
        localProjectors[x].starred = true;
      }
    }
  }
  this.setState({projectorsOrig: localProjectors});
  this.doSearch(this.state.searchValue, this.state.searchResolution, this.state.searchDiscontinued, this.state.searchStarred, this.state.searchSize, this.state.searchDistance, this.state.searchLux, this.state.searchSeries, this.state.searchIllumination);
}  

doSearch2() {

}

hideNotification(e) {
  $(e.target).closest(".notification").addClass("hide-me");
  $(e.target).closest(".notification").addClass("closed");
}

selectLens(lensName, item, index, deselect = true) {
  var lensesArr = [];
  lensesArr.push(item);
  this.setState(
    {
      selectedLenses:lensesArr
    }, 
    function() {
      var val2 = this.myRound(Number($('#screenWidth').val()));

      // change offsets
      if (item !== undefined) {
        var arr = item.offset.vertical.replace("+","").replace("-","").replace(" ","").replace(" ","").replace("%","").replace("%","").split("/");
        var arr2 = item.offset.horizontal.replace("+","").replace("-","").replace(" ","").replace(" ","").replace("%","").replace("%","").split("/");
      }
  
      var lenses = $(".lens");
      lenses.sort(function(a, b){
          return $(a).data("index")-$(b).data("index")
      });

      $(".lenses").html(lenses);

      if($("#lens" + index).hasClass("lens-selected") && deselect && this.state.lenses.length > 1) {
        $("#lens" + index).removeClass("lens-selected");   
        $("#lens" + index).closest(".lens").removeClass("selected");
        $("#lens" + index).html('Select');
        $('#offset-canvas-container').addClass('hide-me');
        $('#offset-canvas-message').removeClass('hide-me');
        $(".ust-values").addClass("hide-me");
        this.setState({ selectedLenses:lensesArr});
      } else {
        var selectLensButtons = $(".select-lens-button");
        if (deselect && this.state.lenses.length == 1)
          return;
        selectLensButtons.html("Select");
        selectLensButtons.removeClass("lens-selected");
        $("#lens" + index).addClass("lens-selected");
        $("#lens" + index).html(checkIcon() + '&nbsp;&nbsp;&nbsp;Selected');
        $(".lens").removeClass("selected");
        $("#lens" + index).closest(".lens").addClass("selected");
        $("#lens" + index).closest(".lens").prependTo($(".lenses"));
        if ($("#throwDistanceCard").hasClass("hide-me")) {
          $("#throwDistanceCard").removeClass("hide-me");
        }
        $("#single-projector").removeClass("hide-me");
        if (lensesArr.length[0] !== undefined) {
          if ((lensesArr[0].type === "UST" && lensesArr[0].zoomtype === "optional") || lensesArr[0].zoomtype === "fixed") {
            $(".ust-values").removeClass("hide-me");
          }
        }
      }
      // if (lensesArr[0] == undefined) {
      //   $("#throwDistanceCard").addClass("hide-me");
      //   $("#single-projector").addClass("hide-me");
      // }
      var mainNav = document.getElementById("mainNav");
      mainNav.parentNode.scrollIntoView();
      if (deselect && this.state.lenses.length == 1)
        return;
      this.hideThrowCard();
      //this.toggleUSTvalues();
      var iframeURLlens = this.state.selectedLenses[0] !== undefined ? "&selected_lens=" + this.state.selectedLenses[0].name : "";
      if (arr2 === undefined) {
        arr2 = ["0"];
      }
      if (arr === undefined) {
        arr = ["0"];
      }
      this.setState(
        {
          vertOff:0,
          horizOff:0,
          maxHoriz:Number(arr2[0]),
          minHoriz:-Number(arr2[1]),
          maxVert:Number(arr[0]),
          minVert: -Number(arr[1]),
          iframeURL: "https://www.christiedigital.com/lp/lc-rq/?product=" + this.state.projector.name + iframeURLlens
        }, 
        function() {
          if (this.state.selectedLenses[0] !== undefined) {
            this.gtaEvent('Select Lens', this.state.selectedLenses[0].name);
          }
          this.setMinMax();
        }
      );
    }
  );
}

deselectLens(lens) {
  this.setState({
      vertOff:0,
      horizOff:0,
      minHoriz:-10,
      maxHoriz:10,
      minVert:-10,
      maxVert:10
    }, function() {
      this.hideThrowCard();
      this.drawSideProjector();
    })
}

newImage(src){
    var tmp = new Image();
    tmp.src = src;
    return tmp
}

drawSideProjector () {
  var canvas = document.getElementById('canSide');
  var context = canvas.getContext('2d');
  //context.clearRect(0, 0, canvas.width, canvas.height);
  var width = canvas.width;
  canvas.height = canvas.width * 0.75;

  var height = canvas.height;

  var $canvas = $("#canSide");

  var stateHeight = this.state.height;
  var stateUnits = this.state.units;
  var stateUnitsMulti = this.state.unitsMulti;

  if (!$canvas.hasClass("drawing")) {
    $canvas.addClass("drawing");
    context.clearRect(0, 0, canvas.width, canvas.height);

    var screenHeight = height * 0.75;
    var screenHalfHeight = screenHeight/2;
    var screenOffset = (height - screenHeight) / 2;
    var canvasPadding = 10;

    
    var ust_adjuster_y = 0;
    var ust_adjuster_x = 0;
    var l = this.state.selectedLenses[0];

    if(l && l.type === "UST") {
      if(l.zoomtype === "snorkel_left" || l.zoomtype === "snorkel_right") {
      this.state.sideProjectorImg = this.state.projectorImages["side_snorkel"];
        ust_adjuster_x = 12;
        ust_adjuster_y = 0;
      }
    } else if(this.state.lenses[0]) {
      if (this.state.lenses[0].ust != null) {
        this.state.sideProjectorImg = this.state.projectorImages["side_ust_new"];
      } else {
        this.state.sideProjectorImg = this.state.projectorImages["standardSide"];
      }
    }

    if (this.state.sideProjectorImg.src === "") {
      this.state.sideProjectorImg = this.state.projectorImages["standardSide"];
    }


    var vertOff = this.state.vertOff;

    var baseVert = 100;
    if(Math.abs(this.state.minVert) > Math.abs(this.state.maxVert)) {
      if(Math.abs(this.state.minVert) > 100) {
        baseVert = Math.abs(this.state.minVert);
      }
    } 
    else if(Math.abs(this.state.minVert) < Math.abs(this.state.maxVert)) {
      if(Math.abs(this.state.maxVert) > 100) {
        baseVert = Math.abs(this.state.maxVert);
      }
    } else if(Math.abs(this.state.maxVert) > 100) {
        baseVert = Math.abs(this.state.maxVert);
    }
    baseVert += 20;


    vertOff = (vertOff/baseVert * (height/2));

    var topvertOff = (screenHalfHeight) - ((Math.abs(this.state.minVert)/100) * (screenHalfHeight));
    var bottomvertOff = (screenHalfHeight) - ((Math.abs(this.state.maxVert)/100) * (screenHalfHeight)); 
    var aspectratio = Number(this.state.aspectheight) / Number(this.state.aspectwidth);
    var projectorTopvertOff = topvertOff;
    var projectorBottomhorizOff = bottomvertOff;
    var projectorAspectRatio = aspectratio;
    var heightRatio = 1;
    var projectedHeight = screenHeight;

    if (this.state.projector !== undefined && this.state.projector.aspectratio !== undefined) {
      if(this.state.orientation === "h") {
        projectorAspectRatio = Number(this.state.projector.aspectratio.height) / Number(this.state.projector.aspectratio.width);
      } else {
        projectorAspectRatio = Number(this.state.projector.aspectratio.width) / Number(this.state.projector.aspectratio.height);
      }
    }

    if (projectorAspectRatio !== aspectratio) {
      heightRatio = projectorAspectRatio / aspectratio;
      if (heightRatio > 1) {
        projectedHeight = screenHeight + (screenHeight * (heightRatio / 10));
      } else {
        heightRatio = 1;  
      }
    }

    var heightOffset = 0;
    if (projectedHeight > screenHeight)
      heightOffset = (projectedHeight - screenHeight) / 2;
    else if (projectedHeight < screenHeight)
      heightOffset = -(screenHeight - projectedHeight) / 2;

      
    //this.state.sideProjectorImg.onload = function() {
      context.strokeStyle = "#000099";
      context.strokeStyle = "#e7e7e7";

      context.setLineDash([5, 3]);//dashes are 5px and spaces are 3px


      if (heightRatio > 1) {
        context.setLineDash([]);//reset dashes
        context.strokeStyle = "#000099"; //000099
        context.lineWidth = 5;
        context.beginPath();
        context.moveTo(width-50, screenOffset + canvasPadding - heightOffset);
        context.lineTo(width-50, screenHeight + screenOffset - canvasPadding + heightOffset);
        context.stroke();
        context.closePath();
        context.lineWidth = 1;
      }

      // draw vertical screen line
      context.setLineDash([]);//reset dashes
      context.strokeStyle = "#397ebb";
      context.lineWidth = 5;
      context.beginPath();
      context.moveTo(width-50, screenOffset + canvasPadding);
      context.lineTo(width-50, screenHeight + screenOffset - canvasPadding);
      context.stroke();
      context.closePath();
      context.lineWidth = 1;

      //draw range
      context.setLineDash([]);//reset dashes
      context.strokeStyle = "#397ebb";
      context.lineWidth = 2;
      context.beginPath();
      context.moveTo(1, screenOffset + canvasPadding + topvertOff); //topvertOff
      context.lineTo(1, screenHeight + screenOffset - canvasPadding - bottomvertOff); //bottomvertOff
      context.stroke();
      context.closePath();
      context.lineWidth = 1;

      context.setLineDash([5, 3]);//dashes are 5px and spaces are 3px
      context.strokeStyle = "#e7e7e7";

      // draw the triangle
      if (projectedHeight !== screenHeight) {
        context.fillStyle = "#e2e2e2";
        context.beginPath();
        context.moveTo(68, height / 2 + vertOff);
        context.lineTo(width-52, screenOffset + canvasPadding - heightOffset);
        context.lineTo(width-52, screenHeight + screenOffset - canvasPadding + heightOffset);
        context.moveTo(68, height / 2 + vertOff);
        context.fill();
      }

      context.fillStyle = "#afcbe4";
      context.beginPath();
      context.moveTo(68, height / 2 + vertOff);
      context.lineTo(width-52, screenOffset + canvasPadding);
      context.lineTo(width-52, screenHeight + screenOffset - canvasPadding);
      context.moveTo(68,height/2 + vertOff);
      context.fill();

      // draw vertical projector source horizontal line
      context.strokeStyle = "#000000";
      context.setLineDash([]);//dashes are 5px and spaces are 3px
      context.beginPath();
      context.moveTo(70,height/2);
      context.lineTo(width - 50, height/2);
      context.stroke();
      context.closePath();

      context.strokeStyle = "#397ebb";
      context.setLineDash([5, 3]);//dashes are 5px and spaces are 3px
      if(vertOff !== 0) {
        context.setLineDash([5, 3]);//dashes are 5px and spaces are 3px
        context.beginPath();
        context.moveTo(70,height/2 + vertOff);
        context.lineTo(width - 50, height/2 + vertOff);
        context.stroke();
        context.closePath();
      }

      context.font = "12px Arial";
      context.fillStyle = "#000000";


      context.setLineDash([]);//reset dashes
      context.strokeStyle = "#000099";


      context.strokeStyle = "";
      context.drawImage(this.state.sideProjectorImg, -6 + ust_adjuster_x, height/2 - 31 + vertOff, 93, 62);

      $canvas.removeClass("drawing");
    //}

    var screenText = "Content height: " + this.myRound(stateHeight * stateUnitsMulti) + " " + stateUnits;
    if(this.state.units === "mm" || this.state.units === "in") {
      screenText = "Content height: " + this.myRoundMM(stateHeight * stateUnitsMulti) + " " + stateUnits;
    }
    if(this.state.units === "ft") {
      screenText = "Content height: " + this.feetAndInches(stateHeight * stateUnitsMulti);
    }

    if (heightRatio !== 1) {
      if (this.state.units !== "ft") {
        if (this.state.units === "mm" || this.state.units === "in") {
          screenText += "<br/>Projected image height: " + this.myRoundMM((stateHeight * heightRatio) * stateUnitsMulti) + " " + stateUnits;
        } else {
          screenText += "<br/>Projected image height: " + this.myRound((stateHeight * heightRatio) * stateUnitsMulti) + " " + stateUnits;
        }
      } else {
        screenText += "<br/>Projected image height: " + this.feetAndInches((stateHeight * heightRatio) * stateUnitsMulti);
      }
    }

    $("#side-view-throw").html(screenText);

    if (!isNaN(this.state.distance)) {
      var throwDistanceText = "Throw: " + this.myRound(this.state.distance * stateUnitsMulti) + " " + stateUnits;

      if(this.state.units === "mm" || this.state.units === "in") {
        throwDistanceText = "Throw: " + this.myRoundMM(this.state.distance * stateUnitsMulti) + " " + stateUnits;
      }

      if(this.state.units === "ft") {
        throwDistanceText = "Throw: " + this.feetAndInches(this.state.distance * stateUnitsMulti);
      }

      $("#throw-distance-sim").show();
      $("#throw-distance-sim").html(throwDistanceText);
    } else {
      $("#throw-distance-sim").hide()
    }
  }


  // rotate canvas if snorkel left
  if (l !== undefined) {
    if(l.zoomtype === "snorkel_left") {
      $("#canSide").addClass("chart-rotated");
      //context.translate(context.width/2,context.height/2);
      //context.rotate(180 * Math.PI / 180)
    } else {
      $("#canSide").removeClass("chart-rotated");

    }
  }

  this.drawTopProjector();
}


drawTopProjector() {
  // draw top view
  var canvas = document.getElementById('canTop');
  var context = canvas.getContext('2d');


  var $canvas = $("#canTop");

  if (!$canvas.hasClass("drawing")) {
    $canvas.addClass("drawing");

    var width = canvas.width;
    var height = canvas.height;
    var screenWidth = width * 0.75;
    var screenHalfWidth = screenWidth/2;
    var screenOffset = (width - screenWidth) / 2;
    var canvasPadding = 10;

    context.setTransform(1, 0, 0, 1, 0, 0);
    context.moveTo(0,0)
    context.clearRect(0, 0, canvas.width, canvas.height);

    // draw projector image width 100, height 62

    var ust_adjuster_y = 0;
    var ust_adjuster_x = 0;
    var l = this.state.selectedLenses[0];
    //var projectorImg = "";

    if(l && l.type === "UST") {
      if(l.zoomtype === "snorkel_left") {
        this.state.topProjectorImg = this.state.projectorImages["top_snorkel_left"];
        ust_adjuster_x = 22;
        ust_adjuster_y = -10;
      } else if (l.zoomtype === "snorkel_right") {
        this.state.topProjectorImg = this.state.projectorImages["top_snorkel_right"];
        ust_adjuster_x = -20;
        ust_adjuster_y = -10;
      }
    } else {
        this.state.topProjectorImg = this.state.projectorImages["standardTop"];
    }
    if (this.state.topProjectorImg.src === "")
        this.state.topProjectorImg = this.state.projectorImages["standardTop"];

    // this.setState({
    //   topProjectorImg: new Image({src: projectorImg })
    // })
    //this.state.topProjectorImg.src = projectorImg;

    var horizOff = this.state.horizOff;

    var baseHoriz = 100;
    if(Math.abs(this.state.minHoriz) > Math.abs(this.state.maxHoriz)) {
      if(Math.abs(this.state.minHoriz) > 100) {
        baseHoriz = Math.abs(this.state.minHoriz);
      }
    } 
    else if(Math.abs(this.state.minHoriz) < Math.abs(this.state.maxHoriz)) {
      if(Math.abs(this.state.maxHoriz) > 100) {
        baseHoriz = Math.abs(this.state.maxHoriz);
      }
    } else if(Math.abs(this.state.maxHoriz) > 100) {
        baseHoriz = Math.abs(this.state.maxHoriz);
    }
    baseHoriz += 20;

    horizOff = (horizOff/baseHoriz * screenHalfWidth);

    var lefthorizOff = screenHalfWidth - ((Math.abs(this.state.minHoriz)/100) * screenHalfWidth);
    var righthorizOff = screenHalfWidth - ((Math.abs(this.state.maxHoriz)/100) * screenHalfWidth);
    var aspectratio = Number(this.state.aspectheight) / Number(this.state.aspectwidth);

    var projectorAspectRatio = aspectratio;
    var widthRatio = 1;
    var projectedWidth = width;

    if (this.state.projector !== undefined && this.state.projector.aspectratio !== undefined) {
      if(this.state.orientation === "h") {
        projectorAspectRatio = Number(this.state.projector.aspectratio.height) / Number(this.state.projector.aspectratio.width);
      } else {
        projectorAspectRatio = Number(this.state.projector.aspectratio.width) / Number(this.state.projector.aspectratio.height);
      }
    }

    if (projectorAspectRatio !== aspectratio) {
      widthRatio = aspectratio / projectorAspectRatio;

      if (widthRatio > 1) {
        projectedWidth = screenWidth + (screenWidth * (widthRatio / 10));
      } else {
        widthRatio = 1;
      }
    }

    var widthOffset = 0;
    if (projectedWidth > screenWidth)
      widthOffset = (projectedWidth - screenWidth) / 2;
    else if (projectedWidth < screenWidth)
      widthOffset = -(screenWidth - projectedWidth) / 2;

    //projector.onload = function() {

      context.strokeStyle = "#000099";
      context.strokeStyle = "#e7e7e7";
      context.setLineDash([5, 3]);//dashes are 5px and spaces are 3px

      //draw projected screen size
      if (widthRatio > 1) {
        context.setLineDash([]);//reset dashes
        context.strokeStyle = "#000099";
        context.lineWidth = 5;
        context.beginPath();
        context.moveTo(screenOffset + canvasPadding - widthOffset, 3);
        context.lineTo(screenWidth + screenOffset - canvasPadding + widthOffset, 3);
        context.stroke();
        context.closePath();
        context.lineWidth = 1;
      }

      // draw horizontal screen line
      context.setLineDash([]);//reset dashes
      context.strokeStyle = "#397ebb";
      context.lineWidth = 5;
      context.beginPath();
      context.lineTo(screenOffset + canvasPadding, 3);
      context.lineTo(screenWidth + screenOffset - canvasPadding, 3);
      context.stroke();
      context.closePath();
      context.lineWidth = 1;


      // draw range
      context.setLineDash([]);//reset dashes
      context.strokeStyle = "#397ebb";
      context.lineWidth = 2;
      context.beginPath();
      context.moveTo(screenOffset + canvasPadding + lefthorizOff, height-2);
      context.lineTo(screenWidth + screenOffset - canvasPadding - righthorizOff, height-2);
      context.stroke();
      context.closePath();
      context.lineWidth = 1;


      context.setLineDash([5, 3]);//dashes are 5px and spaces are 3px

      // draw the triangle
      var fillColor = "#afcbe4";
      if (projectedWidth !== width) {
        context.fillStyle = "#e2e2e2";
        context.beginPath();
        context.moveTo(width/2 + horizOff, height - 58);
        context.lineTo(screenOffset + canvasPadding - widthOffset, 5);
        context.lineTo(screenWidth + screenOffset - canvasPadding + widthOffset, 5);
        context.moveTo(width/2 + horizOff, height - 58);
        context.fill();
      }

      context.fillStyle = fillColor;
      context.beginPath();
      context.moveTo(width/2 + horizOff, height - 58);
      context.lineTo(screenOffset + canvasPadding, 5);
      context.lineTo(screenWidth + screenOffset - canvasPadding, 5);
      context.moveTo(width/2 + horizOff, height - 58);
      context.fill();

      // draw vertical projector source horizontal line
      context.strokeStyle = "#000000";
      context.setLineDash([5, 3]);//dashes are 5px and spaces are 3px
      context.setLineDash([]);//reset dashes
      context.beginPath();
      context.moveTo(width/2,5);
      context.lineTo(width/2, height-58);
      context.stroke();
      context.closePath();

      // draw secondary light line if needed
      context.strokeStyle = "#e7e7e7";
      context.strokeStyle = "#397ebb";
      context.setLineDash([5, 3]);//dashes are 5px and spaces are 3px

      if(horizOff !== 0) {
        context.beginPath();
        context.moveTo(width/2 + horizOff,2);
        context.lineTo(width/2 + horizOff, height-58);
        context.stroke();
        context.closePath();    
      }

      context.drawImage(this.state.topProjectorImg, width/2-30+ust_adjuster_x + horizOff, height - 60 + ust_adjuster_y, 60, 50);

      context.strokeStyle = "";
      $canvas.removeClass("drawing");
    //}



    var screenText = "Content width: " + this.myRound(this.state.width * this.state.unitsMulti) + " " + this.state.units;

    if(this.state.units === "mm" || this.state.units === "in") {
      screenText = "Content width: " + this.myRoundMM(this.state.width * this.state.unitsMulti) + " " + this.state.units;
    }

    if(this.state.units === "ft") {
      screenText = "Content width: " + this.feetAndInches(this.state.width * this.state.unitsMulti);
    }

    if (widthRatio !== 1) {
      if (this.state.units !== "ft") {
        screenText += "<br/>Projected image width: " + this.myRound((this.state.width * widthRatio) * this.state.unitsMulti) + " " + this.state.units;
      } else {
        screenText += "<br/>Projected image width: " + this.feetAndInches((this.state.width * widthRatio) * this.state.unitsMulti);
      }
    }

    $("#top-view-width").html(screenText);
  }

  this.drawScreen();
}

drawScreen() {
  var canvas = document.getElementById('canScreen');
  var context = canvas.getContext('2d');
  var width = canvas.width;
  var height = canvas.height;
  var ratio = 1; 
  var aspectratio = Number(this.state.aspectheight) / Number(this.state.aspectwidth);
  var projectorAspectRatio = aspectratio;

  if (this.state.projector !== undefined && this.state.projector.aspectratio !== undefined) {
    if(this.state.orientation === "h") {
      projectorAspectRatio = Number(this.state.projector.aspectratio.height) / Number(this.state.projector.aspectratio.width);
    } else {
      projectorAspectRatio = Number(this.state.projector.aspectratio.width) / Number(this.state.projector.aspectratio.height);
    }

    ratio = aspectratio;

    if(this.state.orientation === "h") {
      canvas.height = (width * ratio) + 10;
    } else {
      canvas.height = canvas.width + 10;
    }


    var x = 22;
    var y = 22;
    var renderMultiplier = .85;

    if(this.state.orientation === "h") {
      height = width * ratio;
    }
    else {
      height = width;
      width = height / ratio;
    }

    var w = width * renderMultiplier;
    var h = height * renderMultiplier;

    context.stroke();
    context.restore();

    var screenWidth = this.state.width;
    var screenHeight = this.state.height;
    var projectedText = "";

    if (projectorAspectRatio !== aspectratio) {
      var widthRatio = 1;
      var projectedWidth = screenWidth;

      widthRatio = aspectratio / projectorAspectRatio;

      var widthOffset = 0;
      var heightOffset = 0;

      if (widthRatio < 1) {
        projectedWidth = screenWidth * widthRatio;
        widthOffset = (w - (w * widthRatio)) / 2;
      } else if (widthRatio > 1) {
        projectedWidth = screenWidth;
        widthOffset = 0;
        heightOffset = (h - (h / widthRatio)) / 2;
      }

      context.setTransform(1, 0, 0, 1, 0, 0);
      context.moveTo(0,0)
      context.clearRect(0, 0, canvas.width, canvas.height);

      // draw green box
      context.save();
      // context.strokeStyle = "#397ebb";
      // context.fillStyle = "#f4f7fb";
      //context.beginPath();

      if (widthRatio < 1) {

        context.beginPath();
        context.fillStyle = "#555555";
        context.fillRect(x, y, w, h);
        context.save();

        context.beginPath();
        context.moveTo(0,0);
        context.fillStyle = "#f4f7fb";
        context.fillRect(x + widthOffset, y, w * widthRatio, h);
        context.rect(x, y, w, h);
        context.save();

        context.moveTo(0,0);
        context.lineWidth = 2;
        context.strokeStyle = "#397ebb";
        context.strokeRect(x, y, w, h);
        context.save();

      } else if (widthRatio > 1) {

        context.beginPath();
        context.fillStyle = "#555555";
        context.fillRect(x, y, w, h);
        context.save();

        context.beginPath();
        context.moveTo(0,0);
        context.fillStyle = "#f4f7fb";
        context.fillRect(x, y + heightOffset, w, h / widthRatio);
        context.rect(x, y, w, h);
        context.save();        

        context.moveTo(0,0);
        context.lineWidth = 2;
        context.strokeStyle = "#397ebb";
        context.strokeRect(x, y, w, h);
        context.save();
      }

      var projectorWidth = this.state.width * this.state.unitsMulti;
      var projectorHeight = this.state.height * this.state.unitsMulti;
      var projectorDiagonal = this.state.diagonal * this.state.unitsMulti;

      if (widthRatio < 1) {
        projectorWidth = this.state.width * widthRatio * this.state.unitsMulti;
      } else {
        projectorHeight = this.state.width * projectorAspectRatio * this.state.unitsMulti;
      }


      if(this.state.orientation !== "h") {
        projectorDiagonal = this.getProjectedDiagonalFromWidth(projectedWidth, this.state.projector.aspectratio.height, this.state.projector.aspectratio.width) * this.state.unitsMulti;        
      } else {
        projectorDiagonal = this.getProjectedDiagonalFromWidth(projectedWidth, this.state.projector.aspectratio.width, this.state.projector.aspectratio.height) * this.state.unitsMulti;
      }

      if (this.state.units !== "ft" && this.state.units !== "mm" && this.state.units !== "in") {
        projectedText =   "Projected image width: " + this.myRound(projectorWidth) + " " + this.state.units +
                          "<br/>Projected image height: " + this.myRound(projectorHeight) + " " + this.state.units +
                          "<br/>Projected image diagonal: " + this.myRound(projectorDiagonal) + " " + this.state.units;
      } else if (this.state.units === "mm" || this.state.units === "in") {
        projectedText =   "Projected image width: " + this.myRoundMM(projectorWidth) + " " + this.state.units +
                          "<br/>Projected image height: " + this.myRoundMM(projectorHeight) + " " + this.state.units +
                          "<br/>Projected image diagonal: " + this.myRoundMM(projectorDiagonal) + " " + this.state.units;
      } else {
        projectedText =   "Projected image width: " + this.feetAndInches(projectorWidth) +
                          "<br/>Projected image height: " + this.feetAndInches(projectorHeight) +
                          "<br/>Projected image diagonal: " + this.feetAndInches(projectorDiagonal);
      }

      if (this.state.orientation === "h") {
        projectedText +="<br/>Projected aspect ratio: " + this.state.projector.aspectratio.width + ":" + this.state.projector.aspectratio.height;
      } else {
        projectedText +="<br/>Projected aspect ratio: " + this.state.projector.aspectratio.height + ":" + this.state.projector.aspectratio.width;
      }

      $("#projected-image-dimensions").html(projectedText);
      $("#projected-image-dimensions").show();
    } else {

      context.setTransform(1, 0, 0, 1, 0, 0);
      context.moveTo(0,0)
      context.clearRect(0, 0, canvas.width, canvas.height);

      // draw green box
      context.save();
      context.lineWidth = 2;
      context.strokeStyle = "#397ebb";
      context.fillStyle = "#f4f7fb"
      context.beginPath();

      context.strokeRect(x, y, w, h);
      //context.rect(x, y, w, h);

      $("#projected-image-dimensions").hide();
    }
  }

  context.fillStyle = "#000000";

  var imageText = "";
  if (this.state.units !== "ft") {
    if (this.state.units === "mm" || this.state.units === "in") {
      imageText = "Screen width: " + this.myRoundMM(this.state.width * this.state.unitsMulti) + " " + this.state.units +
                  "<br/>Screen height: " + this.myRoundMM(this.state.height * this.state.unitsMulti) + " " + this.state.units +
                  "<br/>Screen diagonal: " + this.myRoundMM(this.state.diagonal * this.state.unitsMulti) + " " + this.state.units;
    } else {
      imageText = "Screen width: " + this.myRound(this.state.width * this.state.unitsMulti) + " " + this.state.units +
                  "<br/>Screen height: " + this.myRound(this.state.height * this.state.unitsMulti) + " " + this.state.units +
                  "<br/>Screen diagonal: " + this.myRound(this.state.diagonal * this.state.unitsMulti) + " " + this.state.units;
    }
  } else {
    imageText = "Screen width: " + this.feetAndInches(this.state.width * this.state.unitsMulti) +
                "<br/>Screen height: " + this.feetAndInches(this.state.height * this.state.unitsMulti) +
                "<br/>Screen diagonal: " + this.feetAndInches(this.state.diagonal * this.state.unitsMulti);
  }

  if (this.state.orientation === "h") {
    imageText +="<br/>Screen aspect ratio: " + this.state.aspectwidth + ":" + this.state.aspectheight;
  } else {
    imageText +="<br/>Screen aspect ratio: " + this.state.aspectwidth + ":" + this.state.aspectheight;
  }

  $("#content-image-dimensions").html(imageText);

  this.drawScreenText();
}

drawScreenText() {
  var canvas = document.getElementById('canScreen');
  var context = canvas.getContext('2d');
  var width = canvas.width;
  var height = canvas.height;  

  var ratio = this.state.aspectheight / this.state.aspectwidth;

  context.font = "10px Arial";
  context.textAlign = "center"; 

  context.save();

  context.restore();


  this.drawContrast();
}

drawContrast() {
  var canvas = document.getElementById('canContrast');
  var context = canvas.getContext('2d');
  context.clearRect(0, 0, canvas.width, canvas.height);
  var width = canvas.width;
  var height = canvas.height;  

  var contrast = new Image();
  contrast.src = 'contrast.png';
  contrast.onload = function() {

    context.drawImage(contrast, 25, height/2 - 60, 40, 120);
  }

  context.font = "8px Arial";
  context.textAlign = "center"; 
  context.fillText("75 - 100", 90, 68); 

  var contrast_thumb = new Image();
  contrast_thumb.src = 'contrast_thumb.png';
  contrast_thumb.onload = function() {

    context.drawImage(contrast_thumb, width-158, height/2 - 45, 133, 90);
  }

  this.drawShift();
}

drawShift() {
  var canvas = document.getElementById('canShift');
  var context = canvas.getContext('2d');
  context.clearRect(0, 0, canvas.width, canvas.height);
  var width = canvas.width;
  var height = canvas.height;  

  context.font = "12px Arial";
  context.textAlign = "center"; 
  context.fillText("No image/data available", width/2, (height/2)+6); 

  this.drawBlend();
}

drawBlend() {

  var canvas = document.getElementById('canBlend');
  var context = canvas.getContext('2d');
  var width = canvas.width;
  var height = canvas.height;  
  var ratio = 1; 
  

  ratio = this.state.aspectheight / this.state.aspectwidth;
  if(this.state.orientation === "h") {
    canvas.height = (width * ratio) + 10;
  } else {
    canvas.height = canvas.width + 10;
  }

  context.setTransform(1, 0, 0, 1, 0, 0);
  context.moveTo(0,0)
  context.clearRect(0, 0, canvas.width, canvas.height);

  // draw green box
  context.save();
  context.strokeStyle = "#397ebb";
  context.fillStyle = "#f4f7fb"
  context.beginPath();

  var x = 22;
  var y = 22;

  if(this.state.orientation === "h") {
    height = width * ratio;
  }
  else {
    height = width;
    width = height / ratio;
  }

  var w = width * .85;
  var h = height * .85 - 22;

  context.fillRect(x, y, w, h);
  context.rect(x, y, w, h);

  context.stroke();
  context.restore();

  // draw overlaps
  if(this.state.noProjectors === 2) {
    // draw one overlap
    var canWidthHalf = canvas.width/2;

    context.save();

    context.fillStyle = "#c1d4eb";
    context.fillRect(canvas.width/2 - 20,23,40, (canvas.height * .85) -31);

    context.strokeStyle = "#397ebb";
    context.setLineDash([5, 3]);//dashes are 5px and spaces are 3px
    context.beginPath();
    context.moveTo(width/2 -20,2);
    context.lineTo(width/2 -20, height-2);
    context.stroke();
    context.closePath();
    context.beginPath();
    context.moveTo(width/2 +20,2);
    context.lineTo(width/2 +20, height-2);
    context.stroke();
    context.closePath();

    context.restore();


  } else if(this.state.noProjectors === 3) {
    var canWidthThird = canvas.width/3;

    context.save();

    context.fillStyle = "#c1d4eb";
    context.fillRect(canWidthThird - 15,23,30, (canvas.height * .85) -31);
    context.fillRect(canWidthThird*2 - 15,23,30, (canvas.height * .85) -31);

    context.strokeStyle = "#397ebb";
    context.setLineDash([5, 3]);//dashes are 5px and spaces are 3px
    context.beginPath();
    context.moveTo(canWidthThird -15,2);
    context.lineTo(canWidthThird -15, height-2);
    context.stroke();
    context.closePath();
    context.beginPath();
    context.moveTo(canWidthThird +15,2);
    context.lineTo(canWidthThird +15, height-2);
    context.stroke();
    context.closePath();
    context.beginPath();
    context.moveTo(canWidthThird*2 -15,2);
    context.lineTo(canWidthThird*2 -15, height-2);
    context.stroke();
    context.closePath();
    context.beginPath();
    context.moveTo(canWidthThird*2 +15,2);
    context.lineTo(canWidthThird*2 +15, height-2);
    context.stroke();
    context.closePath();

    context.restore();  
  }


}

  getProjectedDiagonalFromWidth(projectedImageWidth , aspectRatioWidth , aspectRatioHeight) {
    return (projectedImageWidth * Math.sqrt(Math.pow(aspectRatioWidth, 2) + Math.pow(aspectRatioHeight, 2)) / aspectRatioWidth);
  }
  getProjectedImageWidthFromDiagonal(screenDiagonal, aspectRatioWidth, aspectRatioHeight) {
    return (screenDiagonal * aspectRatioWidth / Math.sqrt(Math.pow(aspectRatioWidth, 2) + Math.pow(aspectRatioHeight, 2)));
  }
  getProjectedImageHeightFromDiagonal(screenDiagonal, aspectRatioWidth , aspectRatioHeight) {
    return (screenDiagonal * aspectRatioHeight / Math.sqrt(Math.pow(aspectRatioWidth, 2) + Math.pow(aspectRatioHeight, 2)));
  }

  getThrowDistance() {
    return (Number(this.state.lenses[0].formula[0].slope) * Number(this.state.width) + (Number(this.state.lenses[0].formula[0].intercept)* this.state.unitsMulti)   );
  }

  getWidthFromThrowDistance() {
    var lens = this.state.selectedLenses[0];

    if (lens.formula === undefined)
      lens = this.state.lenses.filter(x => x.formula !== undefined)[0];

    var intercept;
    var slope;    
    var formula;
    var width = this.state.width;
    if (lens !== undefined) {
      if (Array.isArray(lens.formula)) {
        var minFormula = lens.formula.find(x => x.type === "min");
        var maxFormula = minFormula;
        var minWidth, maxWidth;

        if (lens.formula.length > 1)
          maxFormula = lens.formula.find(x => x.type === "max");

        if (lens.formula.find(x => x.type === "min") === undefined) {
          minFormula = lens.formula.find(x => x.type === "fixed");
          maxFormula = lens.formula.find(x => x.type === "fixed");
        }
        minWidth = (Number(this.state.distance) - Number(minFormula.intercept)) / Number(minFormula.slope);
        maxWidth = (Number(this.state.distance) - Number(maxFormula.intercept)) / Number(maxFormula.slope);

        width = (minWidth + maxWidth) / 2;

        if (width < minWidth)
          width = minWidth;
        if (width > maxWidth)
          width = maxWidth;
      }
      else if (lens.formula !== undefined) {
        intercept = Number(lens.formula.intercept);
        slope = Number(lens.formula.slope);
        var distance = (Number(this.state.distance));
        width = (distance - intercept) / slope;
      }
    }

    return width;
  }

  toggleProjectors() {
    $('#searchProjectors').removeClass('hidden');
    
    $('#projectorName').addClass('disabled');
    $('#setValues').addClass('disabled');
    $('#simulation').addClass('disabled');
    $('#lenses').addClass('disabled');
    $('#mainNav').addClass('hide-me');
    $('.notification').addClass('hide-me');

  }

  myRoundNoDecimals(num) {
    return parseFloat(Number(num).toFixed(0));
  }

  myRound(num) {
    return parseFloat(Number(num).toFixed(2));
  }

  myRoundMM(num) {
    return parseFloat(Number(num).toFixed(0));
  }

  myRoundFeet(num) {
    return parseFloat(Number(num).toFixed(0));
  }

  positiveOnly(key) {
    if (this.state.units === "cm" || this.state.units === "m" || this.state.units === "in" || (this.state.units === "ft" && key.target.id.indexOf('InInput') > -1)) {
      if (key.charCode === 46 || (key.charCode >= 48 && key.charCode <= 57))
        return true;
      else
        return key.preventDefault();

    } else {
      if ((key.charCode >= 48 && key.charCode <= 57))
        return true;
      else
        return key.preventDefault();
    }
  }

  inputValueCheck(key) {
    if(key.target.value.length > 10) {
      key.target.value  = 1000000000;
    }

  }

  manualChangeWidth(e) {
    if(e.target.value.charAt(e.target.value.length - 1) === ".")
      return;
    if ((e.target.value.charAt(e.target.value.length - 2) === ".") && (e.target.value.charAt(e.target.value.length - 1) === "0")) {
      return;
    }
    var normalizedWidth = Number(e.target.value); 
    if (e.target.value.indexOf(".") === -1)
      normalizedWidth = Math.abs(Number(e.target.value));

    if (this.state.units !== 'ft') {
      this.changeWidth(normalizedWidth / this.state.unitsMulti, false);
    } else {
      var normalizedInches = ((parseFloat(normalizedWidth) + (Number($('#manualWidthInInput').val()) / 12))  / this.state.unitsMulti);
      this.changeWidth(normalizedInches, false);
    }
  }

  manualChangeWidthInches(e) {
    if(e.target.value.charAt(e.target.value.length - 1) === ".")
      return;

    var inches = Number(e.target.value);
    var ftInches = Number($('#manualWidth').val());
    this.changeWidth((ftInches + (inches / 12)) / this.state.unitsMulti, false);
  }

  changeWidth(val, b) {
    var height = Number(this.state.aspectheight) * Number(val) / (Number(this.state.aspectwidth) * 1.0);
    var diagonal = this.getProjectedDiagonalFromWidth(Number(val), Number(this.state.aspectwidth), Number(this.state.aspectheight));
    var width =  Number(val);
    var diagonalMin = this.state.selectedLenses[0].diagonal.min;
    var diagonalMax = this.state.selectedLenses[0].diagonal.max;

    $("#screenWidth").val(this.myRound(width));
    $('#screenHeight').val(this.myRound(height));
    $('#screenDiagonal').val(this.myRound(diagonal));
    this.setState(
      {
        width:width,
        height:height,
        diagonal:diagonal
      },
      function() {
        if(!b) {
          if (this.state.units !== "ft") {
            if (this.state.units === "mm" || this.state.units === "in") {
              $('#manualWidth').val(this.myRoundMM(width * this.state.unitsMulti));
            } else {
              $('#manualWidth').val(this.myRound(width * this.state.unitsMulti));
            }
          }
          else {
            var feetInchArray = this.getFeetAndInches(width * this.state.unitsMulti);
            $('#manualWidth').val(feetInchArray[0]);
            $('#manualWidthInInput').val(feetInchArray[1]);
          }
        }        

        if (this.state.units !== "ft") {
          if (this.state.units === "mm" || this.state.units === "in") {
            $('#manualHeight').val(this.myRoundMM(height  * this.state.unitsMulti));
            $('#manualDiagonal').val(this.myRoundMM(diagonal * this.state.unitsMulti));
          } else {
            $('#manualHeight').val(this.myRound(height  * this.state.unitsMulti));
            $('#manualDiagonal').val(this.myRound(diagonal * this.state.unitsMulti));
          }
        } else {
          var feetInchArrayHeight = this.getFeetAndInches(height  * this.state.unitsMulti);
          var feetInchArrayDiagonal = this.getFeetAndInches(diagonal * this.state.unitsMulti);
          $('#manualHeight').val(feetInchArrayHeight[0]);
          $('#manualHeightInInput').val(feetInchArrayHeight[1]);
          $('#manualDiagonal').val(feetInchArrayDiagonal[0]);
          $('#manualDiagonalInInput').val(feetInchArrayDiagonal[1]);
        }

        // blend stuff
        if (this.state.blended)
          this.changeOverallWidth(this.state.overallWidth);

        if (diagonal >= diagonalMin && diagonal <= diagonalMax) {
          // set min/max distance
          if (this.state.calculateThrow)
            this.setMinMax();
          else
            this.drawSideProjector();
        }

        
        this.setDiagonalAlert(diagonal);
      }
    );
  }

  changeWidthNotHeight(val, b) {
    var height = this.state.height;
    var width =  Number(this.state.aspectwidth) * height / (Number(this.state.aspectheight) * 1.0);
    var diagonal = this.getProjectedDiagonalFromWidth(Number(width), Number(this.state.aspectwidth), Number(this.state.aspectheight));
    
    var diagonalMin = this.state.selectedLenses[0].diagonal.min;
    var diagonalMax = this.state.selectedLenses[0].diagonal.max;
    $("#screenWidth").val(this.myRound(width));
    $('#screenHeight').val(this.myRound(height));
    $('#screenDiagonal').val(this.myRound(diagonal));
    this.setState(
      {
        width:width,
        height:height,
        diagonal:diagonal
      },
      function() {
        if(!b) {
          if (this.state.units === "mm" || this.state.units === "in") {
            $('#manualWidth').val(this.myRoundMM(width * this.state.unitsMulti));
          } else {
            $('#manualWidth').val(this.myRound(width * this.state.unitsMulti));
          }
        }
        if (this.state.units === "mm" || this.state.units === "in") {
          $('#manualHeight').val(this.myRoundMM(height  * this.state.unitsMulti));
          $('#manualDiagonal').val(this.myRoundMM(diagonal * this.state.unitsMulti));
        } else {
          $('#manualHeight').val(this.myRound(height  * this.state.unitsMulti));
          $('#manualDiagonal').val(this.myRound(diagonal * this.state.unitsMulti));
        }

        if (this.state.units === "ft") {
          var feetInchArrayHeight = this.getFeetAndInches(height * this.state.unitsMulti);
          var feetInchArrayWidth = this.getFeetAndInches(width * this.state.unitsMulti);
          var feetInchArrayDiagonal = this.getFeetAndInches(diagonal * this.state.unitsMulti);
          $('#manualHeight').val(feetInchArrayHeight[0]);
          $('#manualHeightInInput').val(feetInchArrayHeight[1]);
          $('#manualWidth').val(feetInchArrayWidth[0]);
          $('#manualWidthInInput').val(feetInchArrayWidth[1]);
          $('#manualDiagonal').val(feetInchArrayDiagonal[0]);
          $('#manualDiagonalInInput').val(feetInchArrayDiagonal[1]);
        }

        // blend stuff
        if (this.state.blended)
          this.changeOverallWidth(this.state.overallWidth);

        if (diagonal >= diagonalMin && diagonal <= diagonalMax) {
          // set min/max distance
          if (this.state.calculateThrow)
            this.setMinMax();
          else
            this.drawSideProjector();
        }

        
        this.setDiagonalAlert(diagonal);
      }
    );
  }

  manualChangeHeight(e) {
    if(e.target.value.charAt(e.target.value.length - 1) === ".")
      return;
    if ((e.target.value.charAt(e.target.value.length - 2) === ".") && (e.target.value.charAt(e.target.value.length - 1) === "0")) {
      return;
    }
    var normalizedHeight = Number(e.target.value);

    if (this.state.units !== 'ft') {
      this.changeHeight(normalizedHeight / this.state.unitsMulti, false);
    } else {
      var normalizedInches = ((parseFloat(normalizedHeight) + (parseFloat(Number($('#manualHeightInInput').val()) / 12)))  / this.state.unitsMulti);
      this.changeHeight(normalizedInches, false);
    }
  }

  manualChangeHeightInches(e) {
    if(e.target.value.charAt(e.target.value.length - 1) === ".")
      return;

    var inches = Number(e.target.value);
    var ftInches = Number($('#manualHeight').val());
    this.changeHeight((ftInches + (inches / 12)) / this.state.unitsMulti, false);
  }

  changeHeight(val, b) {
    var height = Number(val);
    var width =  (Number(this.state.aspectwidth) * Number(val))  / (Number(this.state.aspectheight) * 1.0);
    var diagonal = this.getProjectedDiagonalFromWidth(Number($('#screenWidth').val()), Number(this.state.aspectwidth), Number(this.state.aspectheight));

    var diagonalMin = this.state.selectedLenses[0].diagonal.min;
    var diagonalMax = this.state.selectedLenses[0].diagonal.max;

    $('#screenWidth').val(this.myRound(width));
    $('#screenHeight').val(this.myRound(height));
    $('#screenDiagonal').val(this.myRound(diagonal));
    this.setState(
      {
        width:width,
        height:height,
        diagonal:diagonal
      },
      function() {
        if(!b) {
          if (this.state.units !== "ft") {
            if (this.state.units === "mm" || this.state.units === "in") {
              $('#manualHeight').val(this.myRoundMM(height * this.state.unitsMulti));
            } else {
              $('#manualHeight').val(this.myRound(height * this.state.unitsMulti));
            }
          } else {
            var feetInchArray = this.getFeetAndInches(height * this.state.unitsMulti);
            $('#manualHeight').val(feetInchArray[0]);
            $('#manualHeightInInput').val(feetInchArray[1]);
          }
        }        

        if (this.state.units !== "ft") {
          if (this.state.units === "mm" || this.state.units === "in") {
            $('#manualWidth').val(this.myRoundMM(width  * this.state.unitsMulti));
            $('#manualDiagonal').val(this.myRoundMM(diagonal * this.state.unitsMulti));
          } else {
            $('#manualWidth').val(this.myRound(width  * this.state.unitsMulti));
            $('#manualDiagonal').val(this.myRound(diagonal * this.state.unitsMulti));
          }

        } else {
          var feetInchArrayWidth = this.getFeetAndInches(width  * this.state.unitsMulti);
          var feetInchArrayDiagonal = this.getFeetAndInches(diagonal * this.state.unitsMulti);
          $('#manualWidth').val(feetInchArrayWidth[0]);
          $('#manualWidthInInput').val(feetInchArrayWidth[1]);
          $('#manualDiagonal').val(feetInchArrayDiagonal[0]);
          $('#manualDiagonalInInput').val(feetInchArrayDiagonal[1]);
        }
        if (diagonal >= diagonalMin && diagonal <= diagonalMax) {
          if (this.state.blended)
            this.changeOverallWidth(this.state.overallWidth);

          // set min/max distance
          if (this.state.calculateThrow)
            this.setMinMax();
          else
            this.drawSideProjector();

        }

        this.setDiagonalAlert(diagonal);

        //this.drawSideProjector();
      }
    );
  }

  manualChangeDiagonal(e) {
    if(e.target.value.charAt(e.target.value.length - 1) === ".")
      return;
    if ((e.target.value.charAt(e.target.value.length - 2) === ".") && (e.target.value.charAt(e.target.value.length - 1) === "0")) {
      return;
    }
    var normalizedDiagonal = Number(e.target.value);

    if (this.state.units !== 'ft') {
      this.changeDiagonal(normalizedDiagonal / this.state.unitsMulti, false);
    } else {
      //var normalizedInches = ((parseFloat(normalizedDiagonal) + (parseFloat(Number($('#manualDiagnoalInInput').val()) / 12)))  / this.state.unitsMulti);
      var parsedNormalizedDiagonal = parseFloat(normalizedDiagonal);
      var parsedDiagonalInInput = parseFloat(Number($('#manualDiagonalInInput').val()) / 12);
      var normalizedInches = (parsedNormalizedDiagonal + parsedDiagonalInInput) / this.state.unitsMulti;
      this.changeDiagonal(normalizedInches, false);
    }
  }

  manualChangeDiagonalInches(e) {
    if(e.target.value.charAt(e.target.value.length - 1) === ".") {
      return;
    }
    var inches = Number(e.target.value);
    var ftInches = Number($('#manualDiagonal').val());
    this.changeDiagonal((ftInches + (inches / 12)) / this.state.unitsMulti, false);
  }

  changeDiagonal(val, b) {
    var width = this.getProjectedImageWidthFromDiagonal(Number(val), Number(this.state.aspectwidth), Number(this.state.aspectheight));
    var height = this.getProjectedImageHeightFromDiagonal(Number(val), Number(this.state.aspectwidth), Number(this.state.aspectheight));
    var diagonal = Number(val);
    var diagonalMin = this.state.selectedLenses[0].diagonal.min;
    var diagonalMax = this.state.selectedLenses[0].diagonal.max;

    $('#screenWidth').val(this.myRound(width));
    $('#screenHeight').val(this.myRound(height));
    $('#screenDiagonal').val(this.myRound(diagonal));
    this.setState(
      {
        width:width,
        height:height,
        diagonal:diagonal
      },
      function() {
        if (this.state.units !== "ft") {
          if (this.state.units === "mm" || this.state.units === "in") {
            $('#manualDiagonal').val(this.myRoundMM( Number(val) * this.state.unitsMulti));
          } else {
            $('#manualDiagonal').val(this.myRound( Number(val) * this.state.unitsMulti));
          }
        } else {
          var feetInchArray = this.getFeetAndInches(Number(val) * this.state.unitsMulti);
          $('#manualDiagonal').val(feetInchArray[0]);
          $('#manualDiagonalInInput').val(feetInchArray[1]);
        }

        if (this.state.units !== "ft") {
          if (this.state.units === "mm" || this.state.units === "in") {
            $('#manualHeight').val(this.myRoundMM(height * this.state.unitsMulti));
            $('#manualWidth').val(this.myRoundMM(width * this.state.unitsMulti));
          } else {
            $('#manualHeight').val(this.myRound(height * this.state.unitsMulti));
            $('#manualWidth').val(this.myRound(width * this.state.unitsMulti));
          }

        } else {
          var feetInchArrayHeight = this.getFeetAndInches(height * this.state.unitsMulti);
          var feetInchArrayWidth = this.getFeetAndInches(width * this.state.unitsMulti);
          $('#manualHeight').val(feetInchArrayHeight[0]);
          $('#manualHeightInInput').val(feetInchArrayHeight[1]);
          $('#manualWidth').val(feetInchArrayWidth[0]);
          $('#manualWidthInInput').val(feetInchArrayWidth[1]);
        }

        if (diagonal >= diagonalMin && diagonal <= diagonalMax) {
          if (this.state.blended)
            this.changeOverallWidth(this.state.overallWidth);

          // set min/max distance
          if (this.state.calculateThrow) {
            this.setMinMax();
          } else {
            this.drawSideProjector();
          }
        }
        this.setDiagonalAlert(val);
        //this.drawSideProjector();        
      }
    );
  }

  setDiagonalAlert(val) {
    var uom = this.state.unitsMulti;

    //if (this.state.selectedLenses.length > 0 && $(".lenses .lens.selected").length > 0) {
    var diagMin;
    var diagMax; 
    var diagonalCurrent = this.state.diagonal * uom;
    var allLens = $('.lens');
    for(var x = 0; x < allLens.length; x++) {
      var lens = allLens[x];

      if (lens.diagonal !== undefined) {
        var lensMin = Number(lens.diagonal.min);
        var lensMax = Number(lens.diagonal.max);

        if (diagMin == null || lensMin < diagMin)
          diagMin = lensMin;

        if (diagMax == null || lensMax < diagMax)
          diagMax = lensMax;
      }
    }

    if (this.state.units !== "ft") {
      if (val !== $("#manualDiagonal").val()) {
        if (val) {
          diagonalCurrent = Number(val) * uom;
        }
      } else {
        diagonalCurrent = Number(val);
      }
    }
    if ((diagonalCurrent < diagMin) || (diagonalCurrent > diagMax)) {
      $("#diagonal-alert").addClass("alert");
      $("#diagonal-alert").removeClass("hide-me");
      $("#diagonalContainer").addClass("active-alert");
      $("#heightContainer").addClass("active-alert");
      $("#widthContainer").addClass("active-alert");
      var alertMessage = "Enter a value for screen diagonal between " + this.myRound(diagMin) + " - " +  + this.myRound(diagMax) + " " + this.state.units;

      if (this.state.units === "ft")
        alertMessage = "Enter a value for screen diagonal between<br/>" + this.feetAndInches(diagMin) + " - " +  this.feetAndInches(diagMax);

      $('#diagonal-alert span').html(alertMessage);
    } else {
      $("#diagonal-alert").removeClass("alert");
      $("#diagonal-alert").addClass("hide-me");
      $("#diagonalContainer").removeClass("active-alert");
      $("#heightContainer").removeClass("active-alert");
      $("#widthContainer").removeClass("active-alert");
    }
    for (var i = 0; i < this.state.lenses.length; i++) {
      if (this.state.lenses[i].type === "UST" && (this.state.lenses[i].zoomtype === "optional" || this.state.lenses[i].zoomtype === "fixed" )) {
        var index = i;
        var ustLens = this.state.lenses[i];
        var ustMin = this.state.lenses[i].diagonal.min * this.state.unitsMulti;
        var ustMax = this.state.lenses[i].diagonal.max * this.state.unitsMulti;
        ustMin = this.myRound(ustMin);
        if (diagonalCurrent >= ustMin && diagonalCurrent <= ustMax) {
          $(".lenses [data-index=" + index + "]").removeClass("out-of-range");
          $(".lenses [data-index=" + index + "]").addClass("in-range");
        } else if ($(".lenses [data-index=" + index + "]").hasClass("in-range")) {
          $(".lenses [data-index=" + index + "]").addClass("out-of-range");
          $(".lenses [data-index=" + index + "]").removeClass("in-range");
        }
      }
    }
  }


  setMinMax() {
    var distance = this.state.distance;
    var val2 = this.state.width;

    if (this.state.orientation === "v")
      val2 = this.state.height;

    if (val2 === undefined)
      val2 = 0;

    var minDistance;
    var maxDistance;
    var lens = this.state.selectedLenses[0];
    var minLens = this.state.lenses[0];
    var maxLens = this.state.lenses[this.state.lenses.length-1];
    
    var globalMin;
    var globalMax;
    var minFormula;
    var maxFormula;
    var slope;
    var intercept;
    var slope2;
    var intercept2;
    var newMinLens;
    var newMaxLens;
    if(Array.isArray(minLens.formula)) {
      minFormula = minLens.formula.find(x => x.type === "min" || x.type === "fixed");
    } else {
      minFormula = minLens.formula;
    }
    if (minFormula === undefined) {
      minFormula = minLens.formula;
    }

    if (minFormula !== undefined) {
      
      slope = minFormula.slope;
      intercept = Number(minFormula.intercept);

      globalMin = this.calculateDistance(slope, val2, intercept);
    } else {
      newMinLens = this.state.lenses[1];
      slope = newMinLens.formula.slope;
      intercept = newMinLens.formula.intercept;

      globalMin = this.calculateDistance(slope, val2, intercept);
    }
    if(Array.isArray(maxLens.formula)) {
      maxFormula = maxLens.formula.find(x => x.type === "max" || x.type === "fixed");
    } else {
      if (maxLens.formula === undefined && maxLens.type === "UST") {
        maxFormula = this.state.lenses[this.state.lenses.length-1].formula;
      } else {
        maxFormula = maxLens.formula;
      }
    }

    if (maxFormula !== undefined) {
      slope2 = maxFormula.slope;
      intercept2 = Number(maxFormula.intercept);
      globalMax = this.calculateDistance(slope2, val2, intercept2);
    } else {
      newMaxLens = this.state.lenses[this.state.lenses.length-2];
      var newMaxFormula = newMaxLens.formula.find(x => x.type === "max" || x.type === "fixed");
      slope2 = newMaxFormula.slope;
      intercept2 = newMaxFormula.intercept;
      globalMax = this.calculateDistance(slope2, val2, intercept2);
    }

    var selectedLens = $(".lens.selected");
    var ustValues = $(".ust-values");
    if (selectedLens.length > 0) {
      lens = this.state.lenses[selectedLens.data("index")];
      maxLens = lens;
      minLens = lens;
    } else {
      minLens = this.state.lenses[0];
      lens = minLens;
      maxLens = this.state.lenses[this.state.lenses.length -1];
    }
    if(Array.isArray(minLens.formula)) {
      minFormula = minLens.formula.find(x => x.type === "min" || x.type === "fixed");
    } else {
      minFormula = minLens.formula;
    }
    if (minFormula === undefined) {
      minFormula = minLens.formula;
    }
    if (minFormula !== undefined) {
      slope = minFormula.slope
      intercept = Number(minFormula.intercept);
      minDistance = this.calculateDistance(slope, val2, intercept);
      if(distance < minDistance) {
        distance = minDistance;
      }
    } else if (minDistance === undefined && minLens.formula !== undefined) {
      slope = minLens.formula.slope;
      intercept = minLens.formula.intercept;
      minDistance = this.calculateDistance(slope, val2, intercept);
      if (distance < minDistance) {
        distance = minDistance;
      } 
    } else {
      newMinLens = this.state.lenses[1];
      slope = newMinLens.formula.slope;
      intercept = newMinLens.formula.intercept;
      minDistance = this.calculateDistance(slope, val2, intercept);
      if (distance < minDistance) {
        distance = minDistance;
      }
    }

    if(Array.isArray(maxLens.formula)) {
      maxFormula = maxLens.formula.find(x => x.type === "max" || x.type === "fixed");
    } else if (maxLens.formula !== undefined) {
      maxFormula = maxLens.formula;
    } else {
      newMaxLens = this.state.lenses[1];
      maxFormula = newMaxLens.formula;
    }
    if (maxFormula !== undefined) {
      slope2 = maxFormula.slope;
      intercept2 = Number(maxFormula.intercept);
      maxDistance = this.calculateDistance(slope2, val2, intercept2);
      if(distance > maxDistance) {
        distance = maxDistance;
      }
    }
    this.setState(
      {
        minDistance: minDistance,
        distance: distance, 
        maxDistance: maxDistance,
        globalMinDistance: globalMin,
        globalMaxDistance: globalMax
      },
      function() {
        this.setDistanceAlert(distance);
        if (this.state.units === "ft") {
          var feetInchArray = this.getFeetAndInches(this.state.distance * this.state.unitsMulti);
          $('#throwDistance').val(this.myRound(distance));
          // $('#throwDistanceInput').val(Math.floor(distance * this.state.unitsMulti));
          $('#throwDistanceInput').val(feetInchArray[0]);
          $('#throwDistanceInInput').val(feetInchArray[1]);
        } else if (this.state.units === "mm" || this.state.units === "in") {     
          $('#throwDistance').val(this.myRoundMM(distance));
          $('#throwDistanceInput').val(this.myRoundMM(distance * this.state.unitsMulti));
        } else {     
          $('#throwDistance').val(this.myRound(distance));
          $('#throwDistanceInput').val(this.myRound(distance * this.state.unitsMulti));
        }

        if (this.state.blended)
          this.changeOverallWidth(this.state.overallWidth)

        this.toggleUSTvalues();

        this.drawSideProjector();

      }
    );
  }

  setDistanceAlert(val, inchChange = false) {
    var minDistance = this.state.minDistance * this.state.unitsMulti;
    var maxDistance = Math.ceil(this.state.globalMaxDistance * this.state.unitsMulti);
    if (this.state.units !== "ft") {
      val = val * this.state.unitsMulti;
    }
    if (minDistance === undefined || isNaN(minDistance)) {
      minDistance = Math.ceil(this.state.globalMinDistance * this.state.unitsMulti);
    }
    if (this.state.units === "mm" || this.state.units === "in") {
      var valRounded = this.myRoundMM(val);
      $('#throwDistanceInput').val(valRounded);
    }
    if (this.state.units === "ft" && inchChange === false) {
      val = val * this.state.unitsMulti;
    }
    
    if (val < minDistance || val > maxDistance) {
      $("#distance-alert").addClass("alert");
      $("#distance-alert").removeClass("hide-me");
      $("#throwDistanceWrapper").addClass("active-alert");
      var alertMessage = "Enter a value for throw distance between " + this.myRound(minDistance) + " - " +  + this.myRound(this.state.globalMaxDistance * this.state.unitsMulti) + " " + this.state.units;

      if (this.state.units === "mm" || this.state.units === "in") {
        alertMessage = "Enter a value for throw distance between " + this.myRoundMM(minDistance) + " - " +  + this.myRoundMM(this.state.globalMaxDistance * this.state.unitsMulti) + " " + this.state.units;
      }

      if (this.state.units === "ft")
        alertMessage = "Enter a value for throw distance between " + this.feetAndInches(minDistance) + " - " +  this.feetAndInches(this.state.globalMaxDistance * this.state.unitsMulti)

      $('#distance-alert span').html(alertMessage);
    } else {
      $("#distance-alert").removeClass("alert");
      $("#distance-alert").addClass("hide-me");
      $("#throwDistanceWrapper").removeClass("active-alert");
    }
  }

  manualChangeDistance(e) {
    if(e.target.value.charAt(e.target.value.length - 1) === ".")
      return;
    if ((e.target.value.charAt(e.target.value.length - 2) === ".") && (e.target.value.charAt(e.target.value.length - 1) === "0")) {
      return;
    }
    var normalizedDistance = Number(e.target.value);
    if (this.state.units !== 'ft') {
      this.changeDistance(normalizedDistance / this.state.unitsMulti, false);
    } else {
      var normalizedInches = ((parseFloat(normalizedDistance) + (parseFloat(Number($('#throwDistanceInInput').val()) / 12)))  / this.state.unitsMulti);
      this.changeDistance(normalizedInches, false);
    }
  }

  manualChangeDistanceInches(e) {
    if(e.target.value.charAt(e.target.value.length - 1) === ".") {
      return;
    }
    var inches = Number(e.target.value);
    var ftInches = Number($('#throwDistanceInput').val());
    var ftWInch = (ftInches + (inches / 12)) / this.state.unitsMulti;
    this.changeDistance(ftWInch, false);
  }

  changeDistance(val, setInput = false) {
    var distance = Number(val);
    var distanceNoUnitsMulti = this.myRound(Number(val));
    var feetInchArrayDistance;
    var minDistance = this.state.globalMinDistance * this.state.unitsMulti;
    var maxDistance = this.state.globalMaxDistance * this.state.unitsMulti;
    if (setInput === true) {
      this.setDistanceAlert(distance, true);
    } else {
      this.setDistanceAlert(distance, false);
    }

    if (this.state.units !== "ft") {
      distance = this.myRound(Number(val) * this.state.unitsMulti);
    }
    if (this.state.units === "ft" && setInput === false) {
      distanceNoUnitsMulti = Number(val);
      distance = Number(val) * this.state.unitsMulti;
      $('#throwDistance').val(distance);
    } else if (this.state.units === "ft" && setInput === true) {
      feetInchArrayDistance = this.getFeetAndInches(distance);
      $('#throwDistanceInput').val(feetInchArrayDistance[0]);
      $('#throwDistanceInInput').val(feetInchArrayDistance[1]);
      distance = this.myRound(Number(val) * this.state.unitsMulti);
      $('#throwDistance').val(distance);
    }

    this.setState(
      {
        distance: distanceNoUnitsMulti
      },  
      function() {

        if(this.state.calculateThrow || setInput === true) {
          var lens = this.state.selectedLenses[0];
          if (this.state.units === "ft" ) {
            feetInchArrayDistance = this.getFeetAndInches(distance);
            $('#throwDistanceInput').val(feetInchArrayDistance[0]);
            $('#throwDistanceInInput').val(feetInchArrayDistance[1]);
          } else if (this.state.units === "mm" || this.state.units === "in") {      
            $('#throwDistanceInput').val(this.myRoundMM(distance));
          } else {      
            $('#throwDistanceInput').val(this.myRound(distance));
          }
        }


        if (this.state.calculateScreen) {
          var width = this.getWidthFromThrowDistance();
          var diagonal = this.getProjectedDiagonalFromWidth(width, Number(this.state.aspectwidth), Number(this.state.aspectheight));
          var height = (Number(this.state.aspectheight) * width) / (Number(this.state.aspectwidth) * 1.0);
          if (this.state.units === "ft") {
            var feetInchArrayHeight = this.getFeetAndInches(height  * this.state.unitsMulti);
            var feetInchArrayDiagonal = this.getFeetAndInches(diagonal * this.state.unitsMulti);
            var feetInchArrayWidth = this.getFeetAndInches(width * this.state.unitsMulti);
            feetInchArrayDistance = this.getFeetAndInches(distance);
            $('#manualHeight').val(feetInchArrayHeight[0]);
            $('#manualHeightInInput').val(feetInchArrayHeight[1]);
            $('#manualWidth').val(feetInchArrayWidth[0]);
            $('#manualWidthInInput').val(feetInchArrayWidth[1]);
            $('#manualDiagonal').val(feetInchArrayDiagonal[0]);
            $('#manualDiagonalInInput').val(feetInchArrayDiagonal[1]);
            $('#throwDistanceInput').val(feetInchArrayDistance[0]);
            $('#throwDistanceInInput').val(feetInchArrayDistance[1]);
          } else if (this.state.units === "mm" || this.state.units === "in") {
            $('#manualHeight').val(this.myRoundMM(height  * this.state.unitsMulti));
            $('#manualWidth').val(this.myRoundMM(width * this.state.unitsMulti));
            $('#manualDiagonal').val(this.myRoundMM(diagonal * this.state.unitsMulti));
            $('#throwDistanceInput').val(this.myRoundMM(distance * this.state.unitsMulti));
          } else {
            $('#manualHeight').val(this.myRound(height  * this.state.unitsMulti));
            $('#manualWidth').val(this.myRound(width * this.state.unitsMulti));
            $('#manualDiagonal').val(this.myRound(diagonal * this.state.unitsMulti));
            $('#throwDistanceInput').val(this.myRound(distance * this.state.unitsMulti));
          }
          this.setState({
            width:width, 
            height: height,
            diagonal: diagonal
          }, function () {
              this.setDiagonalAlert(diagonal);
              this.drawSideProjector();
          });
        } else {
          this.drawSideProjector();
        }
      }
    )
  }

  changeVert(val) {
    this.setState(
      {vertOff:Number(val), horizOff:0},
      this.drawSideProjector
    );
  }

  changeHoriz(val) {
    this.setState(
      {horizOff:Number(val), vertOff:0},
      this.drawSideProjector      
    );
  }

  changeRatio(e) {
    if (e !== undefined) {
      var arr = e.target.value.split(':');
    } else {
      var arr = [this.state.aspectwidth, this.state.aspectheight];
    }
    if (this.state.orientation === "h") {
      this.setState({aspectwidth:Number(arr[0]),aspectheight:Number(arr[1])}, () => {
        this.postChangeRatio();
        this.postChangeUnits(true);
      });
    }
    else {
      this.setState({aspectwidth:Number(arr[1]),aspectheight:Number(arr[0])}, this.postChangeRatio, this.postChangeUnits(true));
    }
  }
  postChangeRatio() {
    this.changeWidth(this.state.width, false);
  }

  changeUnits(e) {
    this.applyChangeUnits(e.target.value, true);
  }

  applyChangeUnits(val, redraw = true) {
    if(val === "mm") {
      this.setState({units:'mm', unitsMulti: 10, unitsInterceptMulti: 25.4}, function() { this.postChangeUnits(redraw) });
    }
    if(val === "cm") {
      this.setState({units:'cm', unitsMulti: 1.0, unitsInterceptMulti: 2.54}, function() { this.postChangeUnits(redraw) });
    }
    if(val === "m") {
      this.setState({units:'m', unitsMulti: 0.01, unitsInterceptMulti: 0.0254}, function() { this.postChangeUnits(redraw) });
    }
    if(val === "ft") {
      this.setState({units:'ft', unitsMulti: 0.0328084, unitsInterceptMulti: 0.0833333}, function() { this.postChangeUnits(redraw) });
    }
    if(val === "in") {
      this.setState({units:'in', unitsMulti: 0.3937008, unitsInterceptMulti: 1.0}, function() { this.postChangeUnits(redraw) });
    }
  }

  postChangeUnits(redraw) {
    if (this.state.units !== "ft") {
      if (this.state.units === "mm" || this.state.units === "in") {
        $('#manualHeight').val(this.myRoundMM(this.state.height * this.state.unitsMulti));
        $('#manualWidth').val(this.myRoundMM(this.state.width * this.state.unitsMulti));
        $('#manualDiagonal').val(this.myRoundMM(this.state.diagonal * this.state.unitsMulti));
      } else {
        $('#manualHeight').val(this.myRound(this.state.height * this.state.unitsMulti));
        $('#manualWidth').val(this.myRound(this.state.width * this.state.unitsMulti));
        $('#manualDiagonal').val(this.myRound(this.state.diagonal * this.state.unitsMulti));
      }
    } else {
      var feetInchArrayHeight = this.getFeetAndInches(this.state.height * this.state.unitsMulti);
      var feetInchArrayWidth = this.getFeetAndInches(this.state.width * this.state.unitsMulti);
      var feetInchArrayDiagonal = this.getFeetAndInches(this.state.diagonal * this.state.unitsMulti);
      $('#manualHeight').val(feetInchArrayHeight[0]);
      $('#manualHeightInInput').val(feetInchArrayHeight[1]);
      $('#manualWidth').val(feetInchArrayWidth[0]);
      $('#manualWidthInInput').val(feetInchArrayWidth[1]);
      $('#manualDiagonal').val(feetInchArrayDiagonal[0]);
      $('#manualDiagonalInInput').val(feetInchArrayDiagonal[1]);
    }

    this.setDistanceAlert(this.state.distance);
    this.setDiagonalAlert($('#manualDiagonal').val());

    if (this.state.units === "ft") {
      var feetInchArray = this.getFeetAndInches(this.state.distance * this.state.unitsMulti);
      $('#throwDistance').val(Math.floor(this.state.distance));
      $('#throwDistanceInput').val(feetInchArray[0]);
      $('#throwDistanceInInput').val(feetInchArray[1]);
    } else if (this.state.units === "mm" || this.state.units === "in") {      
      $('#throwDistance').val(this.myRoundMM(this.state.distance));
      $('#throwDistanceInput').val(this.myRoundMM(this.state.distance * this.state.unitsMulti));
    } else {      
      $('#throwDistance').val(this.myRound(this.state.distance));
      $('#throwDistanceInput').val(this.myRound(this.state.distance * this.state.unitsMulti));
    }
    if (redraw) {
      this.drawSideProjector();
    }
  }

  changeOrientation(e) {
    var width = this.state.width;
    var height = this.state.height;
    var awidth = this.state.aspectwidth;
    var aheight = this.state.aspectheight;
    if (e !== undefined) {
      var orientation = e.target.value === "vert" ? "v" : "h";
    } else {
      var orientation = this.state.orientation;
    }

    this.setState({width:height, height:width, aspectheight:awidth, aspectwidth:aheight}, this.postChangeRatio);
    $('input[name=ori]').prop("checked", false);
    if (e.target.value === "hooriz") {
      e.target.value = "horiz";
    }
    $('#' + e.target.value).prop("checked", true);
    this.setState({ orientation: orientation }, () => {
        this.drawSideProjector();
    });
    // if(e.target.value === 'vert') {
    //   //$('#canScreen').addClass('rotate90')
    // } else {
    //   //$('#canScreen').removeClass('rotate90')
    // }
  }

  setBlended() {
    this.setState(
      {
        blended:true
      }, 
      function () {
        this.gtaEvent('Toggle Mode', "blended");
        $('#blended-projector').removeClass('hide-me');
        $('#blended-projector-notification').removeClass('hide-me');
        $('#single-projector').addClass('hide-me');
        $('#single-projector-notification').addClass('hide-me');
        $('#heightContainer').addClass('hide-me');
        $('#diagonalContainer').addClass('hide-me');
        $('#ratioContainer').addClass('hide-me');
        $('#oriContainer').addClass('hide-me');
        if($("#notification-blended-quality").hasClass("closed")) {
        } else {
          $('#notification-blended-quality').removeClass('hide-me');
        }
        if($('#notification-blended-guidance').hasClass('closed')) {
        } else {
          $('#notification-blended-guidance').removeClass('hide-me');
        }

        if (this.state.projector.mystique === true) {
          if($("#notification-mystique").hasClass('closed')) {
          } else {
            $("#notification-mystique").removeClass('hide-me');
          }
        } else {
          $("#notification-mystique").addClass('hide-me');
        }
      }
    );
  }

  setSingle() {
    this.setState(
      {
        blended:false
      }, function() {
        this.gtaEvent('Toggle Mode', "single");
      }
    );
  }

  changeOverallWidth(val) {
    var x = this.state.noProjectors;
    var b = this.state.blendWidth;
    var o = this.state.overallWidth;
    var width = this.state.width;

    if (!isNaN(val)) {
      o = Number(val);
    }

    if (this.state.calculateNoOfProjectors) {
      //Number of projectors: X = (O-B) / (W-B)
      x = Math.ceil((o-b)/(width - b));

      if (x > 3)
        x = 3;
      if (x < 2)
        x = 2;

      this.setState({noProjectors: x});
    } else if (this.state.calculateBlendOverlap) {
      //Blend Width: B = -(O-X*W) / (X-1) . – can default to 20% of O
      b = -(o-x*width) / (x-1);
      this.setState({blendWidth: b});
    } else if (this.state.calculateBlendedWidth) {
      //Overall Image Width: O=(X*W)-B*(X-1)
      o = (x*width)-(b*(x-1));
    }

    this.setState({overallWidth: o});

    //Width of Individual Projector: W =(O + B*(X-1))/X . ---dont think we will ever do this


    if (o / 10 >= b) {
      $("#blendWidthalert").addClass("alert");
      $("#blendWidthalert").removeClass("hide-me");
      $("#blendwidthContainer").addClass("active-alert");
    } else {
      $("#blendWidthalert").removeClass("alert");
      $("#blendWidthalert").addClass("hide-me");
      $("#blendwidthContainer").removeClass("active-alert");
    }
    this.drawBlend();
  }

  changeBlendWidth(val) {
    this.setState({blendWidth:val},this.changeOverallWidth);
  }

  changeNoProjectors(val) {
    this.setState({noProjectors:val}, this.changeOverallWidth);
  }

  calculateBlendedWidthChange(e) {
    this.setState({calculateBlendedWidth: true, calculateBlendOverlap: false, calculateNoOfProjectors: false}, this.changeOverallWidth);
  }

  calculateBlendOverlapChange(e) {
    this.setState({calculateBlendedWidth: false, calculateBlendOverlap: true, calculateNoOfProjectors: false}, this.changeOverallWidth);
  }

  calculateNoOfProjectorsChange(e) {
    this.setState({calculateBlendedWidth: false, calculateBlendOverlap: false, calculateNoOfProjectors: true}, this.changeOverallWidth);
  }

  toggleMode(e) {
    if(e.target.value === 'blended' ) {
      this.setBlended();
      $('#blended_selected').prop("checked", true);
      $('#single_selected').prop("checked", false);
      $('#single_selected').removeClass("initial");
    } else if (e.target.value === 'single') {
      this.setSingle();
      $('#blended_selected').prop("checked", false);
      $('#single_selected').prop("checked", true);
      $('#single_selected').removeClass("initial");
      $('#single-projector').removeClass('hide-me');
      $('#single-projector-notification').removeClass('hide-me');
      $('#blended-projector').addClass('hide-me');
      $('#blended-projector-notification').addClass('hide-me');
      $('#heightContainer').removeClass('hide-me');
      $('#diagonalContainer').removeClass('hide-me');
      $('#ratioContainer').removeClass('hide-me');
      $('#oriContainer').removeClass('hide-me');
      $('#notification-blended-quality').addClass('hide-me');
      $('#notification-blended-guidance').addClass('hide-me');
      $("#notification-mystique").addClass('hide-me');

    } else if (e.target.value === 'reset') {
       this.reset();

    } else if (e.target.value === 'help') {
      this.openModal3();
    }
  }

  toggleModeOld(e) {
    if(e.target.value === 'advanced' ) {
      this.setState({advanced:true});
      $('#gain').removeClass('hide-me');
      $('#ambientlight').removeClass('hide-me');
      $('#stack').removeClass('hide-me');
    } else {
      this.setState({advanced:false});
      $('#gain').addClass('hide-me');
      $('#ambientlight').addClass('hide-me');
      $('#stack').addClass('hide-me');
    }
  }

  reset(e) {
    // find the distance
    var projector = this.state.projector;

    var arr = projector.lenses.lens;

    var lenses = $(".lens");
    lenses.sort(function(a, b){
        return $(a).data("index")-$(b).data("index")
    });

    $(".lenses").html(lenses);

    
    var arr2;
    if(arr.constructor === Array) {
      arr2 = arr;
      for(var x=0;x<arr.length;x++) {
        arr[x].selected = "false";
      }
    } else {
      arr2 = [arr];
      arr.selected = "false";
    }

    var minDiagonal;
    var maxDigonal;
    for(var i = 0; i < arr2.length; i++) {
      var lens = arr2[i];

      if (lens.diagonal !== undefined) {
        var lensMin = Number(lens.diagonal.min);
        var lensMax = Number(lens.diagonal.max);

        if (minDiagonal == null || lensMin < minDiagonal)
          minDiagonal = lensMin;

        if (maxDigonal == null || lensMax < maxDigonal)
          maxDigonal = lensMax;
      }

    }

    var diagonal = this.state.diagonal;
    if (diagonal < minDiagonal)
      diagonal = minDiagonal;
    if (diagonal > maxDigonal)
      diagonal = maxDigonal;

    var minWidth = this.getProjectedImageWidthFromDiagonal(minDiagonal, Number(this.state.aspectwidth), Number(this.state.aspectheight));
    var maxWidth = this.getProjectedImageWidthFromDiagonal(maxDigonal, Number(this.state.aspectwidth), Number(this.state.aspectheight));

    var width = this.state.width;
    if (width < minWidth)
      width = minWidth;
    if (width > maxWidth)
      width = maxWidth;

    var height = (Number(projector.aspectratio.height) * width)  / (Number(projector.aspectratio.width) * 1.0);
    var minHeight = this.getProjectedImageHeightFromDiagonal(minDiagonal, Number(this.state.aspectwidth), Number(this.state.aspectheight));
    var maxHeight = this.getProjectedImageHeightFromDiagonal(maxDigonal, Number(this.state.aspectwidth), Number(this.state.aspectheight));

    if (height < minHeight)
      height = minHeight;
    if (height > maxHeight)
      height = maxHeight;

    $('#' + projector.aspectratio.width + projector.aspectratio.height).prop("checked", true);
    $('#horiz').prop("checked", true);

    var selectLensButtons = $(".select-lens-button");
    selectLensButtons.html("Select");
    selectLensButtons.removeClass("lens-selected");
    this.selectLens(this.state.lenses[0],this.state.lenses[0],0, true);

    var _initState = this.getInitialState();
    _initState.units = this.state.units; 
    _initState.unitsMulti = this.state.unitsMulti; 

    this.setState(
      _initState, 
      function() {
        this.setState(
          {
            aspectwidth: projector.aspectratio.width,
            aspectheight: projector.aspectratio.height,            
            width: width,
            minWidth: minWidth,
            maxWidth: maxWidth,
            height: height,
            minHeight: minHeight,
            maxHeight: maxHeight,
            diagonal: diagonal,
            minDiag: minDiagonal,
            maxDiag: maxDigonal,
          }, 
          function() {            
            if (this.state.units === "mm" || this.state.units === "in") {
              $('#manualHeight').val(this.myRoundMM(this.state.height  * this.state.unitsMulti));
              $('#manualWidth').val(this.myRoundMM(this.state.width * this.state.unitsMulti));
              $('#manualDiagonal').val(this.myRoundMM(this.state.diagonal * this.state.unitsMulti));
            } else {
              $('#manualHeight').val(this.myRound(this.state.height  * this.state.unitsMulti));
              $('#manualWidth').val(this.myRound(this.state.width * this.state.unitsMulti));
              $('#manualDiagonal').val(this.myRound(this.state.diagonal * this.state.unitsMulti));
            }
            this.setMinMax();

            var selectLensButtons = $(".select-lens-button");
            selectLensButtons.html("Select");
            selectLensButtons.removeClass("lens-selected");
            this.drawSideProjector();


            if (this.state.lenses.length > 1) {

              $('#offset-canvas-container').addClass('hide-me');
              $('#offset-canvas-message').removeClass('hide-me');
            } else if (this.state.lenses.length === 1) {
              $("#throwDistanceCard").removeClass("hide-me");
              $('#offset-canvas-container').removeClass('hide-me');
              $('#offset-canvas-message').addClass('hide-me');
              $("#single-projector").removeClass("hide-me");
            }
          }
        )
      }
    )
  }

  calculateDistance(slope, val, intercept) {
    return (Number(slope) * Number(val)) + Number(intercept);
  }

  numberWithCommas(x) {
    if(!x)
      return null;
    else
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  projectorSearch(e){
    var searchTerm = e.target.value.trim();
    this.setState({searchValue: e.target.value});
    this.doSearch(searchTerm, this.state.searchResolution, this.state.searchDiscontinued, this.state.searchStarred, this.state.searchSize, this.state.searchDistance, this.state.searchLux, this.state.searchSeries, this.state.searchIllumination);
  }

  seriesSearch(e){
    var seriesVal = e.target.value;

    this.setState({
      searchSeries: seriesVal,
      searchResolution: '',
      searchLux: '',
      searchIllumination: ''
    }, function() {
      this.doSearch(this.state.searchValue, this.state.searchResolution, this.state.searchDiscontinued, this.state.searchStarred, this.state.searchSize, this.state.searchDistance, this.state.searchLux, seriesVal, this.state.searchIllumination);
    });
  }

  resolutionSearch(e){
    this.setState({searchResolution: e.target.value});
    this.doSearch(this.state.searchValue, e.target.value, this.state.searchDiscontinued, this.state.searchStarred, this.state.searchSize, this.state.searchDistance, this.state.searchLux, this.state.searchSeries, this.state.searchIllumination);
  }
  sizeSearch(e){
    this.setState({searchSize: e.target.value});
    this.doSearch(this.state.searchValue, this.state.searchResolution, this.state.searchDiscontinued, this.state.searchStarred, e.target.value, this.state.searchDistance, this.state.searchLux, this.state.searchSeries, this.state.searchIllumination);
  }
  distanceSearch(e){
    this.setState({searchDistance: e.target.value});
    this.doSearch(this.state.searchValue, this.state.searchResolution, this.state.searchDiscontinued, this.state.searchStarred, this.state.searchSize, e.target.value, this.state.searchLux, this.state.searchSeries, this.state.searchIllumination);
  }
  luxSearch(e){
    this.setState({searchLux: e.target.value});
    this.doSearch(this.state.searchValue, this.state.searchResolution, this.state.searchDiscontinued, this.state.searchStarred, this.state.searchSize, this.state.searchDistance, e.target.value, this.state.searchSeries, this.state.searchIllumination);
  }

  illuminationSearch(e){
    this.setState({searchIllumination: e.target.value});
    this.doSearch(this.state.searchValue, this.state.searchResolution, this.state.searchDiscontinued, this.state.searchStarred, this.state.searchSize, this.state.searchDistance,  this.state.searchLux, this.state.searchSeries, e.target.value);
  }

  discontinuedSearch(checked){
    var seriesSelect = this.state.serieses;
    if (checked === true) {
      seriesSelect = this.state.seriesesDiscontinued;
    }

    var selectedSeries = $("#seriesSelect option:selected").val();

    if (seriesSelect.indexOf(selectedSeries) === -1 && selectedSeries !== "")
      seriesSelect.push(selectedSeries);

    seriesSelect.sort();

    this.setState(
      {
        searchDiscontinued: checked,
        seriesSelect: seriesSelect
      }, 
      function() {
        var searchTerm = this.state.searchValue.trim();      
        this.doSearch(searchTerm, this.state.searchResolution, checked, this.state.searchStarred, this.state.searchSize, this.state.searchDistance, this.state.searchLux, this.state.searchSeries, this.state.searchIllumination);
      }
    );
  }

  starredSearch(checked){
    this.setState(
      {
        searchStarred: checked
      },
      function() {
        this.doSearch(this.state.searchValue, this.state.searchResolution, this.state.searchDiscontinued, checked, this.state.searchSize, this.state.searchDistance, this.state.searchLux, this.state.searchSeries, this.state.searchIllumination);
      }
    );
  }

  arrayUnique(array) {
    var a = array.concat();
    for(var i=0; i<a.length; ++i) {
        for(var j=i+1; j<a.length; ++j) {
            if(a[i] === a[j])
                a.splice(j--, 1);
        }
    }

    return a;
  }

  doSearch(searchValue, searchResolution, searchDiscontinued, searchStarred, searchSize, searchDistance, searchLux, searchSeries, searchIllumination) {
    var localProjectors = this.state.projectorsOrig;
    var localProjector;
    var resolution;

    if(searchValue !== '') {
      localProjectors = localProjectors.filter(d => ((d.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1) || (d.partno && d.partno.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1)  )  );
    }

    if(searchStarred) {
      localProjectors = localProjectors.filter(d => d.starred === true);
    }
    if(!searchDiscontinued) {
      if(searchStarred) {
       localProjectors = localProjectors.filter(d => (d.discontinued === "false") || (d.starred === true) );
      } else {
       localProjectors = localProjectors.filter(d => (d.discontinued === "false"));
      }
    } else {
      if(searchStarred) {
       localProjectors = localProjectors.filter(d => (d.discontinued === "true") || (d.starred === true) );
      } else {
       localProjectors = localProjectors.filter(d => (d.discontinued === "true"));
      }
    }

    var lumensOptions = ["0-4,999","5,000-9,999","10,000-14,999","15,000-19,999","20,000-29,999","30,000-39,999","40,000+"];
    var lumensOutputs = lumensOptions;

    var resolutions = this.state.resolutions;

    var illuminations = [];
    if(searchSeries !== '') {

      localProjectors = localProjectors.filter( (d => d.series.indexOf(searchSeries) !== -1)); 
      localProjectors.sort(function(a, b){
        return $(b)[0].lumensoutput -$(a)[0].lumensoutput;
      });

      lumensOutputs = [];

      for (var i = 0; i < lumensOptions.length; i++) {
        var lumens = lumensOptions[i];

        var lumensMatches = [];
        if(lumens === "0-4,999") {
          lumensMatches = localProjectors.filter(d => Number(d.lumensoutput) <= 4999); 
        }
        if(lumens === "5,000-9,999") {
          lumensMatches = localProjectors.filter(d => Number(d.lumensoutput) <= 9999 && Number(d.lumensoutput) > 4999); 
        }
        if(lumens === "10,000-14,999") {
          lumensMatches = localProjectors.filter(d => Number(d.lumensoutput) <= 14999 && Number(d.lumensoutput) > 9999); 
        }
        if(lumens === "15,000-19,999") {
          lumensMatches = localProjectors.filter(d => Number(d.lumensoutput) <= 19999 && Number(d.lumensoutput) > 14999); 
        }
        if(lumens === "20,000-29,999") {
          lumensMatches = localProjectors.filter(d => Number(d.lumensoutput) <= 29999 && Number(d.lumensoutput) > 19999); 
        }
        if(lumens === "30,000-39,999") {
          lumensMatches = localProjectors.filter(d => Number(d.lumensoutput) <= 39999 && Number(d.lumensoutput) > 29999); 
        }
        if(lumens === "40,000+") {
          lumensMatches = localProjectors.filter(d => Number(d.lumensoutput) >= 40000); 
        }

        if (lumensMatches.length > 0) {
          lumensOutputs.push(lumens);
        }
      }

      resolutions = [];
      illuminations = [];
      for(var x = 0; x < localProjectors.length; x++) {
        localProjector = localProjectors[x];

        resolution = localProjector.resolution;
        if(resolutions.indexOf(resolution) === -1 && resolution) {
          resolutions.push(resolution);
        }

        var illumination = this.sentenceCase(localProjector.illumination);
        if (illumination !== undefined) {
          if(illuminations.indexOf(illumination) === -1 && illumination) {
            illuminations.push(illumination);
          }
        }
      }

    } else {
      lumensOutputs = lumensOptions;
      illuminations = this.state.illuminationsOrig;

      for(var y = 0; y < localProjectors.length; y++) {
        localProjector = localProjectors[y];

        resolution = localProjector.resolution;
        if(resolutions.indexOf(resolution) === -1 && resolution) {
          resolutions.push(resolution);
        }
      }

    }

    if(searchResolution !== '') {
      localProjectors = localProjectors.filter(d => d.resolution.indexOf(searchResolution) !== -1); 
    }
    if(searchSize !== '') {
      localProjectors = localProjectors.filter(d => d.screensize.indexOf(searchSize) !== -1); 
    }
    if(searchDistance !== '') {
      localProjectors = localProjectors.filter(d => d.distancetoscreen.indexOf(searchDistance) !== -1); 
    }
    if(searchLux !== '') {

      if(searchLux === "0-4,999") {
        localProjectors = localProjectors.filter(d => Number(d.lumensoutput) <= 4999); 
      }
      if(searchLux === "5,000-9,999") {
        localProjectors = localProjectors.filter(d => Number(d.lumensoutput) <= 9999 && Number(d.lumensoutput) > 4999); 
      }
      if(searchLux === "10,000-14,999") {
        localProjectors = localProjectors.filter(d => Number(d.lumensoutput) <= 14999 && Number(d.lumensoutput) > 9999); 
      }
      if(searchLux === "15,000-19,999") {
        localProjectors = localProjectors.filter(d => Number(d.lumensoutput) <= 19999 && Number(d.lumensoutput) > 14999); 
      }
      if(searchLux === "20,000-29,999") {
        localProjectors = localProjectors.filter(d => Number(d.lumensoutput) <= 29999 && Number(d.lumensoutput) > 19999); 
      }
      if(searchLux === "30,000-39,999") {
        localProjectors = localProjectors.filter(d => Number(d.lumensoutput) <= 39999 && Number(d.lumensoutput) > 29999); 
      }
      if(searchLux === "40,000+") {
        localProjectors = localProjectors.filter(d => Number(d.lumensoutput) >= 40000); 
      }
    }

    if(searchIllumination !== '' && searchIllumination !== 'All') {
      searchIllumination = searchIllumination.toLowerCase();
      localProjectors = localProjectors.filter(d => (d.illumination !== undefined && (d.illumination.toLowerCase() === searchIllumination))); 
    }

    if (localProjectors.length > 0) {
      $("#no-projectors-found").hide()
    } else {
      $("#no-projectors-found").show()
    }

    this.setState({
        projectors: localProjectors,
        lumensoutputs: lumensOutputs,
        resolutions: resolutions,
        illuminations: illuminations
    })  
  }

  showShare() {
    var href = window.location.protocol + '//' + window.location.host + window.location.pathname + "?projector=" + encodeURIComponent(this.state.projector.name) + this.assembleURLComponents();
    window.open('mailto:?subject=Summary of results for '+ this.state.projector.name +'&body='+encodeURIComponent(href), '_self');
  }

  getFeetAndInches(decimal) {
    var feet = Math.floor(decimal);
    var decimalIn = 12 * (decimal - Math.floor(decimal))
    var inches = +(Math.round(decimalIn + "e+2")  + "e-2");
    inches = this.myRoundNoDecimals(inches);

    if (inches === 12) {
      inches = 0;
      feet++;
    }
    if (isNaN(inches)) {
      inches = 0;
    }
    return [feet, inches];
  }

  feetAndInches(decimal) {
    var ftAndIn = this.getFeetAndInches(decimal);
    return ftAndIn[0] +
      " ft " +
      ftAndIn[1] +
      ' in';
  }

  toggleUSTvalues(item) {
    var selectedLens = $(".lens.selected");
    var ustValues = $(".ust-values");
    var uom = this.state.unitsMulti;
    var diagonal = this.state.diagonal;
    var length = this.state.projector.length;
    var arr = [];
    if (selectedLens.length > 0) {
      var lens = this.state.lenses[selectedLens.data("index")];
      if (lens.outputs && ustValues.hasClass("hide-me")) {
        if (lens.zoomtype === "fixed" || lens.zoomtype === "optional") {
        }
      } else if (!lens.outputs || lens.zoomtype === "snorkel_left" || lens.zoomtype === "snorkel_right") {
        ustValues.addClass("hide-me"); 
      }
      if (lens.outputs) {
        for (var i = 0; i < lens.outputs.length; i++) {
          if (lens.outputs[i]) {
            var output = this.valueCalculator(lens.type, lens.zoomtype, lens.outputs[i], diagonal, length, uom);
            if (output != null) {
              if (Array.isArray(output)) {
                arr = $.merge(arr, output);
              } else {
                arr.push(output);
              }
            }
          }
        }
      }

      var ustHTML = "<h3>Values</h3><div class='form-wrapper'>";
      for (var x = 0; x < arr.length; x++) {
        var uomChecked = $('input[name="units"]:checked').val();
        var value = Number(arr[x].value);
        var valueCalc =  this.myRound(value * uom) + " " + uomChecked;
        if (uomChecked === "mm" || this.state.units === "in") {
          valueCalc =  this.myRoundMM(value * uom) + " " + uomChecked;
        }
        if (uomChecked === "ft")
          valueCalc = this.feetAndInches(value * uom)

        ustHTML +=  "<div class='label'>" + arr[x].name + "</div>" + 
                    "<div class='value'>" + 
                      "<span> " + valueCalc + "</span>" +
                    "</div>";

      }

      ustHTML += "<div class='clear'></div>";
      ustValues.html(ustHTML);      
    } else {
      ustValues.addClass("hide-me");
    }
  }


  valueCalculator(lensType, zoomType, output, diagonal, length, uom) {
    var inchMulti = 2.54;
    var inchDiv = 0.3937008;
    var meterMulti = 10; //convert from cm to m
    var value;
    
    if (lensType === "UST") {
      if (zoomType === "fixed") {
        if (output.name.startsWith("L")) {
          var ML = Number(output.ML);
          var BL = Number(output.BL);
          value = (((diagonal / inchMulti) * ML) - BL) / 10;
          var L1 = Number(length) + value;
          return [
            {
              "name": "L1",
              "value": L1
            },
            {
              "name": output.name,
              "value": value
            }
          ];
        }
        if (output.name.startsWith("A")) {
          var MA = Number(output.MA);
          var BA = Number(output.BA);
          value = (((diagonal / inchMulti) * MA) + BA) / 10;
          return {
            "name": output.name,
            "value": value
          }
        }
      } else if (zoomType === "optional") {
        var K1 = Number(output.K1); //in meters?
        var K2 = Number(output.K2); //in meters?
        //convert diagonal to inches
        var inchDiagonal = diagonal * inchDiv;
        //run calculation
        var meterValue = ((inchDiagonal * K1)  + (K2));
        //result is in meters (convert to cm) 
        value = meterValue * 100;

        return {
          "name": output.name,
          "value": value
        }
      }
    }
  }

  constructor(props) {
    super(props);
    var _state = this.getInitialState(); 
    _state = {
        ..._state,
        illuminations: [], 
        searchIllumination:'', 
        projector: {}, 
        lenses: [],
        searchValue: '', 
        selectedLenses: [],
        searchResolution: '', 
        searchDiscontinued: false, 
        searchStarred: false, 
        searchSize: '', 
        searchDistance: '', 
        searchLux: '', 
        projectors: [], 
        projectorsOrig: [], 
        serieses: [], 
        seriesesDiscontinued: [],
        seriesSelect: [],
        distancetoscreens: [], 
        searchSeries: '', 
        screensizes: [], 
        modalIsOpen: false, 
        modalIsOpen2: false, 
        modalIsOpen3: false,
        quoteURL: "", 
        iframeURL: "", 
        lumensoutputs: [], 
        resolutions: [], 
        setLens: false,
        starred: [], 
        aspectwidth: 0, 
        aspectheight: 0, 
        height: 0, 
        diagonal: 0,  
        distance: 0, 
        lensPreselect: "",
        projectorImages: {
          "top_snorkel_left": this.newImage("UST_Snorkel_3DLP_top.png"),
          "top_snorkel_right": this.newImage("UST_Snorkel_1DLP_top.png"),
          "standardTop": this.newImage("standardTop.png"),
          "side_snorkel": this.newImage("UST_Snorkel_3DLP_side.png"),
          "standardSide": this.newImage("standardSide.png"),
          "side_ust_new": this.newImage("UST-side-fixed-new.png"),
          "side_ust_new2x":this.newImage("UST-side-fixed-new@2x.png")
        },
        topProjectorImg: new Image(),
        sideProjectorImg: new Image()
      };

    this.state = _state;


    var theApp = this;
    this.openModal = this.openModal.bind(this);
    this.openModal2 = this.openModal2.bind(this);
    this.openModal3 = this.openModal3.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.closeModal2 = this.closeModal2.bind(this);
    this.closeModal3 = this.closeModal3.bind(this);
    this.export = this.export.bind(this);

    this.discontinuedSearch = this.discontinuedSearch.bind(this);
    this.starredSearch = this.starredSearch.bind(this);
  }

  getInitialState() {
    return { 
      overallWidth: 400, 
      blendWidth: 10, 
      noProjectors: 2, 
      width: 0, 
      advanced: false,
      setDistance: true,
      vertOff: 0, 
      horizOff: 0, 
      setHorizVert: true,
      units: 'mm', 
      unitsMulti: 10, 
      minHeight: 10, 
      maxHeight: 1500, 
      minDiag: 10, 
      maxDiag: 1700, 
      minWidth: 10, 
      maxWidth: 1500, 
      minDistance:0, 
      globalMinDistance:0, 
      maxDistance:8000, 
      globalMaxDistance:8000, 
      unitsInterceptMulti: 2.54,
      minHoriz: -10,
      maxHoriz: 10,
      minVert: -10,
      maxVert: 10,
      orientation: "h",
      minHorizDefault: -10,
      maxHorizDefault: 10,
      minVertDefault: -10,
      maxVertDefault: 10,
      calculateScreen: false,
      calculateThrow: true,
      calculateBlendedWidth: false,
      calculateBlendOverlap: false,
      calculateNoOfProjectors: true,
    };
  }

  openModal() {
    this.gtaEvent('Get a Quote', this.state.projector.name);
    this.setState(
      {
        modalIsOpen: true,
        modalIsOpen2: false,
        modalIsOpen3: false
      }
    );
  }
  openModal2() {
    this.gtaEvent('Summary of Results', this.state.projector.name);
    $('body').addClass("overflow");
    this.setState(
      {
        modalIsOpen: false,
        modalIsOpen2: true,
        modalIsOpen3: false
      }
    );
  }
  openModal3() {
    if (this.state.projector.name !== undefined) {
      this.gtaEvent('Get Help', this.state.projector.name);
    }
    if ($('#searchProjectors').hasClass('pinned')) {
      $('#searchProjectors').removeClass('pinned');
    }
    $('body').addClass("overflow");
    this.setState(
      {
        modalIsOpen: false,
        modalIsOpen2: false,
        modalIsOpen3: true
      }
    );
  }

  export() {
    
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    //this.subtitle.style.color = '#f00';
  }

  closeModal() {
    $('body.overflow').removeClass("overflow");
    this.setState({modalIsOpen: false});
  }

  closeModal2() {
    $('body.overflow').removeClass("overflow");
    this.setState({modalIsOpen2: false});
  }

  closeModal3() {
    $('body.overflow').removeClass("overflow");
    this.setState({modalIsOpen3: false});
  }

  getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = unescape(value);
    });
    return vars;
  }

  assembleURLComponents() {
    var urlComponents = "&units=" + this.state.units +  
                        "&unitsMulti=" + this.state.unitsMulti + 
                        "&aspectwidth=" + this.state.aspectwidth + 
                        "&aspectheight=" + this.state.aspectheight + 
                        "&orientation=" + this.state.orientation + 
                        "&width=" + this.state.width + 
                        "&height=" + this.state.height + 
                        "&diagonal=" + this.state.diagonal + 
                        "&distance=" + this.state.distance + 
                        "&horizOff=" + this.state.horizOff + 
                        "&vertOff=" + this.state.vertOff;
                        


    var $selectedLens = $(".lens.selected");

    if (this.state.blended === true) {
       urlComponents += "&blended=" + this.state.blended;
    }      

    if ($selectedLens.length > 0) {
      var selectedLens = this.state.lenses[$selectedLens.data("index")];

      urlComponents += "&lens=" + selectedLens.partno;
    }              

    return(urlComponents);
  }

  compare( a, b ) {
    if ( a.name < b.name ){
      return -1;
    }
    if ( a.name > b.name ){
      return 1;
    }
    return 0;
  }

  minRange(item) {
    var slope2;
    var intercept2;
      if (item.formula !== undefined) {
        if(item.formula.length) {
          slope2 = Number(item.formula[0].slope);
          intercept2 = Number(item.formula[0].intercept);
          return this.calculateDistance(slope2, this.state.width, intercept2);
        } else {
          slope2 = Number(item.formula.slope);
          intercept2 = Number(item.formula.intercept);
          return this.calculateDistance(slope2, this.state.width, intercept2);
        }
      }
  }

  maxRange(item) {
    var slope2;
    var intercept2;
      if (item.formula !== undefined) {
        if(item.formula.length && item.formula[0].type !== "fixed") {
          slope2 = Number(item.formula[1].slope);
          intercept2 = Number(item.formula[1].intercept);
          return this.calculateDistance(slope2, this.state.width, intercept2);
        } else {
          if (item.formula.slope !== undefined) {
            slope2 = Number(item.formula.slope);
            intercept2 = Number(item.formula.intercept);
          } else {
            slope2 = Number(item.formula[0].slope);
            intercept2 = Number(item.formula[0].intercept);
          }
          return this.calculateDistance(slope2, this.state.width, intercept2);
        }
      }
  }

  getSuitableLenses() {

    var selectedLens = $(".lens.selected");
    var arr = [];

    if (selectedLens.length > 0) {
        arr.push(this.state.lenses[selectedLens.data("index")])
    } else {
      for(var x = 0; x<this.state.lenses.length; x++) {
        var lensMin = this.minRange(this.state.lenses[x]);
        var lensMax = this.maxRange(this.state.lenses[x]);
        var currentMin = this.state.minDistance * this.state.unitsMulti;
        var currentMax = this.state.maxDistance * this.state.unitsMulti;
        var currentDistance = this.state.distance * this.state.unitsMulti;

        if((currentDistance >= lensMin) && (currentDistance <= lensMax)) {
          arr.push(this.state.lenses[x])
        }
      }
    }

    return(arr)
  }

  hideThrowCard() {
    var selectedLens = $(".lens.selected");
    var isUST = false;
    var lens;
    var showSims = false;


    if (selectedLens.length > 0) {
      lens = this.state.lenses[selectedLens.data("index")];
      showSims = true;
    } else if (this.state.lenses.length > 1) {
      lens = this.state.lenses[0];
      if (lens.type === "UST") {
        lens = this.state.lenses[1];
      }
    }

    if (lens !== undefined) {
      if(lens.type === "UST" || lens.ust != null) {
        if (this.state != null) {
          var projectorSeries = this.state.projector.series.toLowerCase().split(" ")[0];
          if (projectorSeries === "ds") {
            projectorSeries = "d";
          }
          var simulations = this.state.simulations[projectorSeries];
          if (simulations === undefined) {
            simulations = this.state.simulations.other;
          }
          var topImage = "topSim";
          var sideImage = "sideSim";
          var sideImageNew = "UST-side-fixed-new@2x.png";
          if (lens.ust != null) {
            isUST = true;
            topImage = simulations[topImage += "Fixed"];
            sideImage = simulations[sideImage += "Fixed"];
            $('#ust-topView-image').html('<img src="'+ topImage +'" />');
            $('#ust-sideView-image').html('<img src="'+ sideImageNew +'" />');
          } else if(lens.zoomtype === "fixed") {
            isUST = true;
            topImage = simulations[topImage += "Fixed"];
            sideImage = simulations[sideImage += "Fixed"];
            $('#ust-topView-image').html('<img src="'+ topImage +'" />');
            $('#ust-sideView-image').html('<img src="'+ sideImage +'" />');
          } else if (lens.zoomtype === "optional") {
            isUST = true;
            topImage = simulations[topImage += "Optional"];
            sideImage = simulations[sideImage += "Optional"];
            $('#ust-topView-image').html('<img src=' + topImage + ' />');
            $('#ust-sideView-image').html('<img src=' + sideImage + ' />');
          }
        }
      } 
    }

    if (lens !== undefined && lens.ust != null) {
      showSims = false;
      $('#offset-canvas-message').addClass('hide-me');
      $('#throwDistanceCard').removeClass("hide-me");
      $('.offset-form-wrapper').addClass("to-hide");
      $('.offset-hide').addClass('hide-me');
      $('#offset-canvas-container').addClass('hide-me');
      $('.ust-values').addClass("hide-me");
      $('#ust-image-container').removeClass('hide-me');
    } else if (isUST) {
      showSims = false;
      $('#offset-canvas-message').addClass('hide-me');
      $('#throwDistanceCard').addClass("hide-me");
      $('.offset-form-wrapper').addClass("to-hide");
      $('.offset-hide').addClass('hide-me');
      $('#offset-canvas-container').addClass('hide-me');
      $('#ust-image-container').removeClass('hide-me');
    } else {
      $('#throwDistanceCard').removeClass("hide-me");
      $('.offset-hide').removeClass('hide-me');
      $('.offset-form-wrapper').removeClass("to-hide");
      $('.ust-values').addClass("hide-me");
      $('#ust-image-container').addClass('hide-me');
    }

    if (showSims) {
      $('#offset-canvas-container').removeClass('hide-me');
      $('#offset-canvas-message').addClass('hide-me');
    } else {
      $('#offset-canvas-container').addClass('hide-me');
      $('#offset-canvas-message').removeClass('hide-me');
    }
  }

  componentDidMount() {
    //projSelected.selectProjector(projSelected);
    fetch('projectors.json')
      .then(response => response.json())
      .then(data => {
          var projectorsSorted = data.projectors.projector.sort(this.compare)
          this.setState({ projectors: data.projectors.projector, projectorsOrig: data.projectors.projector });
          var simulations = data.simulations;
          this.setState({simulations: simulations});
          var series = [];
          var seriesDiscontinued = [];
          simulations = [];
          var resolution = [];
          var screensize = [];
          var distancetoscreen = [];
          var lumensoutput = [];
          var illuminations = ["All"];

          var currentProjector = -1;
          var vars = this.getUrlVars();

          for(var i=0; i<data.projectors.projector.length; i++) {

            if(vars["projector"] && (data.projectors.projector[i].name === vars["projector"])) {
              currentProjector = i;
            }

            var toinsert = data.projectors.projector[i].series;
            var discontinued = data.projectors.projector[i].discontinued;
            if(series.indexOf(toinsert) === -1 && toinsert && discontinued !== "true") {
              if (toinsert !== "")
                series.push(toinsert);
            }
            if(seriesDiscontinued.indexOf(toinsert) === -1 && toinsert && discontinued !== "false") {
              seriesDiscontinued.push(toinsert);
            }
            if(!toinsert) {
              data.projectors.projector[i].series = "n/a";
            }

            toinsert = data.projectors.projector[i].lumensoutput;
            if(lumensoutput.indexOf(toinsert) === -1 && toinsert) {
              lumensoutput.push(toinsert);
            }
            if(!toinsert) {
              data.projectors.projector[i].lumensoutput = "n/a";
            }

            toinsert = data.projectors.projector[i].screensize;
            if(screensize.indexOf(toinsert) === -1 && toinsert) {
              screensize.push(toinsert);
            }
            if(!toinsert) {
              data.projectors.projector[i].screensize = "n/a";
            }

            toinsert = data.projectors.projector[i].distancetoscreen;
            if(distancetoscreen.indexOf(toinsert) === -1 && toinsert) {
              distancetoscreen.push(toinsert);
            }
            if(!toinsert) {
              data.projectors.projector[i].distancetoscreen = "n/a";
            }

            toinsert = data.projectors.projector[i].resolution;
            if(resolution.indexOf(toinsert) === -1 && toinsert) {
              resolution.push(toinsert);
            }
            if(!toinsert) {
              data.projectors.projector[i].resolution = "n/a";
            }

            var illumination = this.sentenceCase(data.projectors.projector[i]["illumination"])
            if (illuminations.indexOf(illumination) === -1 && illumination !== "")
              illuminations.push(illumination);
          }
          illuminations.sort();
          series.sort();
          seriesDiscontinued.sort();
          resolution.sort();
          screensize.sort();
          distancetoscreen.sort();
          lumensoutput = ["0-4,999", "5,000-9,999","10,000-14,999", "15,000-19,999", "20,000-29,999", "30,000-39,999", "40,000+"];
          var selectSeries = series;
          this.setState({
            serieses:series,
            seriesesDiscontinued:seriesDiscontinued,
            seriesSelect:selectSeries,
            resolutions:resolution,
            distancetoscreens:distancetoscreen,
            screensizes:screensize,
            lumensoutputs:lumensoutput,
            illuminations: illuminations,
            illuminationsOrig: illuminations
          });
          var refUrl = document.referrer;
          if (refUrl.length > 0 && refUrl.indexOf('christiedigital.com') > -1) {
            const anchors = document.querySelectorAll('.projector a');
            anchors.forEach(anchor => {
                const href = anchor.getAttribute('href');
                const hrefPath = new URL(href).pathname;
                if (href && refUrl.includes(hrefPath)) {
                    var projSelected = $(anchor).siblings('.button2').click();
                }
            });
          }

          this.doSearch(this.state.searchValue, this.state.searchResolution, this.state.searchDiscontinued, this.state.searchStarred, this.state.searchSize, this.state.searchDistance, this.state.searchLux, this.state.searchSeries, this.state.searchIllumination);
          var units = "mm";
          if (vars["units"] !== undefined) {
            units = vars["units"];
          }
          $('input[type=radio][value='+units+']').attr("checked", "checked");

          this.applyChangeUnits(units, false);

          var orientation = "h";
          var orientationFull = "horiz";
          if (vars["orientation"] !== undefined) {
            orientation = vars["orientation"];
            if (orientation === "v") {
              orientationFull = "vert";
            }
          }
          $('#' + orientationFull).prop("checked", true);
          this.setState({ orientation: orientation }, () => {
              this.drawSideProjector();
          });

          var ratio = '"16:9"';
          var aspectheight = Number(vars["aspectheight"]);
          var aspectwidth = Number(vars["aspectwidth"]);
          if (vars["aspectwidth"] !== undefined) {
            if (this.state.orientation === "h") {
              ratio = '"' + vars["aspectwidth"] + ":" + vars["aspectheight"] + '"';
            } else {
              ratio = '"' + vars["aspectheight"] + ":" + vars["aspectwidth"] + '"';
              aspectheight = Number(vars["aspectwidth"]);
              aspectwidth = Number(vars["aspectheight"]);
            }
            this.setState({aspectheight:aspectheight, aspectwidth:aspectwidth}, () => {
              this.postChangeUnits(true);
              this.drawSideProjector();
            });
          }
          $('input[type=radio][value='+ ratio +']').attr("checked", "checked");
          $('input[type=radio][value='+ ratio +']').prop("checked", true);
          if(currentProjector !== -1) {
            this.setState({
              width:Number(vars["width"]),
              height:Number(vars["height"]),
              diagonal:Number(vars["diagonal"]),
              distance:Number(vars["distance"]),
              horizOff:Number(vars["horizOff"]),
              vertOff:Number(vars["vertOff"]),
              setDistance:false,
              setHorizVert: false
            }, function() {
              $("#throwDistance").val(this.state.distance);
              if (this.state.units === "mm" || this.state.units === "in") {
                $("#throwDistanceInput").val(this.myRoundMM(this.state.distance));
              } else {
                $("#throwDistanceInput").val(this.myRound(this.state.distance));
              }
              this.drawSideProjector();
              if (vars["lens"] !== undefined) {
                this.setState({setLens:true, lensPreselect: vars["lens"]});
              }
              this.setState(
                {partno:Number(vars["partno"])},
                function() {
                  this.selectProjector(currentProjector, true);
                  // if (orientation === "v") {
                  //   this.setState({orientation: orientation})
                  // }
                  if (vars["blended"] !== undefined)
                  {
                    this.setState({blended:Boolean(vars["blended"])});
                  }
                }
              );

            });
          }
        });   
  }

  sentenceCase (str) {
    if (str !== undefined) {
      return str.replace(/[a-z]/i, function (letter) {
        return letter.toUpperCase();
      }).trim();
    } else
      return "";
  }

  toggleTab(tab)  {
    var tabNumber = $(tab.currentTarget).data("tab-index");

    var tabs = $("#tabs a");
    tabs.removeClass("active");
    $(tab.currentTarget).addClass("active");

    var tabContainers = $("[data-tab]");
    tabContainers.addClass("initially-hidden-mobile")
    $("[data-tab=" + tabNumber + "]").removeClass("initially-hidden-mobile")
  }

  toggleWelcome() {
    $("#splashScreenMobile").addClass("always-hidden");
  }

  toggleCalculateScreen() {
    if(this.state.calculateScreen) {
      this.setState(
        {
          calculateScreen:false,
          calculateThrow: true
        }
      );
    } else {
      this.setState(
        {
          calculateScreen:true, 
          calculateThrow: false
        },
        function() {
          var width = this.getWidthFromThrowDistance();
          var diagonal = this.getProjectedDiagonalFromWidth(width, Number(this.state.aspectwidth), Number(this.state.aspectheight));
          var height = (Number(this.state.aspectheight) * width) / (Number(this.state.aspectwidth) * 1.0);

          if (this.state.calculateScreen) {
            if (this.state.units !== "ft") {
              if (this.state.units === "mm" || this.state.units === "in") {
                $('#manualHeight').val(this.myRoundMM(height  * this.state.unitsMulti));
                $('#manualWidth').val(this.myRoundMM(width * this.state.unitsMulti));
                $('#manualDiagonal').val(this.myRoundMM(diagonal * this.state.unitsMulti));
              } else {
                $('#manualHeight').val(this.myRound(height  * this.state.unitsMulti));
                $('#manualWidth').val(this.myRound(width * this.state.unitsMulti));
                $('#manualDiagonal').val(this.myRound(diagonal * this.state.unitsMulti));
              }
            } else {
              $('#manualHeight').val(this.myRoundFeet(height  * this.state.unitsMulti));
              $('#manualWidth').val(this.myRoundFeet(width * this.state.unitsMulti));
              $('#manualDiagonal').val(this.myRoundFeet(diagonal * this.state.unitsMulti));
            }

            this.setState({
              width:width, 
              height: height,
              diagonal: diagonal
            }, function () {

                this.drawSideProjector();

            });
          } else {
            this.drawSideProjector();
          }
        }
      );
    }
  }

  toggleCalculateThrow() {
    if(this.state.calculateThrow) {
      this.setState(
        {
          calculateThrow:false,
          calculateScreen:true
        }
      );
    } else {
      this.setState(
        {calculateThrow:true, calculateScreen: false},
        function() {
          this.setMinMax();
        });
    }
  }

  hideOffsets() {
    var selectedLens = $(".lens.selected");
    if (selectedLens.length > 0) {
      var lens = this.state.lenses[selectedLens.data("index")];
      var lensType = lens.type;
      var zoomType = lens.zoomtype;
      if (lensType === "UST") {
        if (zoomType === "fixed" || zoomType === "optional") {
          $('.offset-form-wrapper').addClass("to-hide");
          $('.offset-hide').addClass('hide-me');
        } else {
          $('.offset-hide').removeClass('hide-me');
          $('.offset-form-wrapper').removeClass("to-hide");
        }
      } else {
        $('.offset-hide').removeClass('hide-me');
        $('.offset-form-wrapper').removeClass("to-hide");
      }
    } else {
      $('.offset-hide').removeClass('hide-me');
      $('.offset-form-wrapper').removeClass("to-hide");
    }
  }


  render() {

    return (
      <div className="App">
        <div className="container">
          <div className="row nav">
            <div className="col-md-6 col-sm-6 col-xs-6 mainLogo">
              <img src={logo} alt="logo" />

            </div>
            <div className="col-md-6 col-sm-6 col-xs-6 right">
              <button className="hide-me" dangerouslySetInnerHTML={infoButton()}></button> <button className="hide-me" dangerouslySetInnerHTML={settingsButton()}></button> 
              <button className="hide-me" dangerouslySetInnerHTML={summaryButton()}></button>
            </div>
          </div>
          <div id="mainNav" className="row nav hide-me">
            <div className="col-md-7 col-sm-4 col-xs-4">
              <div>
                <button style={{display: 'none'}} className="primary-button" value="single" onClick={this.toggleMode.bind(this)}><input id="single_selected" className="initial single_blended" type="radio" value="single" />Single projector</button>
                <button style={{display: 'none'}} className="primary-button" value="blended" onClick={this.toggleMode.bind(this)}><input id="blended_selected" className="single_blended" type="radio" value="blended" />Blended projectors</button>
                <button className="primary-button" onClick={this.reset.bind(this)}>Reset</button>
                <button className="tertiary-button" onClick={this.openModal3}>Help</button>
              </div>
            </div>
            <div className="col-md-5 col-sm-8 col-xs-8 right">
              <button className="secondary-button" onClick={this.openModal2}><span className="desktop-only">Summary of results</span><span className="mobile-only">Results</span></button>
              <button className="tertiary-button" onClick={this.openModal}>Get a quote</button>

              <button className="hide-me" dangerouslySetInnerHTML={infoButton()}></button> <button className="hide-me" dangerouslySetInnerHTML={settingsButton()}></button> <button className="hide-me" dangerouslySetInnerHTML={summaryButton()}></button>
            </div>
          </div>
          <div id="tabs" className="initially-hidden">
            <ul>
              <li><a href="#" data-tab-index="2" onClick={this.toggleTab.bind(this)} className="active">Calculate</a></li>
              <li><a href="#" data-tab-index="3" onClick={this.toggleTab.bind(this)}>Lens</a></li>
            </ul>
          </div>
          <div id="notification-values-displayed" className="notification hide-me">
            <div className="icon" dangerouslySetInnerHTML={infoIcon()}></div> <p>Values displayed by the projector/screen simulations are for illustration only. <a href='https://www.christiedigital.com/help-center/contact-us/' target='_blank'>Contact Christie</a> to confirm installation-critical values.</p><button onClick={this.hideNotification.bind(this)} dangerouslySetInnerHTML={xIcon()}></button>
          </div>
          <div className="row main-app">
            <div id="splashScreenMobile" className="col-md-8 visible-mobile">
              <div id="welcomeTextMobile" className="appContainer3">
                <h2>Get started with the projector lens calculator</h2>
                <p className="disclaimerFront disclaimerText">Welcome to Christie’s tool for selecting projector lenses, based on your unique installation requirements. This tool will help you calculate the lens, the throw distance or your projected image/screen dimensions.</p>
                <p className="disclaimerFront disclaimerText">To begin, use the search and filter options on the left to find a specific projector model, or you can access a list of projectors that meet your criteria based on product series, brightness, resolution and/or illumination type. Review the returned list and click “Select” to choose a projector for your calculations.</p>
                <p className="disclaimerFront disclaimerText">For more information on how to use this calculator, watch our <a href="https://www.youtube.com/watch?v=XGymiduaImI" target="_blank">video tutorial</a>. You can also view tutorials about <a href="https://www.youtube.com/watch?v=Z27fvb-8eoU" target="_blank">calculating throw distances for ultra-short throw lenses</a>, and <a href="https://www.youtube.com/watch?v=QPS4h8vwSLo" target="_blank">how to use this calculator on a mobile device</a>.</p>
                <p className="disclaimerFront disclaimerText">Note: All values are for reference only. Specifications are subject to change without notice. Please contact a Christie representative to confirm critical installation values. <a href="https://www.christiedigital.com/help-center/contact-us/" target="_blank">Get in touch</a>.</p>
                <button className="secondary-button" onClick={this.toggleWelcome.bind(this)}>Close</button>
              </div>
            </div>
            <div id="searchProjectors" className="col-md-4">
              <div className="appContainer">
                <div className="form-wrapper">
                  <h3>Search current projectors <button className="tertiary-button" onClick={this.openModal3}>Help</button></h3>
                  <div className="searchIcon"><div className="icon" dangerouslySetInnerHTML={searchIcon()}></div>
                  <input id="searchText" type="text" placeholder="Search by part no or model name" onChange={this.projectorSearch.bind(this)} /></div>
                </div>

                <div className="form-wrapper">
                  <select onChange={this.seriesSearch.bind(this)} value={this.state.series} id="seriesSelect">
                    <option value="">Series</option>
                    {this.state.seriesSelect.map((series) => <option key={series} value={series}>{series}</option>)}
                  </select>
                </div>
                <div className="form-wrapper">
                  <select onChange={this.luxSearch.bind(this)} value={this.state.lux}>
                    <option value="">Brightness (lumens)</option>
                    {this.state.lumensoutputs.map((lumens) => <option key={lumens} value={lumens}>{lumens} Lumens</option>)}
                  </select>
                </div>
                <div className="form-wrapper">
                  <select onChange={this.resolutionSearch.bind(this)} value={this.state.resolution}>
                    <option value="">Resolution</option>
                    {this.state.resolutions.map((reso) => <option key={reso} value={reso}>{reso}</option>)}
                  </select>
                </div>
                <div className="form-wrapper">
                  <select onChange={this.illuminationSearch.bind(this)} value={this.state.illumination}>
                    <option value="">Illumination</option>
                    {this.state.illuminations.map((ill) => <option key={ill} value={ill}>{ill}</option>)}
                  </select>
                </div>
                <div className="form-wrapper" style={{display: 'none'}}>
                  <select onChange={this.sizeSearch.bind(this)} value={this.state.size}>
                    <option value="">Screen size</option>
                    {this.state.screensizes.map((size) => <option key={size} value={size}>{size} Feet</option>)}
                  </select>
                </div>
                <div className="form-wrapper" style={{display: 'none'}}>
                  <select onChange={this.distanceSearch.bind(this)} value={this.state.distance}>
                    <option value="">Distance to screen</option>
                    {this.state.distancetoscreens.map((distance) => <option key={distance} value={distance}>{distance} Feet</option>)}
                  </select>
                </div>
                <div className="form-wrapper hide-me">
                  <h3>Projectors</h3>
                </div>
                <div className="form-wrapper">
                  <label className="switchWrapper">
                    <Switch onChange={this.discontinuedSearch} checked={this.state.searchDiscontinued} height={20}
                      width={48} uncheckedIcon={false}
                      checkedIcon={false} onColor="#2370b4" title="" />
                    <span>&nbsp;&nbsp;&nbsp;Show legacy products</span>
                  </label>
                </div>
                <div className="form-wrapper">
                  <label className="switchWrapper">
                    <Switch onChange={this.starredSearch} checked={this.state.searchStarred} height={20}
                      width={48} uncheckedIcon={false}
                      checkedIcon={false} onColor="#2370b4" title="" />
                    <span>&nbsp;&nbsp;&nbsp;Only show starred</span>
                  </label>
                </div>
      
                <ProjectorList projectors={this.state.projectors} selectProjector={this.selectProjector.bind(this)} toggleStar={this.toggleStar.bind(this)} />
                <div id="no-projectors-found">
                  No projectors matched the search criteria.
                </div>
                <div className="clear"></div>
              </div>
            </div>
            <div id="splashScreen" className="col-md-8 hidden-mobile">
              <div className="appContainer3">
                <h2>Get started with the projector lens calculator</h2>
                <p className="disclaimerFront disclaimerText">Welcome to Christie’s tool for selecting projector lenses, based on your unique installation requirements. This tool will help you calculate the lens, the throw distance or your projected image/screen dimensions.</p>
                <p className="disclaimerFront disclaimerText">To begin, use the search and filter options on the left to find a specific projector model, or you can access a list of projectors that meet your criteria based on product series, brightness, resolution and/or illumination type. Review the returned list and click “Select” to choose a projector for your calculations.</p>
                <p className="disclaimerFront disclaimerText">For more information on how to use this calculator, watch our <a href="https://www.youtube.com/watch?v=XGymiduaImI" target="_blank">video tutorial</a>. You can also view tutorials about <a href="https://www.youtube.com/watch?v=Z27fvb-8eoU" target="_blank">calculating throw distances for ultra-short throw lenses</a>, and <a href="https://www.youtube.com/watch?v=QPS4h8vwSLo" target="_blank">how to use this calculator on a mobile device</a>.</p>
                <p className="disclaimerFront disclaimerText">Note: All values are for reference only. Specifications are subject to change without notice. Please contact a Christie representative to confirm critical installation values. <a href="https://www.christiedigital.com/help-center/contact-us/" target="_blank">Get in touch</a>.</p>
              </div>
            </div>
            <div id="setValues" data-tab="2" className="col-md-4 initially-hidden">
              <div className="appContainerSmall">
                <div id="projectorName" className="initially-hidden">
                  <div>
                    <h3>{this.state.projector.name}</h3>
                  </div>
                  <button onClick={this.toggleProjectors}>Change</button> 
                </div>
              </div>

              <div className="appContainerBig">            
                <h3>Screen dimensions</h3>
                <p id="single-projector-notification">Enter the dimensions of your screen or desired image. If you are unsure of your screen size, enter your throw distance, select a lens and use the ‘calculate screen dimensions’ feature below.</p>
                <p id="blended-projector-notification" className="hide-me">Enter the Width (maximum image width possible for a single projector) based on the projector model you have selected. </p>

                <div id="widthContainer" className={this.state.units === "ft" ? "form-wrapper small-sliders shortened" : "form-wrapper small-sliders"}>
                  <h4>Width</h4>
                  <input id="screenWidth" style={{display: 'none'}}  onChange={this.changeWidth.bind(this)} type="range" defaultValue="0" min={this.state.minWidth} max={this.state.maxWidth} step={0.1} />
                  <InputRange
                    id="screenWidth2" step={1}
                    maxValue={this.state.maxWidth}
                    minValue={this.state.minWidth}
                    value={this.state.width}
                    onChange={this.changeWidth.bind(this)} 
                    formatLabel={value => ``} />
                  <input id="manualWidth" className="manualInput" type="text" onChange={this.manualChangeWidth.bind(this)} min="0" onKeyPress={this.positiveOnly.bind(this)} onKeyUp={this.inputValueCheck.bind(this)} />
                  <div className="float-units">{this.state.units}</div>
                  <input id="manualWidthInInput" className={this.state.units !== "ft" ? "hide-me" : "manualInput secondInput"} onKeyPress={this.positiveOnly.bind(this)} onKeyUp={this.inputValueCheck.bind(this)} type="text" min="0" max="11" onChange={this.manualChangeWidthInches.bind(this)} defaultValue="0" />
                  <span className={this.state.units !== "ft" ? "hide-me" : "manualLabel"}>in</span>
                  <div className="clear"></div>                    
                  <div className={this.state.units === "ft" ? "minLabel" : "hide-me"}>
                    {this.feetAndInches(this.state.minWidth * this.state.unitsMulti)}
                  </div>
                  <div className={this.state.units !== "ft" && this.state.units !== "mm" && this.state.units !== "in" ? "minLabel" : "hide-me"}>
                    {this.myRound(this.state.minWidth * this.state.unitsMulti)} {this.state.units}
                  </div>
                  <div className={this.state.units === "mm" || this.state.units === "in" ? "minLabel" : "hide-me"}>
                    {this.myRoundMM(this.state.minWidth * this.state.unitsMulti)} {this.state.units}
                  </div>
                  <div className={this.state.units === "ft" ? "maxLabel" : "hide-me"}>
                    {this.feetAndInches(this.state.maxWidth * this.state.unitsMulti)}
                  </div>
                  <div className={this.state.units !== "ft" && this.state.units !== "mm" && this.state.units !== "in" ? "maxLabel" : "hide-me"}>
                    {this.myRound(this.state.maxWidth * this.state.unitsMulti)} {this.state.units}
                  </div>
                  <div className={this.state.units === "mm" || this.state.units === "in" ? "maxLabel" : "hide-me"}>
                    {this.myRoundMM(this.state.maxWidth * this.state.unitsMulti)} {this.state.units}
                  </div>
                  <div className="clear"></div>
                </div>
                <div id="heightContainer" className={this.state.units === "ft" ? "form-wrapper small-sliders shortened" : "form-wrapper small-sliders"}>
                  <h4>Height</h4>
                  <input id="screenHeight" style={{display: 'none'}}  onChange={this.changeHeight.bind(this)} type="range" defaultValue="0" min={this.state.minHeight} max={this.state.maxHeight} step={0.1} />
                  <InputRange
                    id="screenHeight2" step={1}
                    maxValue={this.state.maxHeight}
                    minValue={this.state.minHeight}
                    value={this.state.height}
                    onChange={this.changeHeight.bind(this)} 
                    formatLabel={value => ``}
                   />
                  <input id="manualHeight"  className="manualInput" type="text" onKeyPress={this.positiveOnly.bind(this)} onKeyUp={this.inputValueCheck.bind(this)} onChange={this.manualChangeHeight.bind(this)} min="0" />
                  <div className="float-units">{this.state.units}</div>
                  <input id="manualHeightInInput" className={this.state.units !== "ft" ? "hide-me" : "manualInput secondInput"} onKeyPress={this.positiveOnly.bind(this)} onKeyUp={this.inputValueCheck.bind(this)} type="text" min="0" max="11" onChange={this.manualChangeHeightInches.bind(this)} defaultValue="0" />
                  <span className={this.state.units !== "ft" ? "hide-me" : "manualLabel"}>in</span>
                  <div className="clear"></div>                 
                    <div className={this.state.units === "ft" ? "minLabel" : "hide-me"}>
                      {this.feetAndInches(this.state.minHeight * this.state.unitsMulti)}
                    </div>
                    <div className={this.state.units !== "ft" && this.state.units !== "mm" && this.state.units !== "in" ? "minLabel" : "hide-me"}>
                      {this.myRound(this.state.minHeight * this.state.unitsMulti)} {this.state.units}
                    </div>
                    <div className={this.state.units === "mm" || this.state.units === "in" ? "minLabel" : "hide-me"}>
                      {this.myRoundMM(this.state.minHeight * this.state.unitsMulti)} {this.state.units}
                    </div>
                    <div className={this.state.units === "ft" ? "maxLabel" : "hide-me"}>
                      {this.feetAndInches(this.state.maxHeight * this.state.unitsMulti)}
                    </div>
                    <div className={this.state.units !== "ft" && this.state.units !== "mm" && this.state.units !== "in" ? "maxLabel" : "hide-me"}>
                      {this.myRound(this.state.maxHeight * this.state.unitsMulti)} {this.state.units}
                    </div>
                    <div className={this.state.units === "mm" || this.state.units === "in" ? "maxLabel" : "hide-me"}>
                      {this.myRoundMM(this.state.maxHeight * this.state.unitsMulti)} {this.state.units}
                    </div>
                    <div className="clear"></div>
                </div>
                <div id="diagonalContainer" className={this.state.units === "ft" ? "form-wrapper small-sliders shortened" : "form-wrapper small-sliders"}>
                  <h4>Diagonal</h4>
                  <input id="screenDiagonal" style={{display: 'none'}} onChange={this.changeDiagonal.bind(this)} type="range" defaultValue="0" min="0" max="16000" step={0.1} />
                  <InputRange
                    id="screenDiagonal2" step={1}
                    maxValue={this.state.maxDiag}
                    minValue={this.state.minDiag}
                    value={this.state.diagonal}
                    onChange={this.changeDiagonal.bind(this)} 
                    formatLabel={value => ``}
                     />
                  <input id="manualDiagonal"className="manualInput" type="text" onKeyPress={this.positiveOnly.bind(this)} onKeyUp={this.inputValueCheck.bind(this)} onChange={this.manualChangeDiagonal.bind(this)} min="0" />
                  <div className="float-units">{this.state.units}</div>
                  <input id="manualDiagonalInInput" className={this.state.units !== "ft" ? "hide-me" : "manualInput secondInput"} onKeyPress={this.positiveOnly.bind(this)} onKeyUp={this.inputValueCheck.bind(this)} type="text" min="0" max="11" onChange={this.manualChangeDiagonalInches.bind(this)} defaultValue="0" />
                  <span className={this.state.units !== "ft" ? "hide-me" : "manualLabel"}>in</span>
                  <div className="clear"></div>

                  <div className={this.state.units === "ft" ? "minLabel" : "hide-me"}>
                    {this.feetAndInches(this.state.minDiag * this.state.unitsMulti)}
                  </div>
                  <div className={this.state.units !== "ft" && this.state.units !== "mm" && this.state.units !== "in" ? "minLabel" : "hide-me"}>
                    {this.myRound(this.state.minDiag * this.state.unitsMulti)} {this.state.units}
                  </div>
                  <div className={this.state.units === "mm" || this.state.units === "in" ? "minLabel" : "hide-me"}>
                    {this.myRoundMM(this.state.minDiag * this.state.unitsMulti)} {this.state.units}
                  </div>
                  <div className={this.state.units === "ft" ? "maxLabel" : "hide-me"}>
                    {this.feetAndInches(this.state.maxDiag * this.state.unitsMulti)}
                  </div>
                  <div className={this.state.units !== "ft" && this.state.units !== "mm" && this.state.units !== "in" ? "maxLabel" : "hide-me"}>
                    {this.myRound(this.state.maxDiag * this.state.unitsMulti)} {this.state.units}
                  </div>
                  <div className={this.state.units === "mm" || this.state.units === "in" ? "maxLabel" : "hide-me"}>
                    {this.myRoundMM(this.state.maxDiag * this.state.unitsMulti)} {this.state.units}
                  </div>
                  <div className="clear"></div>
                  <div id="diagonal-alert" className="hide-me">
                    <span>The Diagonal is outside the min/max for the lens</span>
                  </div>          
                  <div className="clear"></div>       
                </div>
                <label className="switchWrapper">
                  <Switch id="distance-lock-container" onChange={this.toggleCalculateThrow.bind(this)} checked={this.state.calculateThrow} height={20}
                    width={48} uncheckedIcon={false}
                    checkedIcon={false} onColor="#2370b4" title="" />
                  <span>&nbsp;&nbsp;&nbsp;Calculate throw distance  
                    <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                      <Tooltip>
                                          Select a lens and confirm your screen dimensions first.
                                      </Tooltip>
                                  }
                              >
                      <button className="tooltip-btn throw-distance-tooltip" dangerouslySetInnerHTML={infoButton()}></button>
                    </OverlayTrigger>
                  </span>
                </label>
                <h4>Units</h4>
                <div id="unitsContainer" className="form-wrapper row">
                  <div className="col-sm-6">
                    <input type="radio" id="mm" name="units" value="mm" onChange={this.changeUnits.bind(this)} />&nbsp;&nbsp;&nbsp;&nbsp;mm <br />
                    <input type="radio" id="cm" name="units" value="cm" onChange={this.changeUnits.bind(this)} />&nbsp;&nbsp;&nbsp;&nbsp;cm <br />
                    <input type="radio" id="m" name="units" value="m" onChange={this.changeUnits.bind(this)} />&nbsp;&nbsp;&nbsp;&nbsp;m
                  </div>
                  <div className="col-sm-6">
                    <input type="radio" id="in" name="units" value="in" onChange={this.changeUnits.bind(this)} />&nbsp;&nbsp;&nbsp;&nbsp;in <br />
                    <input type="radio" id="ft" name="units" value="ft" onChange={this.changeUnits.bind(this)} />&nbsp;&nbsp;&nbsp;&nbsp;ft <br />
                  </div>
                </div>

                <div className="form-wrapper">
                  <h4>Screen view</h4>
                  <p id="content-image-dimensions"></p>
                  <p id="projected-image-dimensions"></p>
                  <canvas id="canScreen" className="chart chart3"></canvas>
                </div>

                <div id="ratioContainer">
                  <h4>Aspect ratio 
                    <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip>
                              The aspect ratio defaults to the native aspect ratio of the projector you have selected. 
                          </Tooltip>
                        }
                    >
                      <button className="tooltip-btn" dangerouslySetInnerHTML={infoButton()}></button>
                    </OverlayTrigger>
                  </h4>
                    
                  <div className="form-wrapper row">
                    <div className="col-sm-6">
                      <input type="radio" id="169" name="ratio" value="16:9" onChange={this.changeRatio.bind(this)} />&nbsp;&nbsp;&nbsp;&nbsp;16:9 <br />
                      <input type="radio" id="1610" name="ratio" value="16:10" onChange={this.changeRatio.bind(this)} />&nbsp;&nbsp;&nbsp;&nbsp;16:10 <br />
                      <input type="radio" id="43" name="ratio" value="4:3" onChange={this.changeRatio.bind(this)} />&nbsp;&nbsp;&nbsp;&nbsp;4:3 <br />
                    </div>
                    <div className="col-sm-6">
                      <input type="radio" id="54" name="ratio" value="5:4" onChange={this.changeRatio.bind(this)} />&nbsp;&nbsp;&nbsp;&nbsp;5:4 <br />
                      <input type="radio" id="1710" name="ratio" value="17:10" onChange={this.changeRatio.bind(this)} />&nbsp;&nbsp;&nbsp;&nbsp;17:10 <br />
                      <input type="radio" id="179" name="ratio" value="17:9" onChange={this.changeRatio.bind(this)} />&nbsp;&nbsp;&nbsp;&nbsp;17:9
                    </div>
                  </div>
                </div>

                <div id="oriContainer">
                <h4>Orientation</h4>
                <div className="form-wrapper row">
                  <div className="col-sm-6">
                    <input type="radio" id="horiz" name="ori" value="hooriz" onChange={this.changeOrientation.bind(this)} />&nbsp;&nbsp;&nbsp;&nbsp;Landscape
                  </div>
                  <div className="col-sm-6">
                    <input type="radio" id="vert" name="ori" value="vert" onChange={this.changeOrientation.bind(this)} />&nbsp;&nbsp;&nbsp;&nbsp;Portrait
                  </div>
                </div>
                </div>

                <div id="gain" className="form-wrapper hide-me">
                  <h4>Gain</h4>
                  <input type="range" defaultValue="0" min="0" max="100" /><br />
                  0.00
                </div>
                <div id="ambientlight" className="form-wrapper hide-me">
                  <h4>Ambient Light</h4>
                  <input type="range" defaultValue="0" min="0" max="100" /><br />
                  0.00 lux
                </div>


                <div className="form-wrapper">

                </div>
                <div className="form-wrapper hide-me">
                  <h4>Brightness</h4>
                  <input type="range" defaultValue="0" min="0" max="100" /><br />
                  0.00%
                </div>
                <div id="stackprojectors" className="form-wrapper hide-me">
                  <h4>Stack projectors</h4>
                  <input type="range" defaultValue="0" min="0" max="100" /><br />
                  1
                </div>
                <div className="form-wrapper hide-me">
                  <h4>Horizontal offset</h4>
                  <input type="range" defaultValue="0" min="0" max="100" /><br />
                  0.00%
                </div>
                <div className="form-wrapper hide-me">
                  <h4>Vertical offset</h4>
                  <input type="range" defaultValue="0" min="0" max="100" /><br />
                  0.00%
                </div>

                </div>
            </div>

            <div id="simulation" data-tab="2" className="col-md-4 initially-hidden initially-hidden-mobile">
              <div id="throwDistanceCard" className={this.state.units === "ft" ? "appContainerSmall2 small-sliders shortened" : "appContainerSmall2 small-sliders"}>                          
                <h3>Throw distance 
                  <OverlayTrigger
                      placement="bottom"
                      overlay={
                          <Tooltip>
                              The distance from the projector to the screen. For standard lenses, the distance is measured from the front foot of the projector.
                          </Tooltip>
                      }
                  >
                    <button className="tooltip-btn" dangerouslySetInnerHTML={infoButton()}></button>
                  </OverlayTrigger>
                </h3>
                <p>Calculated throw distances are subject to a +/- 5% lens tolerance.</p>
                <div id="throwDistanceWrapper">
                  <input id="throwDistance" type="range" style={{display: 'none'}} defaultValue={this.state.globalMinDistance} min={this.state.globalMinDistance} max={this.state.globalMaxDistance} />
                  <InputRange
                    id="screenDistance2"
                    step={0.01}
                    minValue={this.state.globalMinDistance}
                    maxValue={this.state.globalMaxDistance}
                    value={this.state.distance}
                    onChange={this.changeDistance.bind(this)}  
                    formatLabel={value => ``}                    
                   />
                  <input id="throwDistanceInput" className="manualInput" type="text" onKeyPress={this.positiveOnly.bind(this)} onKeyUp={this.inputValueCheck.bind(this)} defaultValue="0" onChange={this.manualChangeDistance.bind(this)} />
                  <span className="manualLabel">{this.state.units}</span>     
                  <input id="throwDistanceInInput" className={this.state.units !== "ft" ? "hide-me" : "manualInput secondInput"} onKeyPress={this.positiveOnly.bind(this)} type="text" min="0" max="11" onChange={this.manualChangeDistanceInches.bind(this)} defaultValue="0" />
                  <span className={this.state.units !== "ft" ? "hide-me" : "manualLabel"}>in</span>
                  <div className="clear"></div> 
                  <div className={this.state.units === "ft" ? "minLabel" : "hide-me"}>
                    {this.feetAndInches(this.state.globalMinDistance * this.state.unitsMulti)}
                  </div>
                  <div className={this.state.units !== "ft" && this.state.units !== "mm" && this.state.units !== "in" ? "minLabel" : "hide-me"}>
                    {this.myRound(this.state.globalMinDistance * this.state.unitsMulti)} {this.state.units}
                  </div>
                  <div className={this.state.units === "mm" || this.state.units === "in" ? "minLabel" : "hide-me"}>
                    {this.myRoundMM(this.state.globalMinDistance * this.state.unitsMulti)} {this.state.units}
                  </div>
                  <div className={this.state.units === "ft" ? "maxLabel" : "hide-me"}>
                    {this.feetAndInches(this.state.globalMaxDistance * this.state.unitsMulti)}
                  </div>
                  <div className={this.state.units !== "ft" && this.state.units !== "mm" && this.state.units !== "in" ? "maxLabel" : "hide-me"}>
                    {this.myRound(this.state.globalMaxDistance * this.state.unitsMulti)} {this.state.units}
                  </div>
                  <div className={this.state.units === "mm" || this.state.units === "in" ? "maxLabel" : "hide-me"}>
                    {this.myRoundMM(this.state.globalMaxDistance * this.state.unitsMulti)} {this.state.units}
                  </div>
                  <div className="clear"></div>
                  <div id="distance-alert" className="hide-me">
                    <span>The distance is outside the min/max for the lens</span>
                  </div>
                </div>
                <label className="switchWrapper">
                  <Switch id="dimensions-lock-container" onChange={this.toggleCalculateScreen.bind(this)} checked={this.state.calculateScreen} height={20}
                    width={48} uncheckedIcon={false}
                    checkedIcon={false} onColor="#2370b4" title="" />
                  <span>&nbsp;&nbsp;&nbsp;Calculate screen dimensions</span>
                </label>
              </div>
                        
              <div className="appContainerSmall2 hide-me ust-values">
              </div>

              <div id="blended-projector" className="appContainerBig2 hide-me">
                <h3>Total screen size</h3>
                <p>Enter the width (maximum image width possible for a single projector) based on the projector model you have selected.</p>


                <div className="form-wrapper">
                                <h4>Overall width <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip>
                                            The overall width of the projected image. 
                </Tooltip>
                                    }
                                >
                                    <button className="tooltip-btn" dangerouslySetInnerHTML={infoButton()}></button>
                                </OverlayTrigger></h4>
                  
                  <InputRange
                    id="overallwidth"
                    maxValue={this.state.width*3}
                    minValue={this.state.width}
                    value={this.state.overallWidth}
                    onChange={this.changeOverallWidth.bind(this)} formatLabel={value => ``}
                  /> 

                  <span className={this.state.units === "ft" ? "hide-me label" : "label"}>{this.myRound(this.state.overallWidth * this.state.unitsMulti)} {this.state.units}</span>
                  <span className={this.state.units !== "ft" ? "hide-me label" : "label"}>{this.feetAndInches(this.state.overallWidth * this.state.unitsMulti)}&nbsp;</span>

                </div>
                <div className="form-wrapper">
                  <label className="switchWrapper">
                    <Switch onChange={this.calculateBlendOverlapChange.bind(this)} checked={this.state.calculateBlendOverlap} height={20}
                      width={48} uncheckedIcon={false}
                      checkedIcon={false} onColor="#2370b4" title="" />
                    <span>&nbsp;&nbsp;&nbsp;Calculate blend overlap</span>
                  </label>
                </div>
                <div id="blendwidthContainer" className="form-wrapper">
                                <h4>Blend overlap <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip>
                                            The amount that the images from each projector will overlap. Christie recommends an overlap of at least 15% of the overall image width. The default measurement of 15% can be adjusted. 
                </Tooltip>
                                    }
                                >
                                    <button className="tooltip-btn" dangerouslySetInnerHTML={infoButton()}></button>
                                </OverlayTrigger></h4>
                  
                  <InputRange
                    id="blendwidth"
                    maxValue={100}
                    minValue={1}
                    value={this.state.blendWidth}
                    onChange={this.changeBlendWidth.bind(this)}  formatLabel={value => ``}
                 /> 

                  <span className={this.state.units === "ft" ? "hide-me label" : "label"}>{this.myRound(this.state.blendWidth * this.state.unitsMulti)} {this.state.units}</span>
                  <span className={this.state.units !== "ft" ? "hide-me label" : "label"}>{this.feetAndInches(this.state.blendWidth * this.state.unitsMulti)}&nbsp;</span>


                  <div id="blendWidthalert" className="hide-me">
                    <span>The blend overlap is less than 10% of the overall width</span>
                  </div>
                </div>
                <div className="form-wrapper">
                  <label className="switchWrapper">
                    <Switch onChange={this.calculateBlendedWidthChange.bind(this)} checked={this.state.calculateBlendedWidth} height={20}
                      width={48} uncheckedIcon={false}
                      checkedIcon={false} onColor="#2370b4" title="" />
                    <span>&nbsp;&nbsp;&nbsp;Calculate overall width</span>
                  </label>
                </div>

                <div className="form-wrapper">
                  <h4>Number of projectors <OverlayTrigger
                      placement="bottom"
                      overlay={
                          <Tooltip>
                              This calculator supports a maximum of 3 horizontally blended projectors.  
                          </Tooltip>
                        }
                    >
                        <button className="tooltip-btn" dangerouslySetInnerHTML={infoButton()}></button>
                    </OverlayTrigger></h4>
                  
                  <InputRange
                    id="noprojectors"
                    maxValue={3}
                    minValue={2}
                    value={this.state.noProjectors}
                    onChange={this.changeNoProjectors.bind(this)}  formatLabel={value => ``}
                 /> 

                 {this.state.noProjectors}
                </div>
                <div className="form-wrapper">
                  <label className="switchWrapper">
                    <Switch onChange={this.calculateNoOfProjectorsChange.bind(this)} checked={this.state.calculateNoOfProjectors} height={20}
                      width={48} uncheckedIcon={false}
                      checkedIcon={false} onColor="#2370b4" title="" />
                    <span>&nbsp;&nbsp;&nbsp;Calculate number of projectors</span>
                  </label>
                </div>

                <canvas id="canBlend" className="chart"></canvas>
              </div>


              <div id="single-projector" className="appContainerBig2">
                <h3>Offset simulation</h3>
                <p id="offset-canvas-message" className="hide-me"><i>Select a lens to view simulations</i></p>
                <div id="offset-canvas-container">
                  <p className="offset-hide">The offset measures the position of the projector relative to the centerline of the screen (0% offset means the center of the lens is even with the center of the screen). Calculated offsets are subject to +/- 7% tolerance.</p>

                  <div className="form-wrapper offset-form-wrapper">
                    <h4 className="offset-hide subtitle">Vertical offset</h4>
                    <InputRange
                      className={this.hideOffsets()}
                      id="verticalOffsetSlider"
                      disabled={this.hideOffsets()}
                      maxValue={this.state.maxVert}
                      minValue={this.state.minVert}
                      value={this.state.vertOff}
                      onChange={this.changeVert.bind(this)}  formatLabel={value => ``}
                     />

                    <div className="offset-hide" id="verticalOffsetInfo">
                      {(Math.sign(this.state.vertOff) === -1) ? "-" : ""}{Math.abs(this.state.vertOff)}% or {this.state.units === "mm" || this.state.units === "in" ? Math.abs(this.myRoundMM(this.state.unitsMulti * (this.state.vertOff/100.0) * (this.state.height/2)  )) : Math.abs(this.myRound(this.state.unitsMulti * (this.state.vertOff/100.0) * (this.state.height/2)  ))} {this.state.units} from screen center
                    </div>

                    <h4 className="pad-top">Side view</h4>
                    <h5 id="side-view-throw"></h5>
                    <canvas id="canSide" className="chart"></canvas>
                    <h5 id="throw-distance-sim"></h5>
                  </div>
                  <div className="form-wrapper offset-form-wrapper">            
                    <h4 className="offset-hide subtitle">Horizontal offset</h4>
                    <InputRange
                      id="horizontalOffsetSlider"
                      disabled={this.hideOffsets()}
                      maxValue={this.state.maxHoriz}
                      minValue={this.state.minHoriz}
                      value={this.state.horizOff}
                      onChange={this.changeHoriz.bind(this)}  formatLabel={value => ``}
                     />
                    <div className="offset-hide" id="horizOffsetInfo">
                      {(Math.sign(this.state.horizOff) === 1) ? "-" : ""}{Math.abs(this.state.horizOff)}% or {this.state.units === "mm" || this.state.units === "in" ? Math.abs(this.myRoundMM(this.state.unitsMulti * (this.state.horizOff/100.0) * (this.state.width/2) )) : Math.abs(this.myRound(this.state.unitsMulti * (this.state.horizOff/100.0) * (this.state.width/2) ))} {this.state.units} from screen center
                    </div>
                    <h4 className="pad-top">Top view</h4>
                    <h5 id="top-view-width"></h5>
                    <canvas id="canTop" className="chart"></canvas>
                  </div>
                  <div className="form-wrapper hide-me">
                    <h4>Shift limits</h4>
                    <canvas id="canShift" className="chart"></canvas>
                  </div>

                  <div className="form-wrapper hide-me">
                    <h4>Contrast</h4>
                    <canvas id="canContrast" className="chart"></canvas>
                  </div>
                  <div className="form-wrapper hide-me">
                    <div className="label">Contrast on screen</div><div className="value">80.4:1</div>
                  </div>  
                  <div className="form-wrapper hide-me">
                    <div className="label">Lux</div><div className="value">333.3</div>
                  </div>  
                  <div className="form-wrapper hide-me">
                    <div className="label">Image brightness</div><div className="value">106 Nits</div>
                  </div>  
                  <div className="clear"></div> 
                </div>
                <div id="ust-image-container" className="hide-me">
                  <h4 className="pad-top">Side view</h4>
                  <div id="ust-sideView-image" className="form-wrapper ust-image-wrapper"></div>
                  <h4 className="pad-top">Top view</h4>
                  <div id="ust-topView-image"  className="form-wrapper ust-image-wrapper"></div>
                </div>             
              </div>
            </div>
            <div id="lenses" data-tab="3" className="col-md-4 initially-hidden initially-hidden-mobile">
              <div className="appContainer4">
                <h3>Available lens options</h3>
                <p>Select a lens from the list below. Focal length values are subject to a 5% +/- lens tolerance.</p>
                <LensList projector={this.state.projector} lenses={this.state.lenses} units={this.state.units} selectLens={this.selectLens.bind(this)} aspectRatio={this.state.projector.aspectratio} maxDistance={this.state.maxDistance} minDistance={this.state.minDistance} globalMaxDistance={this.state.globalMaxDistance} globalMinDistance={this.state.globalMinDistance} distance={this.state.distance} unitsMulti={this.state.unitsMulti}  unitsInterceptMulti={this.state.unitsInterceptMulti} width={this.state.width} height={this.state.height} orientation={this.state.orientation} length={this.state.projector.length} />
              </div>
            </div>
          </div>         
          <div id="notification-mystique" className="notification hide-me">
            <div className="icon" dangerouslySetInnerHTML={infoIcon()}></div> <p>If you are considering a blended display, we offer <a href="https://www.christiedigital.com/en-us/business/products/mystique" target="_blank">Christie Mystique</a> for camera-based alignment.</p><button onClick={this.hideNotification.bind(this)} dangerouslySetInnerHTML={xIcon()}></button>
          </div>            
          <div id="notification-blended-quality" className="notification hide-me">
            <div className="icon" dangerouslySetInnerHTML={infoIcon()}></div> <p>Disclaimer: Image quality is impacted by brightness, which is directly affected by the image size, number of projectors, projector lumens, blend overlap and the environment.</p><button onClick={this.hideNotification.bind(this)} dangerouslySetInnerHTML={xIcon()}></button>
          </div>          
          <div id="notification-blended-guidance" className="notification hide-me">
            <div className="icon" dangerouslySetInnerHTML={infoIcon()}></div> <p>Disclaimer: Christie provides calculations as general guidance. To confirm accuracy or receive more support for a project, <a href="https://www.christiedigital.com/en-us/about-christie/contact-us" target="_blank">contact your Christie representative</a>.</p><button onClick={this.hideNotification.bind(this)} dangerouslySetInnerHTML={xIcon()}></button>
          </div>
          <div className="row subnav">
            <div className="col-md-6">&copy;2022 Christie Digital Systems USA, Inc. All rights reserved.</div>
            <div className="col-md-6 right text-xs-center subnav-links">
              <a href="https://www.surveymonkey.com/r/2WRPPLL" target="_blank">Give feedback</a>
              <a href="https://www.christiedigital.com/about/">About Christie</a> 
              <a href="https://www.christiedigital.com/about/terms-and-conditions/">Terms &amp; conditions</a> 
              <a href="https://www.christiedigital.com/about/privacy-policy/">Privacy policy</a>
            </div>
          </div>

        </div>
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal">
          <button className="close" onClick={this.closeModal}>
            <svg version="1.1" width="12" height="16" viewBox="0 0 12 16" className="octicon octicon-x" aria-hidden="true">
              <path fill-rule="evenodd" d="M7.48 8l3.75 3.75-1.48 1.48L6 9.48l-3.75 3.75-1.48-1.48L4.52 8 .77 4.25l1.48-1.48L6 6.52l3.75-3.75 1.48 1.48L7.48 8z"></path>
            </svg>
          </button>
          <iframe id="engagement-iframe" style={{height: "500px"},{width: "100%"},{"border-style": "none"}} src={this.state.iframeURL}></iframe>
        </Modal>

        <Modal
          isOpen={this.state.modalIsOpen2}
          onRequestClose={this.closeModal2}
          style={customStyles2}
          contentLabel="Example Modal">
          <button className="close" onClick={this.closeModal2}>
            <svg version="1.1" width="12" height="16" viewBox="0 0 12 16" className="octicon octicon-x" aria-hidden="true">
              <path fill-rule="evenodd" d="M7.48 8l3.75 3.75-1.48 1.48L6 9.48l-3.75 3.75-1.48-1.48L4.52 8 .77 4.25l1.48-1.48L6 6.52l3.75-3.75 1.48 1.48L7.48 8z"></path>
            </svg>
          </button>
          <div id="summary-of-results" className="container containerModal">   
            <div id="summary-of-results2">        
              <img src={exportLogo} alt="logo" width="50%" className="modal-logo" />
              <img src={exportTriangle} alt="branding" width="20%" className="modal-logo" />
              <p className="summary-subtitle">Projector&nbsp;lens&nbsp;calculator</p>
              <h2 className="summary-title modal-title">Summary&nbsp;of&nbsp;results</h2>
              <p className="date-export"></p>
              <div className="row summary-container">
                <div className="col-md-6 projector-details">
                  <h4>{this.state.projector.name}</h4>
                  <p className="summary-partno">{"Part #" + this.state.projector.partno}</p>
                  <div className="form-wrapper">
                    <div className="label">Lumens</div><div className="value">{this.numberWithCommas(this.state.projector.lumensoutput)}</div>
                    <div className="label">Resolution</div><div className="value resolution">{this.state.projector.resolution}</div>
                    <div className="label">Illumination</div><div className="value">{this.state.projector.illumination !== undefined ? this.state.projector.illumination : ""}&nbsp;</div>
                    <div className="label">Aspect ratio</div><div className="value">{this.state.projector.aspectratio !== undefined ? this.state.projector.aspectratio.width + ":" + this.state.projector.aspectratio.height : ""}</div>
                    <div className="label">Series</div><div className="value">{this.state.projector.series}</div>
                    <div className="label">Discontinued</div><div className="value">{this.state.projector.discontinued === "false" ? "No" : "Yes"}</div>
                    <div className={(this.state.blended === undefined || this.state.blended === false) ? "hide-me label" : "label"}>Number of projectors</div><div className={(this.state.blended === undefined || this.state.blended === false) ? "hide-me value" : "value"}>{this.state.noProjectors}</div>
                    <div className={(this.state.blended === undefined || this.state.blended === false) ? "hide-me label" : "label"}>Blend overlap</div><div className={(this.state.blended === undefined || this.state.blended === false) ? "hide-me value" : "value"}>
                      <span className={this.state.units === "ft" ? "hide-me value" : "value"}>{this.myRound(this.state.blendWidth * this.state.unitsMulti)} {this.state.units}</span>
                      <span className={this.state.units !== "ft" ? "hide-me value" : "value"}>{this.feetAndInches(this.state.blendWidth * this.state.unitsMulti)}&nbsp;</span>
                    </div>
                    <div className="clear"></div>
                  </div>  
                </div>
                <div className="col-md-6 lens-details">
                  <LensList2 projector={this.state.projector} lenses={this.getSuitableLenses()} units={this.state.units} selectLens={this.selectLens.bind(this)} aspectRatio={this.state.projector.aspectratio} maxDistance={this.state.maxDistance} minDistance={this.state.minDistance} globalMaxDistance={this.state.globalMaxDistance} globalMinDistance={this.state.globalMinDistance} distance={this.state.distance} unitsMulti={this.state.unitsMulti}  unitsInterceptMulti={this.state.unitsInterceptMulti} width={this.state.width} height={this.state.height} orientation={this.state.orientation} length={this.state.projector.length} />
                </div>
                <div className="col-md-6 installation-specs">
                  <h4>Installation specifications</h4>
                  <div className="form-wrapper">
                    <div className="label">Screen width</div><div className="value">
                      <span className={this.state.units === "ft" || this.state.units === "in" || this.state.units === "mm" ? "hide-me value" : "value"}>{this.myRound(this.state.width * this.state.unitsMulti) + this.state.units}</span>
                      <span className={this.state.units === "ft" && this.state.units !== "in" && this.state.units !== "mm" ? "hide-me value" : "value"}>{this.myRoundMM(this.state.width * this.state.unitsMulti) + this.state.units}</span>
                      <span className={this.state.units !== "ft" ? "hide-me value" : "value"}>{this.feetAndInches(this.state.width * this.state.unitsMulti)}&nbsp;</span>
                    </div>
                    <div className="label">Screen height</div><div className="value">
                      <span className={this.state.units === "ft" || this.state.units === "in" || this.state.units === "mm" ? "hide-me value" : "value"}>{this.myRound(this.state.height * this.state.unitsMulti) + this.state.units}</span>
                      <span className={this.state.units === "ft" && this.state.units !== "in" && this.state.units !== "mm" ? "hide-me value" : "value"}>{this.myRoundMM(this.state.height * this.state.unitsMulti) + this.state.units}</span>
                      <span className={this.state.units !== "ft" ? "hide-me value" : "value"}>{this.feetAndInches(this.state.height * this.state.unitsMulti)}&nbsp;</span>
                    </div>
                    <div className="label">Screen diagonal</div><div className="value">
                      <span className={this.state.units === "ft" || this.state.units === "in" || this.state.units === "mm" ? "hide-me value" : "value"}>{this.myRound(this.state.diagonal * this.state.unitsMulti) + this.state.units}</span>
                      <span className={this.state.units === "ft" && this.state.units !== "in" && this.state.units !== "mm" ? "hide-me value" : "value"}>{this.myRoundMM(this.state.diagonal * this.state.unitsMulti) + this.state.units}</span>
                      <span className={this.state.units !== "ft" ? "hide-me value" : "value"}>{this.feetAndInches(this.state.diagonal * this.state.unitsMulti)}&nbsp;</span>
                    </div>
                    <div className="label">Aspect ratio</div><div className="value">{this.state.aspectwidth !== undefined ? this.state.aspectwidth + ":" + this.state.aspectheight : ""}</div>
                    <div className="label">Projector orientation</div><div className="value">{this.state.orientation === "v" ? "Portrait": "Landscape"}</div>
                    <div className="clear"></div>
                  </div>  
                </div>
              </div>
              <div className="button-container">
                <a href="javascript:exportReport();">
                  <button className="primary-button">Export</button>
                </a>
                <button className="primary-button" onClick={this.showShare.bind(this)}>Share</button>
                <button id="get-a-quote" className="tertiary-button" onClick={this.openModal}>Get a quote</button>
              </div>

              <div id="showSharer" className="hide-me">{window.location.protocol + '//' + window.location.host + window.location.pathname + "?projector=" + (this.state.projector.name) + this.assembleURLComponents()}</div>

            </div>
          </div>
        </Modal>

        <Modal
          isOpen={this.state.modalIsOpen3}
          onRequestClose={this.closeModal3}
          style={customStyles2}
          contentLabel="Example Modal">
          <button className="close" onClick={this.closeModal3}>
            <svg version="1.1" width="12" height="16" viewBox="0 0 12 16" className="octicon octicon-x" aria-hidden="true">
              <path fill-rule="evenodd" d="M7.48 8l3.75 3.75-1.48 1.48L6 9.48l-3.75 3.75-1.48-1.48L4.52 8 .77 4.25l1.48-1.48L6 6.52l3.75-3.75 1.48 1.48L7.48 8z"></path>
            </svg>
          </button>
          <div className="container containerModal">
              <h3 className="modal-title">Help</h3>
              <p>Thank you for using Christie’s projector lens calculator. To get started, choose a projector. If you’ve already selected a projector, you can change your projector selection at any time by clicking the <strong>“Change”</strong> button beside the model name.</p>
              <p>After you’ve selected a projector, follow the steps below:</p>
              <ol type="1">
                  <li>In the first panel, enter any one of the three <strong>Screen dimensions</strong> (the width, height or diagonal of the desired image size) and the other two will be calculated based on the native resolution of the projector selected. Ensure your preferred unit of measurement is selected (ie. mm, cm, m, in or ft).</li>
                  <li>The <strong>Aspect ratio</strong> will default to the native aspect ratio of the projector selected. If your content requires a different aspect ratio, you can change the ratio. The <strong>Screen view</strong> will update to show the size of the projected image in relation to the size of the screen.</li>
                  <li>The projector <strong>Orientation</strong> defaults to Landscape. If you want to use your projector in Portrait mode, select ‘Portrait’ in the orientation section.</li>
                  <li>The “Calculate throw distance” option defaults to on. After entering your screen dimensions, the <strong>Throw distance</strong> field will update with the maximum throw distance (ie. the distance between the projector and the screen) that you can achieve based on the available lenses.<ul>
                      <li>When using the calculator on a desktop device, the Throw distance is found in the middle panel. On a mobile device, the throw distance is listed in the Calculate section.</li>
                      <li>The Lens cards that are found in the right-side panel (on a desktop device) or in the right tab labelled “Lens” (on a mobile device) will indicate if a lens is “In range” or “Out of range”. Select your preferred lens and the throw distance will be recalculated.</li>
                      <li>When you click the <strong>“Select”</strong> button on any lens card, that lens card will move to the top of the list.</li>
                  </ul></li>
                  <li>If you know your preferred throw distance, enter it in the <strong>Throw distance</strong> field.<ul>
                      <li>When a throw distance is entered, the lens options will re-order to show them in order of greatest to least throw distance range.</li>
                      <li>If you know your throw distance and do not have a specific screen size in mind, toggle <strong>Calculate screen dimensions</strong> on.  The maximum Width, Height and Diagonal of the screen/image will be calculated, based on the projector and lens selected.</li>
                  </ul></li>
                  <li>After selecting a lens and setting or calculating the throw distance, you can view the offset simulations. In a desktop view, this is in the middle panel. In a mobile view, find the <strong>offset simulations</strong> at the bottom of the Calculate tab. You can adjust the <strong>Vertical offset</strong> and <strong>Horizontal offset</strong> manually by moving the slider bar for either offset. The offset range is limited by the lens selected.</li>
              </ol>
              <p>After determining the right projector and lens combination for your project, you can request a quote for these items, using the <strong>“Get a quote”</strong> button at the top right of the screen. If you do not require a quote but would like to export or share a summary of the results, click the <strong>Summary of results</strong> button.</p>
              <p>Need more help?  <a href="https://www.christiedigital.com/help-center/contact-us/" target="_blank">Contact a Christie representative</a>. </p>
          </div>
        </Modal>
      </div>
    );
  }
}
var projectors = require.context('./projectors', true);

class ProjectorIcon extends React.Component {
  

    render() {
        let img_src = require(`./projectors/${this.props.picture.toString().toLowerCase()}`)
        return (
            <div className="projectorContainer"><img className="chart2"  src={img_src} alt=""/></div>
        );
    }
}

class ProjectorList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {starredList: []};
  }

  numberWithCommas(x) {
    if(!x)
      return null;
    else
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  selectProjector(index) {
    if (typeof this.props.selectProjector === 'function') {
            this.props.selectProjector(index);
    }
  }
  toggleStar(id) {
    if (typeof this.props.toggleStar === 'function') {
            this.props.toggleStar(id);
    }
  }

  printArray(arr) {
    if(arr) {
      return arr.toString()
    } else {
      return "";
    }
  }

  projectorPartno(x) {
    if(!x) {
      return null;
    } else {
      if (x.indexOf(',') > 0) {
        var arr = x.split(',');
        for (var i = 0; i < arr.length; i++) {
          var part = arr[i];
          part += "\n";
          part += part;
        }
        return part;
      } else {
        return x;
      }
    }
  }

  render() {
    return (

      <div>
      {this.props.projectors.map((item, index) => (
      <div className="form-wrapper" key={item.name}>
        <div className="projector">
          <div id="projector{id}" onClick={this.toggleStar.bind(this, item.name)} className={(item.starred === true) ? "toggleStarSelected" : "toggleStar" } dangerouslySetInnerHTML={starIcon()}></div>
          <ProjectorIcon picture={item.picture} /> 
          <h3>{item.name}</h3>
          <div className="form-wrapper">
            <div className="label">Lumens</div><div className="value">{this.numberWithCommas(item.lumensoutput)}&nbsp;</div>
            <div className="clear"></div>
            <div className="label">Resolution</div><div className="value">{item.resolution}&nbsp;</div>
            <div className="clear"></div>
            <div className="label">Illumination</div><div className="value">{this.printArray(item.illumination)}&nbsp;</div>
            <div className="clear"></div>
            <p></p>
          </div>  
            <a href={item.url} target="_blank"><button className="button1">Specification</button></a>
            <button className="button2" onClick={this.selectProjector.bind(this, index)}>Select</button>
        </div>                
      </div>  
      ))}
      </div>  
    );
  }
}

class LensList extends React.Component {

  constructor(props) {
    super(props);
  }

  myRoundNoDecimals(num) {
    return parseFloat(Number(num).toFixed(0));
  }

  myRound(num) {    
    return parseFloat(Number(num).toFixed(2));
  }

  myRoundMM(num) {
    return parseFloat(Number(num).toFixed(0));
  }

  getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = unescape(value);
    });
    return vars;
  }

  changeOrientation(e) {

    var width = this.state.width;
    var height = this.state.height;
    var awidth = this.state.aspectwidth;
    var aheight = this.state.aspectheight;
    var orientation = e.target.value === "vert" ? "v" : "h";

    this.setState({width:height, height:width, aspectheight:awidth, aspectwidth:aheight, orientation: orientation}, this.postChangeRatio);
  }

  componentDidUpdate(prevProps, prevState) {
    var single = $('#single_selected');
    var blended = $('#blended_selected');
    if (single.hasClass("initial")) {
      single.prop("checked", true);
    }
    var selectNewLens = true;
    var preSelectLens = false;
    var selectLensButtons;
    var lens;
    var isUST = false;
    var ustImageHTML;
    var projectorSeries;
    var simulations;
    var topImage;
    var sideImage;
    var arr;
    var ustValues;
    var diagonal;
    var length;
    var output;
    var ustHTML;
    var uomChecked;
    var value;
    var valueCalc;
    if (window.location.search.length > 0) {
      if (this.getUrlVars().lens !== undefined) {
        var query = this.getUrlVars().lens;
      }
      if (this.getUrlVars().orientation !== undefined) {
        var orientation = this.getUrlVars().orientation;
        if (this.state != null && this.state.orientation !== orientation) {
          this.setState({ orientation: orientation }, () => {
              this.drawSideProjector();
          });
        }
      }
    }
    if (query !== undefined) {

      if (this.props.lenses.length > 0) {
        preSelectLens = true;
        //check for preselect class on lens list
        if (!$(".lenses").hasClass("preselected")) {
          $(".lenses").addClass("preselected");
          preSelectLens = true;
        } else {
            preSelectLens = false;
        }
      }
    }

    if (this.props.lenses.length === 1) {
      selectLensButtons = $(".select-lens-button");
      selectLensButtons.html("Select");
      selectLensButtons.removeClass("lens-selected");
      $("#lens0").addClass("lens-selected");
      $("#lens0").addClass("in-range");
      $("#lens0").html(checkIcon() + '&nbsp;&nbsp;&nbsp;Selected');
      $("#lens0").closest(".lens").addClass("selected");
      $("#lens0").closest(".lens").prependTo($(".lenses"));
      lens = this.props.lenses[0];
      isUST = false;
      if (lens !== undefined) {
        if(lens.type === "UST") {
          if (this.state != null) {
            projectorSeries = this.state.projector.series.toLowerCase().split(" ")[0];
            if (projectorSeries === "ds") {
              projectorSeries = "d";
            }
            simulations = this.state.simulations[projectorSeries];
            if (simulations === undefined) {
              simulations = this.state.simulations.other;
            }
            topImage = "topSim";
            sideImage = "sideSim";
            if(lens.zoomtype === "fixed") {
              isUST = true;
              topImage = simulations[topImage += "Fixed"];
              sideImage = simulations[sideImage += "Fixed"];
              $('#ust-topView-image').html('<img src="'+ topImage +'" />');
              $('#ust-sideView-image').html('<img src="'+ sideImage +'" />');
            } else if (lens.zoomtype === "optional") {
              isUST = true;
              topImage = simulations[topImage += "Optional"];
              sideImage = simulations[sideImage += "Optional"];
              $('#ust-topView-image').html('<img src=' + topImage + ' />');
              $('#ust-sideView-image').html('<img src=' + sideImage + ' />');
            }
          }
        } 
      }
    }
    else {
      if (this.props.setLens === true) {
        $(".lens.selected").removeClass("selected");
        selectLensButtons = $(".select-lens-button");
        selectLensButtons.html("Select");
        selectLensButtons.removeClass("lens-selected");
        $('.offset-hide').removeClass('hide-me');
        $('#offset-canvas-container').removeClass('hide-me');
        $('#offset-canvas-message').addClass('hide-me');
        $('#ust-image-container').addClass('hide-me');
      }
    }
    // preselect lens with share url
    if (preSelectLens && $(".lens.selected").length < 1) {
      var dataIndex = 0;
      for (var i = 0; i < this.props.lenses.length; i++) {
        if (this.props.lenses[i].partno === query) {
          dataIndex = i;
        }
      }
      var lensIndex = "#lens" + dataIndex;
      selectLensButtons = $(".select-lens-button");
      selectLensButtons.html("Select");
      selectLensButtons.removeClass("lens-selected");
      $(lensIndex).addClass("lens-selected");
      $(lensIndex).addClass("sharedUrl");
      $(lensIndex).addClass("in-range");
      $(lensIndex).html(checkIcon() + '&nbsp;&nbsp;&nbsp;Selected');
      $(lensIndex).closest(".lens").addClass("selected");
      $(lensIndex).closest(".lens").prependTo($(".lenses"));

      lens = this.props.lenses[dataIndex];
      isUST = false;

      if (lens !== undefined) {
        if (lens.type === "UST") {
          if (this.state != null) {
            projectorSeries = this.state.projector.series.toLowerCase().split(" ")[0];
            if (projectorSeries === "ds") {
              projectorSeries = "d";
            }
            simulations = this.state.simulations[projectorSeries];
            if (simulations === undefined) {
              simulations = this.state.simulations.other;
            }
            topImage = "topSim";
            sideImage = "sideSim";
            $(".ust-values").removeClass("hide-me");
            if(lens.zoomtype === "fixed") {
              isUST = true;
              topImage = simulations[topImage += "Fixed"];
              sideImage = simulations[sideImage += "Fixed"];
              $('#ust-topView-image').html('<img src ="'+ topImage +'" />');
              $('#ust-sideView-image').html('<img src ="'+ sideImage +'" />');
            } else if (lens.zoomtype === "optional") {
              isUST = true;
              topImage = simulations[topImage += "Optional"];
              sideImage = simulations[sideImage += "Optional"];
              $('#ust-topView-image').html('<img src =' + topImage + ' />');
              $('#ust-sideView-image').html('<img src =' + sideImage + ' />');
            } else {
              $(".ust-values").addClass("hide-me");
              $("#throwDistanceCard").removeClass("hide-me");
              $("#single-projector").removeClass("hide-me");
            }
          } else {
            $(".ust-values").addClass("hide-me");
            $("#throwDistanceCard").removeClass("hide-me");
            $("#single-projector").removeClass("hide-me");
          }
        } else {
        }
      }
    }

    // only once lens and its UST (fixed/optional);
    if (this.props.lenses.length > 0) {
      if ((this.props.lenses[0].type === "UST" && this.props.lenses[0].zoomtype === "fixed") || (this.props.lenses[0].type === "UST" && this.props.lenses[0].zoomtype === "optional")) {
        if (this.props.lenses.length === 1) {
          $('#throwDistanceCard').addClass("hide-me");
          $('.offset-form-wrapper').addClass("to-hide");
          $('.offset-hide').addClass('hide-me');
          $('#offset-canvas-container').addClass('hide-me');
          $('#ust-image-container').removeClass('hide-me');
          lens = this.props.lenses[0];
          ustValues = $(".ust-values");
          arr = [];
          if (lens.outputs && ustValues.hasClass("hide-me")) {
            if (lens.zoomtype === "fixed" || lens.zoomtype === "optional") {
              ustValues.removeClass("hide-me");
              if ($(".ust-values").hasClass("hide-me")) {
              } else {
                $("#single-projector").removeClass("hide-me");
              }
            }
          } else if (!lens.outputs || lens.zoomtype === "snorkel_left" || lens.zoomtype === "snorkel_right") {
            ustValues.addClass("hide-me");
          }
          if (lens.outputs) {
            for (var a = 0; a < lens.outputs.length; a++) {
              if (lens.outputs[a]) {
                diagonal = Number($('#screenDiagonal').val());
                length = this.props.length;
                output = this.valueCalculator(lens.type, lens.zoomtype, lens.outputs[a], diagonal, length, uom);
                if (output != null) {
                  if (Array.isArray(output)) {
                    arr = $.merge(arr, output);
                  } else {
                    arr.push(output);
                  }
                }
              }
            }
          }

          ustHTML = "<h3>Values</h3><div class='form-wrapper'>";
          for (var x = 0; x < arr.length; x++) {
            uomChecked = $('input[name="units"]:checked').val();
            value = Number(arr[x].value);
            valueCalc =  this.myRound(value * uom) + " " + uomChecked;
            if (this.state !== null) {
              if (uomChecked === "mm" || this.state.units === "in")
                valueCalc = this.myRoundMM(value * uom) + " " + uomChecked;
            } else {
              if (uomChecked === "mm" || uomChecked === "in")
                valueCalc = this.myRoundMM(value * uom) + " " + uomChecked;
            }
            if (uomChecked === "ft")
              valueCalc = this.feetAndInches(value * uom)

            ustHTML +=  "<div class='label'>" + arr[x].name + "</div>" + 
                        "<div class='value'>" + 
                          "<span> " + valueCalc + "</span>" +
                        "</div>";
          }

          ustHTML += "<div class='clear'></div>";

          ustValues.html(ustHTML);
        }
      }
    } 

    // add values to UST card when UST lens is pre-selected
    var selectedLens = $(".lens.selected");
    ustValues = $(".ust-values");
    var uom = Number(this.props.unitsMulti);
    arr = [];
    if (selectedLens.length > 0) {
      lens = this.props.lenses[Number(selectedLens.attr("data-index"))];
      if (lens.outputs && ustValues.hasClass("hide-me")) {
        if (lens.zoomtype === "fixed" || lens.zoomtype === "optional") {
          if (!$("#single-projector").hasClass("hide-me")) {
            ustValues.removeClass("hide-me");
          }
        }
      } else if (!lens.outputs || lens.zoomtype === "snorkel_left" || lens.zoomtype === "snorkel_right") {
        ustValues.addClass("hide-me");
      }
      if (lens.outputs) {
        for (var b = 0; b < lens.outputs.length; b++) {
          if (lens.outputs[b]) {
            diagonal = Number($('#screenDiagonal').val());

            length = this.props.length;
            output = this.valueCalculator(lens.type, lens.zoomtype, lens.outputs[b], diagonal, length, uom);
            if (output != null) {
              if (Array.isArray(output)) {
                arr = $.merge(arr, output);
              } else {
                arr.push(output);
              }
            }
          }
        }
      }

      ustHTML = "<h3>Values</h3><div class='form-wrapper'>";
      for (var c = 0; c < arr.length; c++) {
        uomChecked = $('input[name="units"]:checked').val();
        value = Number(arr[c].value);
        valueCalc =  this.myRound(value * uom) + " " + uomChecked;
        if (this.state !== null) {

          if (uomChecked === "mm" || this.state.units === "in") {
            valueCalc = this.myRoundMM(value * uom) + " " + uomChecked;
          }
        } else {
          if (uomChecked === "mm" || uomChecked === "in") {
            valueCalc = this.myRoundMM(value * uom) + " " + uomChecked;
          }
        }
        if (uomChecked === "ft")
          valueCalc = this.feetAndInches(value * uom);

        ustHTML +=  "<div class='label'>" + arr[c].name + "</div>" + 
                    "<div class='value'>" + 
                      "<span> " + valueCalc + "</span>" +
                    "</div>";
      }

      ustHTML += "<div class='clear'></div>";
      ustValues.html(ustHTML);
    } else {
      ustValues.addClass("hide-me");
    }
  }

  valueCalculator(lensType, zoomType, output, diagonal, length, uom) {
    var inchMulti = 2.54;
    var inchDiv = 0.3937008;
    var value;
    if (lensType === "UST") {
      if (zoomType === "fixed") {
        if (output.name.startsWith("L")) {
          var ML = Number(output.ML);
          var BL = Number(output.BL);
          value = (((diagonal / inchMulti) * ML) - BL) / 10;
          var L1 = Number(length) + value;

          return [
            {
              "name": "L1",
              "value": L1
            },
            {
              "name": output.name,
              "value": value
            }
          ];
        }
        if (output.name.startsWith("A")) {
          var MA = Number(output.MA);
          var BA = Number(output.BA);
          value = (((diagonal / inchMulti) * MA) + BA) / 10;
          return {
            "name": output.name,
            "value": value
          }
        }
      } else if (zoomType === "optional") {
        var K1 = Number(output.K1);
        var K2 = Number(output.K2);
        //convert diagonal to inches
        var inchDiagonal = diagonal * inchDiv;
        //run calculation
        var meterValue = ((inchDiagonal * K1)  + (K2));
        //result is in meters (convert to cm) 
        value = meterValue * 100;

        return {
          "name": output.name,
          "value": value
        }
      }
    }
  }

  selectLens(index, name, item, e) {

    if (typeof this.props.selectLens === 'function') {
      this.props.selectLens(name, item, index);
    }

  }

  calculateDistance(slope, val, intercept) {
    return ((Number(slope) * Number(val)) + Number(intercept));
  }

  getWidthFromThrowDistance(lens, type) {
    var width = 0;
    var intercept;
    var slope;

    if (lens.formula !== undefined) {

      if (Array.isArray(lens.formula)) {
        var formula = lens.formula.find(x => x.type === type);
        if (formula !== undefined) {
          intercept = Number(formula.intercept);
          slope = Number(formula.slope); 
        } else {
          intercept = Number(lens.formula[0].intercept);
          slope = Number(lens.formula[0].slope);
        } 
      }
      else {
        intercept = Number(lens.formula.intercept);
        slope = Number(lens.formula.slope);  
      }

      var distance = this.props.distance;
      width = (Number(distance) - intercept) / slope;
      return width;
    }
  }

  getDiagonalFromDistance(lens, aspectRatio, type) {
    var width = this.getWidthFromThrowDistance(lens, type);

    var diagonal = (width * Math.sqrt(Math.pow(Number(aspectRatio.width), 2) + Math.pow(Number(aspectRatio.height), 2)) / Number(aspectRatio.width));
    return diagonal;
  }

  getHeightFromThrowDistance(lens, aspectRatio, type) {
    var diagonal = this.getDiagonalFromDistance(lens, aspectRatio, type);

    var height = (diagonal * Number(aspectRatio.height) / Math.sqrt(Math.pow(Number(aspectRatio.width), 2) + Math.pow(Number(aspectRatio.height), 2)));
    return height;
  }

  minRange(item, fixedAdjustment = 0.1) {
    var val = this.props.width;
    var slope2;
    var intercept2;
    if (this.props.orientation === "v")
      val = this.props.height;

    if (item.formula !== undefined) {
      if(item.formula.length) {
        slope2 = Number(item.formula[0].slope);
        intercept2 = Number(item.formula[0].intercept);
        if (item.formula[0].type !== "fixed")
          return this.calculateDistance(slope2, val, intercept2);
        else
          return this.calculateDistance(slope2, val, intercept2) - fixedAdjustment;
      } else {
        slope2 = Number(item.formula.slope);
        intercept2 = Number(item.formula.intercept);
        if (item.formula.type !== "fixed")
          return this.calculateDistance(slope2, val, intercept2);
        else
          return this.calculateDistance(slope2, val, intercept2) - fixedAdjustment;
      }
    }
  }

  maxRange(item, fixedAdjustment = 0.1) {
    var val = this.props.width;
    var slope2;
    var intercept2;
    if (this.props.orientation === "v")
      val = this.props.height;

    if (item.formula !== undefined) {
      if(item.formula.length && item.formula[0].type !== "fixed") {
        slope2 = Number(item.formula[1].slope);
        intercept2 = Number(item.formula[1].intercept);
        if (item.formula[1].type !== "fixed")
          return this.calculateDistance(slope2, val, intercept2);
        else
          return this.calculateDistance(slope2, val, intercept2) + fixedAdjustment;
      } else {
        if (item.formula.slope !== undefined) {
          slope2 = Number(item.formula.slope);
          intercept2 = Number(item.formula.intercept);
        } else {
            slope2 = Number(item.formula[0].slope);
            intercept2 = Number(item.formula[0].intercept);
        }
        if (item.formula.type !== "fixed")
          return this.calculateDistance(slope2, val, intercept2);
        else
          return this.calculateDistance(slope2, val, intercept2) + fixedAdjustment;
      }
    }
  }

  getFeetAndInches(decimal) {
    var feet = Math.floor(decimal);
    var inches = (Math.round(12 * (decimal - Math.floor(decimal))));
    inches = this.myRoundNoDecimals(inches);
    if (inches === 12) {
      inches = 0;
      feet++;
    }
    return [feet, inches];
  }

  feetAndInches(decimal) {
    var ftAndIn = this.getFeetAndInches(decimal);
    return ftAndIn[0] +
      " ft " +
      ftAndIn[1] +
      ' in';
  }

  changeLensInput(item, index) {
    var minRange = this.minRange(item);
    var maxRange = this.maxRange(item);
    var inRange = false;
    var notFixedUST = true;
    if (item.type === "UST") {
      if (item.zoomtype !== "snorkel_left" && item.zoomtype !== "snorkel_right") {
        notFixedUST = false;
      }
    }
    if (notFixedUST) {
      if (this.props.distance >= minRange && this.props.distance <= maxRange)
      {
        inRange = true;
      } else {
        inRange = false;
      }
    } else {
      // treat ust lens differently
      if (this.props.units !== 'ft') {
        var diagonalCurrent = Number($('#manualDiagonal').val());
      } else {
        var diagonalCurrent = Number($('#manualDiagonal').val()) + (Number($('#manualDiagonalInInput').val()) / 12);
      }
      var diagonalMin = item.diagonal.min * this.props.unitsMulti;
      var diagonalMax = item.diagonal.max * this.props.unitsMulti;
      diagonalMin = this.myRound(diagonalMin);
      if (diagonalCurrent < diagonalMin || diagonalCurrent > diagonalMax) {
        inRange = false;
      } else {
        inRange = true;
      }
    }

    if (inRange) {
      $(".lenses [data-index=" + index + "]").removeClass("out-of-range");
      $(".lenses [data-index=" + index + "]").addClass("in-range");
    }
    else {
      $(".lenses [data-index=" + index + "]").addClass("out-of-range");
      $(".lenses [data-index=" + index + "]").removeClass("in-range");
    }

    // if ($('.lens.ustInc').length > 0) {
    //   $(this).removeClass("in-range");
    //   $(this).addClass("out-of-range");
    //   $(this).find('button').attr("disabled");
    // }
  }

  render() {
    return (

      <div className="lenses">
      {this.props.lenses.map((item, index) => (

          <div className="lens" key={index} data-index={index}>
            <h3>{item.name}</h3><p className="in-range-label">In range</p><p className="out-of-range-label">Out of range</p>
            <p className="clear summary-partno">Part #{item.partno}</p>
            {/*<p className={item.type === "UST" && item.partno === "140-133108-XX" ? "ust-disclaimer" : "hide-me"}>Calculations for this UST lens are available by contacting Christie <a href="mailto:support.americas@christiedigital.com">Technical Support</a>. Screen size ranges from 120" to ~350" (tolerance +/- 3%).</p>*/}
            <div className={item.type === "UST" && (item.zoomtype === "optional" || item.zoomtype === "fixed") ? "hide-me form-wrapper" : "form-wrapper"}>
              <div className={item.type === "UST" && (item.zoomtype === "optional" || item.zoomtype === "fixed") ? "hide-me label" : "label"}>Vertical offset</div>
              <div className={item.type === "UST" && (item.zoomtype === "optional" || item.zoomtype === "fixed") ? "hide-me value" : "value"}>{item.offset.vertical}&nbsp;</div>
              <div className={item.type === "UST" && (item.zoomtype === "optional" || item.zoomtype === "fixed") ? "hide-me label" : "label"}>Horizontal offset</div>
              <div className={item.type === "UST" && (item.zoomtype === "optional" || item.zoomtype === "fixed") ? "hide-me value" : "value"}>{item.offset.horizontal}&nbsp;</div>
              <div className={item.type === "UST" && (item.zoomtype === "optional" || item.zoomtype === "fixed") ? "hide-me label" : "label"}>{item.zoomtype === "fixed" || (item.formula !== undefined && item.formula.type === "fixed") || item.zoomtype === "snorkel_left" || item.zoomtype === "snorkel_right" ? "Image width" : "Image width range"}</div>
              <div className={item.type === "UST" && (item.zoomtype === "optional" || item.zoomtype === "fixed") ? "hide-me value" : "value"}>
                <span className={(this.props.units === "ft" || this.props.units === "mm" || this.props.units === "in" || item.zoomtype === "fixed" || (item.formula !== undefined && item.formula.type === "fixed") || item.zoomtype === "snorkel_left" || item.zoomtype === "snorkel_right") ? "hide-me" : ""}>{this.myRound(this.getWidthFromThrowDistance(item, "max") * this.props.unitsMulti)}{this.props.units}&mdash;</span>
                <span className={(this.props.units === "ft" || (this.props.units !== "mm" && this.props.units !== "in") || item.zoomtype === "fixed" || (item.formula !== undefined && item.formula.type === "fixed") || item.zoomtype === "snorkel_left" || item.zoomtype === "snorkel_right") ? "hide-me" : ""}>{this.myRoundMM(this.getWidthFromThrowDistance(item, "max") * this.props.unitsMulti)}{this.props.units}&mdash;</span>
                <span className={(this.props.units !== "ft" || item.zoomtype === "fixed" || (item.formula !== undefined && item.formula.type === "fixed") || item.zoomtype === "snorkel_left" || item.zoomtype === "snorkel_right") ? "hide-me" : ""}>{this.feetAndInches(this.getWidthFromThrowDistance(item, "max") * this.props.unitsMulti)}&mdash;</span>
                <span className={(this.props.units === "ft" || this.props.units === "mm" || this.props.units === "in") ? "hide-me" : ""}>{this.myRound(this.getWidthFromThrowDistance(item, "min") * this.props.unitsMulti)}{this.props.units}</span>
                <span className={(this.props.units === "ft" || (this.props.units !== "mm" && this.props.units !== "in")) ? "hide-me" : ""}>{this.myRoundMM(this.getWidthFromThrowDistance(item, "min") * this.props.unitsMulti)}{this.props.units}</span>
                <span className={(this.props.units !== "ft") ? "hide-me" : ""}>{this.feetAndInches(this.getWidthFromThrowDistance(item, "min") * this.props.unitsMulti)}</span>
              </div>
              <div className="clear"></div>
              <div className={item.type === "UST" && (item.zoomtype === "optional" || item.zoomtype === "fixed") ? "hide-me label" : "label"}>{item.zoomtype === "fixed" || (item.formula !== undefined && item.formula.type === "fixed") || item.zoomtype === "snorkel_left" || item.zoomtype === "snorkel_right" ? "Image height" : "Image height range"}</div>
              <div className={item.type === "UST" && (item.zoomtype === "optional" || item.zoomtype === "fixed") ? "hide-me value" : "value"}>
                <span className={(this.props.units === "ft" || this.props.units === "mm" || this.props.units === "in" || item.zoomtype === "fixed" || (item.formula !== undefined && item.formula.type === "fixed") || item.zoomtype === "snorkel_left" || item.zoomtype === "snorkel_right") ? "hide-me" : ""}>{this.myRound(this.getHeightFromThrowDistance(item, this.props.aspectRatio, "max") * this.props.unitsMulti)}{this.props.units}&mdash;</span>
                <span className={((this.props.units !== "mm" && this.props.units !== "in") || item.zoomtype === "fixed" || (item.formula !== undefined && item.formula.type === "fixed") || item.zoomtype === "snorkel_left" || item.zoomtype === "snorkel_right") ? "hide-me" : ""}>{this.myRoundMM(this.getHeightFromThrowDistance(item, this.props.aspectRatio, "max") * this.props.unitsMulti)}{this.props.units}&mdash;</span>
                <span className={(this.props.units !== "ft" || item.zoomtype === "fixed" || (item.formula !== undefined && item.formula.type === "fixed") || item.zoomtype === "snorkel_left" || item.zoomtype === "snorkel_right") ? "hide-me" : ""}>{this.feetAndInches(this.getHeightFromThrowDistance(item, this.props.aspectRatio, "max") * this.props.unitsMulti)}&mdash;</span>
                <span className={(this.props.units === "ft" || this.props.units === "mm" || this.props.units === "in") ? "hide-me" : ""}>{this.myRound(this.getHeightFromThrowDistance(item, this.props.aspectRatio, "min") * this.props.unitsMulti)}{this.props.units}</span>
                <span className={(this.props.units !== "mm" && this.props.units !== "in") ? "hide-me" : ""}>{this.myRoundMM(this.getHeightFromThrowDistance(item, this.props.aspectRatio, "min") * this.props.unitsMulti)}{this.props.units}</span>
                <span className={(this.props.units !== "ft") ? "hide-me" : ""}>{this.feetAndInches(this.getHeightFromThrowDistance(item, this.props.aspectRatio, "min") * this.props.unitsMulti)}</span>
              </div>
              <div className="clear"></div>
              <div className={item.type === "UST" && (item.zoomtype === "optional" || item.zoomtype === "fixed") ? "hide-me label" : "label"}>{item.zoomtype === "fixed" || (item.formula !== undefined && item.formula.type === "fixed") || item.zoomtype === "snorkel_left" || item.zoomtype === "snorkel_right" ? "Image diagonal" : "Image diagonal range"}</div>
              <div className={item.type === "UST" && (item.zoomtype === "optional" || item.zoomtype === "fixed") ? "hide-me value" : "value"}>
                <span className={(this.props.units === "ft" || this.props.units === "mm" || this.props.units === "in" || item.zoomtype === "fixed" || (item.formula !== undefined && item.formula.type === "fixed") || item.zoomtype === "snorkel_left" || item.zoomtype === "snorkel_right") ? "hide-me" : ""}>{this.myRound(this.getDiagonalFromDistance(item, this.props.aspectRatio, "max") * this.props.unitsMulti)}{this.props.units}&mdash;</span>
                <span className={((this.props.units !== "mm" && this.props.units !== "in") || item.zoomtype === "fixed" || (item.formula !== undefined && item.formula.type === "fixed") || item.zoomtype === "snorkel_left" || item.zoomtype === "snorkel_right") ? "hide-me" : ""}>{this.myRoundMM(this.getDiagonalFromDistance(item, this.props.aspectRatio, "max") * this.props.unitsMulti)}{this.props.units}&mdash;</span>
                <span className={(this.props.units !== "ft" || item.zoomtype === "fixed" || (item.formula !== undefined && item.formula.type === "fixed") || item.zoomtype === "snorkel_left" || item.zoomtype === "snorkel_right") ? "hide-me" : ""}>{this.feetAndInches(this.getDiagonalFromDistance(item, this.props.aspectRatio, "max") * this.props.unitsMulti)}&mdash;</span>
                <span className={(this.props.units === "ft" || this.props.units === "mm" || this.props.units === "in") ? "hide-me" : ""}>{this.myRound(this.getDiagonalFromDistance(item, this.props.aspectRatio, "min") * this.props.unitsMulti)}{this.props.units}</span>
                <span className={(this.props.units !== "mm" && this.props.units !== "in") ? "hide-me" : ""}>{this.myRoundMM(this.getDiagonalFromDistance(item, this.props.aspectRatio, "min") * this.props.unitsMulti)}{this.props.units}</span>
                <span className={(this.props.units !== "ft") ? "hide-me" : ""}>{this.feetAndInches(this.getDiagonalFromDistance(item, this.props.aspectRatio, "min") * this.props.unitsMulti)}</span>
              </div>
              <div className="clear"></div>
              <InputRange
                className="globalRange"
                maxValue={(this.maxRange(item))}
                minValue={(this.minRange(item))}
                value={
                  {
                    min:this.minRange(item),
                    max:this.maxRange(item),
                    value:(this.props.distance) 
                  }
                }
                formatLabel={value => ``}
                onChange={this.changeLensInput(item, index)}
               />     
              <InputRange
                maxValue={this.myRound(this.maxRange(item))}
                minValue={this.myRound(this.minRange(item))}
                value={this.props.distance}
                className="irProjectorDistance"
                formatLabel={value => ``}
                onChange={value => this.setState({ value })}
               />
              <div className="clear"></div>
              <div>
                <div className="clear"></div>
                <div className={item.type === "UST" && (item.zoomtype === "optional" || item.zoomtype === "fixed") ? "hide-me label" : "label"}>
                  <span className={(this.props.units === "ft" || this.props.units === "mm" || this.props.units === "in") ? "hide-me" : ""}>{this.myRound(this.minRange(item, 0) * this.props.unitsMulti)}{this.props.units}</span>
                  <span className={(this.props.units !== "mm" && this.props.units !== "in") ? "hide-me" : ""}>{this.myRoundMM(this.minRange(item, 0) * this.props.unitsMulti)}{this.props.units}</span>
                  <span className={(this.props.units !== "ft") ? "hide-me" : ""}>{this.feetAndInches(this.minRange(item, 0) * this.props.unitsMulti)}</span>
                  <span className="lensRangeLabel">Lens range</span>
                </div>
                <div className={item.type === "UST" && (item.zoomtype === "optional" || item.zoomtype === "fixed") ? "hide-me value" : "value"}>
                  <span className={(this.props.units === "ft" || this.props.units === "mm" || this.props.units === "in") ? "hide-me" : ""}>{this.myRound(this.maxRange(item, 0) * this.props.unitsMulti)}{this.props.units}</span>
                  <span className={(this.props.units !== "mm" && this.props.units !== "in") ? "hide-me" : ""}>{this.myRoundMM(this.maxRange(item, 0) * this.props.unitsMulti)}{this.props.units}</span>
                  <span className={(this.props.units !== "ft") ? "hide-me" : ""}>{this.feetAndInches(this.maxRange(item, 0) * this.props.unitsMulti)}</span>
                </div>  
              </div>            
              <div className="clear"></div>
            </div>  
              <a href={item.url} target="_blank"><button className="button1">Specification</button></a>
              <button id={"lens" + index} className="button2 select-lens-button" onClick={this.selectLens.bind(this, index, item.name, item)}>Select</button>
          </div>

        ))}
      </div>  
    );
  }
}

class LensList2 extends React.Component {

  constructor(props) {
    super(props);
  }

  myRoundNoDecimals(num) {
    return parseFloat(Number(num).toFixed(0));
  }

  myRound(num) {    
    return parseFloat(Number(num).toFixed(2));
  }

  myRoundMM(num) {
    return parseFloat(Number(num).toFixed(0));
  }

  calculateDistance(slope, val, intercept) {
    return ((Number(slope) * Number(val)) + Number(intercept));
  }

  minRange(item) {
    var val = this.props.width;
    var slope2;
    var intercept2;
    if (this.props.orientation === "v")
      val = this.props.height;

    if (item.formula !== undefined) {
      if(item.formula.length) {
        slope2 = Number(item.formula[0].slope);
        intercept2 = Number(item.formula[0].intercept);
        return this.calculateDistance(slope2, val, intercept2);
      } else {
        slope2 = Number(item.formula.slope);
        intercept2 = Number(item.formula.intercept);
        return this.calculateDistance(slope2, val, intercept2);
      }
    }
  }

  maxRange(item) {
    var val = this.props.width;
    var slope2;
    var intercept2;
    if (this.props.orientation === "v")
      val = this.props.height;

    if (item.formula !== undefined) {
      if(item.formula.length > 1) {
        slope2 = Number(item.formula[1].slope);
        intercept2 = Number(item.formula[1].intercept);
        return this.calculateDistance(slope2, val, intercept2);
      } else {
        if (item.formula.slope !== undefined) {
          slope2 = Number(item.formula.slope);
          intercept2 = Number(item.formula.intercept);  
        } else {
          slope2 = Number(item.formula[0].slope);
          intercept2 = Number(item.formula[0].intercept);
        }
        return this.calculateDistance(slope2, val, intercept2);
      }
    }
  }

  getWidthFromThrowDistance(lens, type) {
    var width = 0;
    var intercept;
    var slope;

    if (lens.formula !== undefined) {

      if (Array.isArray(lens.formula)) {
        var formula = lens.formula.find(x => x.type === type);
        if (formula !== undefined) {
          intercept = Number(formula.intercept);
          slope = Number(formula.slope);
        } else {
          intercept = Number(lens.formula[0].intercept);
          slope = Number(lens.formula[0].slope);
        }
      }
      else {
        intercept = Number(lens.formula.intercept);
        slope = Number(lens.formula.slope); 
      }

      var distance = this.props.distance;
      width = (Number(distance) - intercept) / slope;

      return width;
    }
  }

  getDiagonalFromDistance(lens, aspectRatio, type) {
    var width = this.getWidthFromThrowDistance(lens, type);

    var diagonal = (width * Math.sqrt(Math.pow(Number(aspectRatio.width), 2) + Math.pow(Number(aspectRatio.height), 2)) / Number(aspectRatio.width));

    return diagonal;
  }

  getHeightFromThrowDistance(lens, aspectRatio, type) {
    var diagonal = this.getDiagonalFromDistance(lens, aspectRatio, type);

    var height = (diagonal * Number(aspectRatio.height) / Math.sqrt(Math.pow(Number(aspectRatio.width), 2) + Math.pow(Number(aspectRatio.height), 2)));
    return height;
  }

  getFeetAndInches(decimal) {
    var feet = Math.floor(decimal);
    var inches = (Math.round(12 * (decimal - Math.floor(decimal))));
    inches = this.myRoundNoDecimals(inches);
    if (inches === 12) {
      inches = 0;
      feet++;
    }
    return [feet, inches];
  }

  feetAndInches(decimal) {
    var ftAndIn = this.getFeetAndInches(decimal);
    return ftAndIn[0] +
      " ft " +
      ftAndIn[1] +
      ' in';
  }

  render() {
    return (
      <div>
      {this.props.lenses.map((item, index) => (
          <div className="lens2" key={index}>
            <h4>{item.name}</h4>
            <p>{"Part #" + item.partno}</p>
            <div className="form-wrapper">
              <div className={item.type === "UST" && (item.zoomtype === "optional" || item.zoomtype === "fixed") ? "hide-me label" : "label"}>Vertical offset</div><div className={item.type === "UST" && (item.zoomtype === "optional" || item.zoomtype === "fixed") ? "hide-me label" : "value"}>{item.offset.vertical}</div>
              <div className={item.type === "UST" && (item.zoomtype === "optional" || item.zoomtype === "fixed") ? "hide-me label" : "label"}>Horizontal offset</div><div className={item.type === "UST" && (item.zoomtype === "optional" || item.zoomtype === "fixed") ? "hide-me label" : "value"}>{item.offset.horizontal}</div>
              <div className={item.type === "UST" && (item.zoomtype === "optional" || item.zoomtype === "fixed") ? "hide-me label" : "label"}>{item.zoomtype === "fixed" || (item.formula !== undefined && item.formula.type === "fixed") ? "Image width" : "Image width range"}</div>
              <div className={item.type === "UST" && (item.zoomtype === "optional" || item.zoomtype === "fixed") ? "hide-me value" : "value"}>
                <span className={(this.props.units === "ft" || this.props.units === "mm" || this.props.units === "in" || item.zoomtype === "fixed" || (item.formula !== undefined && item.formula.type === "fixed")) ? "hide-me" : ""}>{this.myRound(this.getWidthFromThrowDistance(item, "max") * this.props.unitsMulti)}{this.props.units}&mdash;</span>
                <span className={((this.props.units !== "mm" && this.props.units !== "in") || item.zoomtype === "fixed" || (item.formula !== undefined && item.formula.type === "fixed")) ? "hide-me" : ""}>{this.myRoundMM(this.getWidthFromThrowDistance(item, "max") * this.props.unitsMulti)}{this.props.units}&mdash;</span>
                <span className={(this.props.units !== "ft" || item.zoomtype === "fixed" || (item.formula !== undefined && item.formula.type === "fixed")) ? "hide-me" : ""}>{this.feetAndInches(this.getWidthFromThrowDistance(item, "max") * this.props.unitsMulti)}&mdash;</span>
                <span className={(this.props.units === "ft" || this.props.units === "mm" || this.props.units === "in") ? "hide-me" : ""}>{this.myRound(this.getWidthFromThrowDistance(item, "min") * this.props.unitsMulti)}{this.props.units}</span>
                <span className={(this.props.units !== "mm" && this.props.units !== "in") ? "hide-me" : ""}>{this.myRoundMM(this.getWidthFromThrowDistance(item, "min") * this.props.unitsMulti)}{this.props.units}</span>
                <span className={(this.props.units !== "ft") ? "hide-me" : ""}>{this.feetAndInches(this.getWidthFromThrowDistance(item, "min") * this.props.unitsMulti)}</span>
              </div>
              <div className={item.type === "UST" && (item.zoomtype === "optional" || item.zoomtype === "fixed") ? "hide-me label" : "label"}>{item.zoomtype === "fixed" || (item.formula !== undefined && item.formula.type === "fixed") ? "Image height" : "Image height range"}</div>
              <div className={item.type === "UST" && (item.zoomtype === "optional" || item.zoomtype === "fixed") ? "hide-me value" : "value"}>
                <span className={(this.props.units === "ft" || this.props.units === "mm" || this.props.units === "in" || (item.formula !== undefined && item.formula.type === "fixed")) ? "hide-me" : ""}>{this.myRound(this.getHeightFromThrowDistance(item, this.props.aspectRatio, "max") * this.props.unitsMulti)}{this.props.units}&mdash;</span>
                <span className={((this.props.units !== "mm" && this.props.units !== "in") || item.zoomtype === "fixed" || (item.formula !== undefined && item.formula.type === "fixed")) ? "hide-me" : ""}>{this.myRoundMM(this.getHeightFromThrowDistance(item, this.props.aspectRatio, "max") * this.props.unitsMulti)}{this.props.units}&mdash;</span>
                <span className={(this.props.units !== "ft" || item.zoomtype === "fixed" || (item.formula !== undefined && item.formula.type === "fixed")) ? "hide-me" : ""}>{this.feetAndInches(this.getHeightFromThrowDistance(item, this.props.aspectRatio, "max") * this.props.unitsMulti)}&mdash;</span>
                <span className={(this.props.units === "ft" || this.props.units === "mm" || this.props.units === "in") ? "hide-me" : ""}>{this.myRound(this.getHeightFromThrowDistance(item, this.props.aspectRatio, "min") * this.props.unitsMulti)}{this.props.units}</span>
                <span className={(this.props.units !== "mm" && this.props.units !== "in") ? "hide-me" : ""}>{this.myRoundMM(this.getHeightFromThrowDistance(item, this.props.aspectRatio, "min") * this.props.unitsMulti)}{this.props.units}</span>
                <span className={(this.props.units !== "ft") ? "hide-me" : ""}>{this.feetAndInches(this.getHeightFromThrowDistance(item, this.props.aspectRatio, "min") * this.props.unitsMulti)}</span>
              </div>
              <div className={item.type === "UST" && (item.zoomtype === "optional" || item.zoomtype === "fixed") ? "hide-me label" : "label"}>{item.zoomtype === "fixed" || (item.formula !== undefined && item.formula.type === "fixed") ? "Image diagonal" : "Image diagonal range"}</div>
              <div className={item.type === "UST" && (item.zoomtype === "optional" || item.zoomtype === "fixed") ? "hide-me value" : "value"}>
                <span className={(this.props.units === "ft" || this.props.units === "mm" || this.props.units === "in" || item.zoomtype === "fixed" || (item.formula !== undefined && item.formula.type === "fixed")) ? "hide-me" : ""}>{this.myRound(this.getDiagonalFromDistance(item, this.props.aspectRatio, "max") * this.props.unitsMulti)}{this.props.units}&mdash;</span>
                <span className={((this.props.units !== "mm" && this.props.units !== "in") || item.zoomtype === "fixed" || (item.formula !== undefined && item.formula.type === "fixed")) ? "hide-me" : ""}>{this.myRoundMM(this.getDiagonalFromDistance(item, this.props.aspectRatio, "max") * this.props.unitsMulti)}{this.props.units}&mdash;</span>
                <span className={(this.props.units !== "ft" || item.zoomtype === "fixed" || (item.formula !== undefined && item.formula.type === "fixed")) ? "hide-me" : ""}>{this.feetAndInches(this.getDiagonalFromDistance(item, this.props.aspectRatio, "max") * this.props.unitsMulti)}&mdash;</span>
                <span className={(this.props.units === "ft" || this.props.units === "mm" || this.props.units === "in") ? "hide-me" : ""}>{this.myRound(this.getDiagonalFromDistance(item, this.props.aspectRatio, "min") * this.props.unitsMulti)}{this.props.units}</span>
                <span className={(this.props.units !== "mm" && this.props.units !== "in") ? "hide-me" : ""}>{this.myRoundMM(this.getDiagonalFromDistance(item, this.props.aspectRatio, "min") * this.props.unitsMulti)}{this.props.units}</span>
                <span className={(this.props.units !== "ft") ? "hide-me" : ""}>{this.feetAndInches(this.getDiagonalFromDistance(item, this.props.aspectRatio, "min") * this.props.unitsMulti)}</span>
              </div>
              <div className={item.type === "UST" && (item.zoomtype === "optional" || item.zoomtype === "fixed") ? "hide-me label" : "label"}>Lens range</div>
              <div className={item.type === "UST" && (item.zoomtype === "optional" || item.zoomtype === "fixed") ? "hide-me value" : "value"}>                
                <span className={(this.props.units === "ft" || this.props.units === "mm" || this.props.units === "in" || item.zoomtype === "fixed" || (item.formula !== undefined && item.formula.type === "fixed")) ? "hide-me" : ""}>{this.myRound(this.minRange(item) * this.props.unitsMulti)}{this.props.units}&mdash;</span>
                <span className={((this.props.units !== "mm" && this.props.units !== "in")  || item.zoomtype === "fixed" || (item.formula !== undefined && item.formula.type === "fixed")) ? "hide-me" : ""}>{this.myRoundMM(this.minRange(item) * this.props.unitsMulti)}{this.props.units}&mdash;</span>
                <span className={(this.props.units !== "ft") ? "hide-me" : ""}>{this.feetAndInches(this.minRange(item) * this.props.unitsMulti)}&mdash;</span>
                <span className={(this.props.units === "ft" || this.props.units === "mm" || this.props.units === "in") ? "hide-me" : ""}>{this.myRound(this.maxRange(item) * this.props.unitsMulti)}{this.props.units}</span>
                <span className={(this.props.units !== "mm" && this.props.units !== "in") ? "hide-me" : ""}>{this.myRoundMM(this.maxRange(item) * this.props.unitsMulti)}{this.props.units}</span>
                <span className={(this.props.units !== "ft") ? "hide-me" : ""}>{this.feetAndInches(this.maxRange(item) * this.props.unitsMulti)}</span>
              </div>
              <div className="clear"></div>
            </div>  
          </div>
        ))}
      </div>  
    );
  }
}

class Switcher extends Component {
  constructor() {
    super();
    this.state = { checked: false };
    this.handleChange = this.handleChange.bind(this);
  }
 
  handleChange(checked) {
    this.setState({ checked });
  }
 
  render() {
    return (
      <label htmlFor="normal-switch">
        <Switch
          onChange={this.handleChange}
          checked={this.state.checked}
          id="normal-switch"
          onColor="#2370b4"
          onHandleColor="#2693e6"
          handleDiameter={10}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          height={10}
          width={28}
        />
        <span>{this.props.title}</span>
      </label>
    );
  }
}



export default App;
